import { combineReducers } from 'redux';

export function error(state = null, action) {
    switch (action.type) {
        case 'MEMBER_ADMIN_ASSOCIATION_MODIFICATION_ERROR':
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case 'MEMBER_ADMIN_ASSOCIATION_MODIFICATION_LOADING':
            return action.loading;

        default:
            return state;
    }
}

export function modified(state = null, action) {
    switch (action.type) {
        case 'MEMBER_ADMIN_ASSOCIATION_MODIFICATION_SUCCESS':
            return action.modified;

        default:
            return state;
    }
}

export default combineReducers({ error, loading, modified });
