const MenuItems = [
    {
        name: "accueil",
        href: "/",
        authenticationRequired : false
    },
    {
        name: "actualité",
        href: "/categorie/actualite/",
        authenticationRequired : false
    },
    {
        name: "l'association",
        href: "/categorie/association/",
        authenticationRequired : false
    },
    {
        name: "adhérents AD13",
        href: "/adherents_ad13/",
        authenticationRequired : false
    },
    {
        name: "réglementation",
        href: "/categorie/reglementation/",
        authenticationRequired : false
    },
    {
        name: "formation",
        href: "/formations/",
        authenticationRequired : false
    },
    {
        name: "documents",
        href: "/documents/",
        authenticationRequired : true
    },
    {
        name: "nous contacter",
        href: "/contact/",
        authenticationRequired : false
    },
];

export default MenuItems;
