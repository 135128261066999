import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, Grid, Typography} from '@material-ui/core/';


const styles = theme => ({
    titleContainer: {
        height: 'min-content',
    },
    beforeTitle: {
        backgroundColor: theme.palette.primary.contrastText,
        content: "''",
        display: 'block',
        height: 3,
        left: 0,
        position: 'absolute',
        top: 'calc(50% - 1.5px)',
        transition: theme.transition.smooth,
        width: theme.spacing.unit * 4,
        zIndex: 0,
    },
    beforeTitlePrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    beforeTitleSecondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        color: theme.palette.primary.contrastText,
        fontFamily: "'FjallaOne', 'OpenSans', sans-serif",
        paddingLeft: theme.spacing.unit * 6,
        position: 'relative',
        '&:first-letter': {
          textTransform: 'uppercase',
        }
    },
    titlePrimary: {
        color: theme.palette.primary.main,
    },
    titleSecondary: {
        color: theme.palette.secondary.main,
    }

});

class SectionTitle extends React.Component {
    render() {
        const {classes, title, color, paddingTop, paddingBottom, variant} = this.props;
        return (
            <Grid container
                  className={classes.titleContainer}
                  style={{
                      paddingTop: undefined !== paddingTop && paddingTop,
                      paddingBottom: undefined !== paddingBottom && paddingBottom
                  }}>
                <Typography
                    variant={undefined !== variant ? variant : "h4"}
                    align="center"
                    className={`${classes.title} ${undefined === color ? '' :  'primary' === color ? classes.titlePrimary : classes.titleSecondary}`}
                >
                    <Grid item component='span'
                        className={`${classes.beforeTitle} ${undefined === color ? '' :  'primary' === color ? classes.beforeTitlePrimary : classes.beforeTitleSecondary}`}
                    />
                    {title}
                </Typography>
            </Grid>
        )
    }
}

SectionTitle.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    paddingBottom: PropTypes.number,
    paddingTop: PropTypes.number,
    variant: PropTypes.string,
}

export default withStyles(styles)(SectionTitle)
