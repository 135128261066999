import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SectionTitle from '../partials/SectionTitle';
import Slider from '../formation/Slider';
import {withStyles, Button, Grid, Hidden, Typography} from '@material-ui/core'
import {Group } from '@material-ui/icons';
import AD13Separator from '../partials/AD13Separator';
import {compute} from '../../actions/member/authenticate';
import FormationModal from '../formation/FormationModal';
import {fetch} from '../../utils/dataAccess';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import AD13SingleHeader from '../partials/AD13SingleHeader';
import AD13SingleContent from '../partials/AD13SingleContent';
import AD13SwiperContainer from '../partials/AD13SwiperContainer';
import SliderNotReady from '../article/SliderNotReady';
import ShowNotReady from '../partials/ShowNotReady';
import {reset, retrieve} from '../../actions/training/show';
import {del} from '../../actions/training/delete';
import AD13SingleMetas from '../partials/AD13SingleMetas';
import GoBackBar from '../navigation/GoBackBar';

const styles = theme =>  ({
    container: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing.unit * 6,
        },
        maxWidth: '100%',
    },
    headerContent: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters(),
        },
        margin: 'auto',
        marginBottom: 0,
        marginTop: 0,
        maxWidth: theme.spacing.unit * 70,
        minHeight: 300
    },
    headerTitle: {
        '&:first-letter': {
            TextTransform: 'uppercase',
        }
    },
    content: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters()
        },
    },
    inscriptionCTA: {
        position: 'absolute',
        top: -78,
        zIndex: 10
    },
    capacity: {
        borderLeft: '1px solid ' + theme.palette.primary.contrastText,
        borderRight: '1px solid ' + theme.palette.primary.contrastText,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    alert: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 4,
    },
    info: {
        marginBottom: 32,
    },
    inlineBold: {
        display: 'inline-block',
        fontWeight: 700
    },
    falseLink: {
        color: theme.palette.textPrimary,
        display: 'inline-block',
        textDecoration: 'underline',
        '&:hover': {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    }
});

class FormationSingle extends React.Component {
    static propTypes = {
        retrieved: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        eventSource: PropTypes.instanceOf(EventSource),
        retrieve: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        deleteError: PropTypes.string,
        deleteLoading: PropTypes.bool.isRequired,
        deleted: PropTypes.object,
        del: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.props.retrieve(decodeURIComponent(this.props.match.params.id));
        this.fetchAllTrainings();
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.fetchAllTrainings();
            this.props.retrieve(decodeURIComponent(nextProps.match.params.id));
        }
    };

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    };


    state = {
        items: [],
        isModalOpen: false
    };

    fetchAllTrainings = () => {
        fetch('trainings?perPage=100')
            .then(response => {
                response.json().then(data=>{
                    let trainings = [...data['hydra:member']];
                    trainings = trainings.filter(item => item.id !== this.props.match.params.id)
                    trainings = trainings.filter(item => new Date(item['events'][0]['dateStart']) > new Date())
                    this.setState({items: trainings})
                })
            })
    }

    handleModal = () => {
        this.setState({isModalOpen: !this.state.isModalOpen})
        if (!this.state.isModalOpen) {
            this.props.retrieve(decodeURIComponent(this.props.match.params.id));
        }
    };

    handleLoginModal = () => {
        this.props.computeAuthentication(!this.props.isComputingAuthentication);
    };

    render() {
        const {classes, isAuthenticated, retrieved, loading, deleteError, deletedItem, error} = this.props,
            {items} = this.state,
            item = retrieved,
            options = {year: "numeric", month: "long", day: "numeric"},
            StartDate = item && new Date(item.events[0].dateStart).toLocaleString('fr-FR', options),
            itemImage = item && null !== item['image'] ? item['image']['url'] : darkRabbit,
            dateOptions = {day: 'numeric', month: 'long', year: 'numeric'};

        return (
            item ?
                <React.Fragment>
                    <GoBackBar/>
                    <AD13SingleMetas item={item}/>
                    <Grid container className={classes.container}>
                        <AD13SingleHeader itemImage={itemImage}>
                            <Grid container className={classes.headerContent} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" color="inherit">
                                        {StartDate}
                                    </Typography>
                                    <Typography variant={"h2"} color="inherit" className={classes.headerTitle}>
                                        {item['name']}
                                    </Typography>
                                    <Hidden mdDown>
                                        <Grid container alignItems={'center'} className={classes.infos}>
                                            <Typography variant={'subtitle1'} color="inherit">
                                                <Group className={classes.groupIcon}/>&nbsp;{item.numberOfParticipants}
                                            </Typography>
                                            <Typography variant={'caption'} color="inherit" className={classes.capacity}>
                                                {item['numberMinOfParticipants']} à {item['numberMaxOfParticipants']} pers.
                                            </Typography>
                                            <Typography variant={'subtitle1'} color="inherit">
                                                {item['numberMaxOfParticipants'] - item.numberOfParticipants} places restantes
                                            </Typography>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </AD13SingleHeader>
                        <AD13SingleContent socialData={{subject:item['name']}}>
                            <React.Fragment>
                                <Hidden mdDown>
                                    {isAuthenticated &&
                                        <Grid
                                            item md={2}
                                            style={{
                                                background: 'opened_for_registration' !== item.status ? '#FFF' : 'transparent',
                                                position: 'absolute',
                                                top: -75,
                                                right: 100,
                                                zIndex: 100
                                            }}
                                        >
                                            {new Date() > new Date(item.events[0].dateStart) ?
                                                <Button
                                                    variant={"contained"}
                                                    size={'small'}
                                                    disabled
                                                    className={classes.actionpast}
                                                >
                                                    Passé
                                                </Button>
                                                :
                                                <Button
                                                    variant={'contained'}
                                                    color={'secondary'}
                                                    onClick={this.handleModal}
                                                    disabled={'opened_for_registration' !== item.status}
                                                    >
                                                    {'opened_for_registration' !== item.status ?
                                                        'Bientôt ouverte'
                                                        :
                                                        'Inscrire un équipier'
                                                    }
                                                </Button>
                                            }
                                        </Grid>
                                    }
                                </Hidden>
                                <Grid item xs={12}>
                                    <Typography>
                                        <Typography component={'span'} className={classes.inlineBold}>
                                            Durée&nbsp;
                                        </Typography>
                                        : {item.events.length} jour{item.events.length > 1 ? 's' : ''}
                                    </Typography>
                                    {item.events.map((event, index) => (
                                        <Typography key={index}>
                                            <Typography component={'span'} className={classes.inlineBold}>
                                                Jour {index + 1}
                                            </Typography>
                                            : {new Date(event.dateStart).toLocaleString('fr-Fr', dateOptions)}
                                        </Typography>
                                    ))}
                                    <Typography>
                                        <Typography component={'span'} className={classes.inlineBold}>
                                            Nombre de stagiaires&nbsp;
                                        </Typography>
                                        : {item['numberMinOfParticipants']} à {item['numberMaxOfParticipants']} personnes
                                    </Typography>
                                    <AD13Separator />
                                    <Grid container dangerouslySetInnerHTML={{__html: '<div>' + item['description'] + '</div>'}}/>
                                </Grid>
                                {!isAuthenticated &&
                                <Grid container>
                                    <AD13Separator />
                                    <SectionTitle title={'Inscription'} color={'primary'} paddingBottom={32}/>
                                    <Grid item xs={12} className={classes.info}>
                                        <Typography>
                                            Si vous êtes&nbsp;
                                            <Typography component='span' className={classes.inlineBold}>
                                                bénévole CCFF/RCSC
                                            </Typography>
                                            &nbsp;contactez votre responsable ou votre chargé de formation
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Vous êtes&nbsp;
                                            <Typography component='span' className={classes.inlineBold}>
                                                responsable CCFF/RCSC
                                            </Typography>
                                            &nbsp;
                                            <Typography component='span' className={classes.falseLink} onClick={this.handleLoginModal}>
                                                connectez-vous
                                            </Typography>
                                            &nbsp;à votre espace membre pour inscrire un/des bénévoles
                                        </Typography>
                                    </Grid>
                                </Grid>
                                }
                            </React.Fragment>
                        </AD13SingleContent>
                        {0 < items.length ?
                            <AD13SwiperContainer title={'Formations à venir'}>
                                <Slider items={items}/>
                            </AD13SwiperContainer>
                            :
                            <SliderNotReady loading={loading} error={error} deletedItem={deletedItem}/>
                        }
                        <FormationModal item={item} handleModal={this.handleModal} isModalOpen={this.state.isModalOpen}/>
                    </Grid>
                </React.Fragment>
                :
                <ShowNotReady loading={loading} error={error} deleteError={deleteError} deletedItem={deletedItem}/>
        )
    }
}

FormationSingle.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    isAuthenticated: state.storeAuth.isAuthenticated,
    storeAuth: state.storeAuth,
    isComputingAuthentication: state.member.authenticate.compute,
    retrieved: state.training.show.retrieved,
    error: state.training.show.error,
    loading: state.training.show.loading,
    eventSource: state.training.show.eventSource,
    deleteError: state.training.del.error,
    deleteLoading: state.training.del.loading,
    deleted: state.training.del.deleted
});

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve(id)),
    del: item => dispatch(del(item)),
    reset: eventSource => dispatch(reset(eventSource)),
    computeAuthentication: (value) => dispatch(compute(value)),
});

export default compose (
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter,
    withStyles(styles)
)(FormationSingle);

