import React from 'react'

const LogoPurjus = props => (
    <svg data-name="Calque 1" viewBox="0 0 76.16 10" {...props} style={{fill: "inherit"}}>
        <title>{'logo_purjus'}</title>
        <path
            d="M8.57 7.29l-3.75 2.4a1.15 1.15 0 0 1-.54.11 1.71 1.71 0 0 1-.53-.11L0 7.29v-7C0 .2.29.2.29.2h.43A.88.88 0 0 1 1 .31a24.55 24.55 0 0 1 1.64 2.23 17.22 17.22 0 0 0 3.29 0C6 2.45 7.17.65 7.58.27A.64.64 0 0 1 7.77.2h.55s.25 0 .25.16v6.93zM15 9.8h-.9V.2h4.2a2.57 2.57 0 0 1 2.8 2.74 2.57 2.57 0 0 1-2.8 2.75H15zm0-4.89h3.1a1.87 1.87 0 0 0 2.1-2 1.86 1.86 0 0 0-2.1-2H15zM32.36.2h-.9v5.94c0 2.23-1 3.08-2.78 3.08s-2.78-.85-2.76-3.08V.2H25v6.14A3.35 3.35 0 0 0 28.68 10a3.35 3.35 0 0 0 3.68-3.66zm11.91 9.6h-1A4 4 0 0 1 43 8.31c-.08-1.35-.18-2.84-1.89-2.77h-3.39V9.8h-.9V.2h4.32C42.7.2 44 1 44 2.7a2.3 2.3 0 0 1-1.84 2.45c1.24.17 1.59 1 1.66 2.17a5.48 5.48 0 0 0 .45 2.48zm-1.16-6.91a1.79 1.79 0 0 0-2-1.91h-3.39v3.78h3.36a1.89 1.89 0 0 0 2.03-1.87zM53 7.12V.2h-.9v6.53c0 1.76-.37 2.49-1.77 2.49-1.86 0-1.78-1.68-1.81-2.5h-.9c0 1.67.46 3.28 2.63 3.28S53 9.07 53 7.12zm11.8-.78V.2h-.9v5.94c0 2.23-1 3.08-2.77 3.08s-2.78-.85-2.77-3.08V.2h-.9v6.14A3.36 3.36 0 0 0 61.12 10a3.35 3.35 0 0 0 3.67-3.66zm11.37.91c0-1.57-1.12-2.16-2.45-2.52l-2.3-.58C70.64 4 70 3.59 70 2.69 70 1.26 71.12.78 72.35.78A2.34 2.34 0 0 1 74.93 3h.9c0-2-1.63-3-3.5-3-2.66 0-3.25 1.76-3.25 2.7 0 1.49 1 2 2.13 2.31l2.1.53c.88.21 2 .64 2 1.73 0 1.4-1.51 2-2.5 2-1.72 0-3.13-.6-3.09-2.58h-.9C68.67 9 70.31 10 72.54 10c3.3 0 3.61-2 3.62-2.7z"
        />
    </svg>
)

export default LogoPurjus
