import React, { Component } from 'react';
import {Grid, Typography, withStyles} from '@material-ui/core';

const styles = theme =>  ({
    messages: {
        paddingTop: theme.spacing.unit * 6,
    },
})

class SliderNotReady extends Component {
    render() {
        const {classes, loading, error, deletedItem} = this.props
        return (
            <Grid container justify="center" className={classes.messages}>
                {loading && <Typography>Loading...</Typography>}
                {deletedItem && <Typography>{deletedItem['@id']} deleted.</Typography>}
                {error && <Typography>{error}</Typography>}
            </Grid>
        );
    }
}

export default withStyles(styles)(SliderNotReady)
