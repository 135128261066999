import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Settings from '@material-ui/icons/Settings';
import PowerSettings from '@material-ui/icons/PowerSettingsNew';
import {withStyles, Grid, Hidden, Typography} from '@material-ui/core/';
import {logout, compute} from '../../actions/member/authenticate'

const styles = theme => ({
    goBack: {
        ...theme.mixins.gutters(),
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 15
    },
    goBackContainer: {
        alignItems: 'center',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        padding: theme.spacing.unit + 'px 0',
        '&:hover': {
            color: theme.palette.secondary.main,
            cursor: 'pointer',
            textDecoration: 'none'
        }
    },
    ctaIcon: {
        color: 'inherit',
        fontSize: '2rem',
        marginRight: theme.spacing.unit * 1.5
    },
    topbarContainer: {
        background: theme.palette.primary.main,
        maxHeight: theme.spacing.unit * 7,
        minHeight: theme.spacing.unit * 1.5,
        position: 'relative',
        top: 0
    },
    topbar: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters()
        },
        color: theme.palette.primary.contrastText,
        margin: 'auto',
        maxWidth: theme.layout.width,
        pointerEvents: 'all'

    },
    topbarLinks: {
        color: theme.palette.primary.contrastText,
        display: 'inline-flex',
        height: 'inherit',
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        textDecoration: 'none',
        textTransform: 'uppercase',
        '&:hover': {
            background: theme.palette.primary.dark,
            cursor: 'pointer',
            color: theme.palette.primary.contrastText,
            textDecoration: 'none'
        }
    },
    topbarTexts: {
        fontSize: 12,
        fontWeight: 700
    },
    topbarIcons: {
        fontSize: 14,
        marginLeft: theme.spacing.unit
    },
    connectedContainer: {
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText
        }
    },
    connectedIconsContainer: {
        height: theme.spacing.unit * 7,
        width: theme.spacing.unit * 7,
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText
        }
    },
    connectedIconsLink: {
        color: 'inherit',
        '&:hover': {
            color: 'inherit',
            textDecoration: 'none'
        }
    },
    connectedIcons: {
        height: theme.spacing.unit * 3,
        width: theme.spacing.unit * 3

    },
    topbarSlash: {
        color: theme.palette.primary.contrastText
    }
});

class Topbar extends React.Component {

    handleLoginModal = () => {
        this.props.computeAuthentication(!this.props.isComputingAuthentication);
    };

    logOut = () => {
        this.props.logout();
    };

    render() {
        const {classes, storeAuth} = this.props;

        return (
            <Grid container className={classes.topbarContainer}>
                <Grid container alignItems="center" justify="space-between" className={classes.topbar}>
                    <Hidden smDown>
                        <Grid item>
                            <Link to="/contact/" className={classes.topbarLinks}>
                                <Grid container alignItems="center"
                                      style={{height: storeAuth.isAuthenticated ? 56 : 32}}>
                                    <Typography color="inherit" className={classes.topbarTexts}>
                                        Nous contacter
                                    </Typography>
                                    <MailIcon className={classes.topbarIcons}/>
                                </Grid>
                            </Link>
                        </Grid>
                        <Grid item>
                            {storeAuth.isAuthenticated ?
                                <Grid container justify={'flex-end'} alignItems={'center'}>
                                    {
                                        storeAuth.user.associations.map(association => (
                                            <Grid key={association.uuid} item className={classes.connectedContainer}>
                                                <Link to={'/compte/' + association.uuid}
                                                      className={classes.connectedIconsLink}>
                                                    <Grid container alignItems={'center'}>
                                                        <Grid item>
                                                            <Grid container justify={'center'} alignItems={'center'}
                                                                  className={classes.connectedIconsContainer}>
                                                                <AccountCircle className={classes.connectedIcons}
                                                                               color={'inherit'}/>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container direction={'column'}
                                                                  style={{paddingRight: 8}}>
                                                                <Typography color={'inherit'} variant={'body1'}>
                                                                    {association.associationType && association.associationType.name}
                                                                </Typography>
                                                                <Typography color={'inherit'} variant={'body2'}>
                                                                    {association.mapZoneLabel}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Link>
                                            </Grid>
                                        ))
                                    }
                                    <Grid item>
                                        <Grid container justify={'center'} alignItems={'center'}
                                              className={classes.connectedIconsContainer}>
                                            <Link to={'/preferences/'} className={classes.connectedIconsLink}>
                                                <Settings className={classes.connectedIcons}/>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container justify={'center'} alignItems={'center'}
                                              className={classes.connectedIconsContainer}>
                                            <PowerSettings className={classes.connectedIcons} onClick={this.logOut}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container justify={'flex-end'} alignItems={'center'}>
                                    <Link to="/inscription/" className={classes.topbarLinks}>
                                        <Grid container alignItems="center">
                                            <Typography color="inherit" className={classes.topbarTexts}>
                                                S'inscrire
                                            </Typography>
                                        </Grid>
                                    </Link>
                                    <Grid item className={classes.topbarLinks} onClick={this.handleLoginModal}>
                                        <Grid container alignItems={'center'}>
                                            <Typography color="inherit" className={classes.topbarTexts}>
                                                Se connecter
                                            </Typography>
                                            <AccountCircle className={classes.topbarIcons}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        )
    }
}

Topbar.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        storeAuth: state.storeAuth,
        isComputingAuthentication: state.member.authenticate.compute
    }
};

const mapDispatchToProps = dispatch => ({
    logout: (username, password) => dispatch(logout(username, password)),
    computeAuthentication: (value) => dispatch(compute(value))
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(Topbar);

