import {SubmissionError} from 'redux-form';
import {fetch} from '../../utils/dataAccess';
import decodeJwt from "jwt-decode";

export function compute(compute) {
    return {type: 'MEMBER_AUTHENTICATE_COMPUTE', compute};
}

export function error(error) {
    return {type: 'MEMBER_AUTHENTICATE_ERROR', error};
}

export function loading(loading) {
    return {type: 'MEMBER_AUTHENTICATE_LOADING', loading};
}

export function success(authenticated) {
    return {type: 'MEMBER_AUTHENTICATE_SUCCESS', authenticated};
}

export function logout() {
    return dispatch => {
        dispatch({type: 'AUTHENTICATE_USER', isAuthenticated: false, token: null, user: null});
    }
}

export function authenticate(username, password) {

    let values = {
        username: username,
        password: password,
    };

    return dispatch => {
        dispatch(loading(true));

        return fetch('login_check', {method: 'POST', body: JSON.stringify(values)})
            .then(response => {
                dispatch(loading(false));

                return response.json();
            })
            .then(retrieved => {
                dispatch(success(retrieved));
                dispatch(compute(false));
                dispatch({type: 'AUTHENTICATE_USER',isAuthenticated: true, token: retrieved.token, user: decodeJwt(retrieved.token)['user']});
            })
            .catch(e => {
                dispatch(loading(false));

                if (e instanceof SubmissionError) {
                    dispatch(error(e.errors._error));
                    throw e;
                }

                dispatch(error(true));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(null));
    };
}
