import React, {Component} from 'react';
import PropTypes from 'prop-types';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import {withStyles, CardMedia, Grid} from '@material-ui/core';


const styles = theme => ({
    mediaContainer: {
        boxSizing: 'border-box',
        height: theme.spacing.unit * 20,
        overflow: 'hidden',
        position: 'relative',
        transition: theme.transition.smooth,
        minWidth: theme.spacing.unit * 25,
        '&:hover > span': {
            transform: 'scale(1.1)'
        }
    },
    mediaBackground: {
        background: theme.palette.background.default,
        height: theme.spacing.unit * 20,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 0
    },
    media: {
        backgroundSize: 'contain',
        height: theme.spacing.unit * 20,
        position: 'relative',
        transition: theme.transition.smooth,
        width: '100%',
        zIndex: 10
    }
})

class FormationListItemMedia extends Component {
    render() {
        const {classes, item} = this.props;
        const itemImage = null !== item['image'] ? item['image']['url'] : darkRabbit;
        return (
            <Grid item className={classes.mediaContainer}>
                <CardMedia component={'span'} className={classes.media} image={itemImage} title={item['title']}/>
            </Grid>
        );
    }
}

FormationListItemMedia.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
}

export default withStyles(styles)(FormationListItemMedia);
