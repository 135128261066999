import './assets/fonts/fonts.css'

const palette = {
    primary: {
        main: '#f3752B',
        light: '#F3932B',
        dark: '#D95B2B',
        contrastText: '#FFFFFF',
    },
    secondary: {
        main: '#88B84B',
        light: '#A7CE5E',
        dark: '#729E4B',
        contrastText: '#FFFFFF',
    },
    tertiary: {
        main: '#1B1C1E',
        light: '#6C7078',
        dark: '#000000',
        contrastText: '#FFFFFF',
    },
    textPrimary: '#1B1C1E',
    textSecondary: '#A4A4A5',
    background: {
        default: '#FFFFFF',
        black: '#1B1C1E',
        lightgrey: '#F1F1F1',
        bottomGradient: 'linear-gradient(to bottom, transparent calc(100% - 40px), #F1F1F1 calc(100% - 40px))',
        overlay: 'rgba(0,0,0,.35)',
        redOverlay: 'rgba(243,117,43,.9)',
    },
};

export {palette};

const spacing = {
    unit: 8,
};

export default {
    props: {
        MuiWithWidth: {
            initialWidth: 'lg',
        },
    },
    layout: {
        width: 1280,
    },
    transition: {
        smooth: 'all .5s',
    },
    shadows: [
        'none',
        '0px 1px 2px -1px   rgba(0,84,164,0.15)',
        '0px 1px 3px 0px    rgba(0,84,164,0.15)',
        '0px 2px 5px -1px   rgba(0,84,164,0.15)',
        '0px 2px 6px 0px    rgba(0,84,164,0.15)',
        '0px 3px 8px -1px   rgba(0,84,164,0.15)',
        '0px 3px 9px 0px    rgba(0,84,164,0.15)',
        '0px 4px 11px -1px  rgba(0,84,164,0.15)',
        '0px 4px 12px 0px   rgba(0,84,164,0.15)',
        '0px 5px 14px -1px  rgba(0,84,164,0.15)',
        '0px 5px 15px 0px   rgba(0,84,164,0.15)',
        '0px 6px 17px -1px  rgba(0,84,164,0.15)',
        '0px 6px 18px 0px   rgba(0,84,164,0.15)',
        '0px 7px 20px -1px  rgba(0,84,164,0.15)',
        '0px 7px 21px 0px   rgba(0,84,164,0.15)',
        '0px 8px 23px -1px  rgba(0,84,164,0.15)',
        '0px 8px 24px 0px   rgba(0,84,164,0.15)',
        '0px 9px 26px -1px  rgba(0,84,164,0.15)',
        '0px 9px 27px 0px   rgba(0,84,164,0.15)',
        '0px 10px 29px -1px rgba(0,84,164,0.15)',
        '0px 10px 30px 0px  rgba(0,84,164,0.15)',
        '0px 11px 32px -1px rgba(0,84,164,0.15)',
        '0px 11px 33px 0px  rgba(0,84,164,0.15)',
        '0px 12px 35px -1px rgba(0,84,164,0.15)',
        '0px 12px 36px 0px  rgba(0,84,164,0.15)',
    ],
    palette: palette,
    spacing: spacing,
    shape: {borderRadius: 6},
    typography:{
        fontFamily: [
            'FjallaOne',
            'OpenSans',
            'sans-serif',
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        useNextVariants: true,
        h1: {
            fontFamily: "'FjallaOne', 'OpenSans', sans-serif",
            fontSize: '5rem',
            fontWeight: 400,
            lineHeight: '6.3rem',
        },
        h2: {
            fontFamily: "'FjallaOne', 'OpenSans', sans-serif",
            fontSize: '3rem',
            fontWeight: 300,
            lineHeight: '3.75rem',
        },
        h3: {
            fontFamily: "'FjallaOne', 'OpenSans', sans-serif",
            fontSize: '2.25rem',
            fontWeight: 300,
            lineHeight: '3rem',
        },
        h4: {
            fontFamily: "'FjallaOne', 'OpenSans', sans-serif",
            fontSize: '1.5rem',
            fontWeight: 300,
            lineHeight: '1.5rem',
        },
        h5: {
            fontFamily: "'OpenSans', sans-serif",
            fontSize: '1.375rem',
            fontWeight: 700,
            lineHeight: '1.14286rem',
        },
        h6: {
            fontFamily: "'FjallaOne','OpenSans', sans-serif",
            fontSize: '1.25rem',
            fontWeight: 300,
            lineHeight: '25px',
        },
        subtitle1: {
            fontFamily: "'OpenSans', sans-serif",
            fontSize: '0.875rem',
            fontWeight: 700,
        },
        body1: {
            fontFamily: "'OpenSans', sans-serif",
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: "1.25rem",
        },
        body2: {
            fontFamily: "'OpenSans', sans-serif",
            fontSize: '0.875rem',
            fontWeight: 300,
            lineHeight: "1.50rem",
        },
        caption: {
            fontFamily: "'OpenSans', sans-serif",
            color: palette.textSecondary,
            fontSize: '0.75rem',
        },
        button: {
            fontFamily: "'OpenSans', sans-serif",
            fontSize: '0.875rem',
            fontWeight: 700,
        },
    },
    overrides: {
        MuiAppBar : {
            root: {
                boxShadow: 'none',
            },
            colorDefault : {
                backgroundColor : 'transparent',
            }
        },
        MuiButton: {
            root: {
                borderRadius: 0,
                '&:focus': {
                    outline: '2px auto ' + palette.secondary.light,
                }
            },
        },
        MuiCard: {
            root: {
                borderRadius: 0
            }
        },
        MuiCollapse: {
            wrapper: {
                height: 'inherit',
            }
        },
        MuiDivider: {
            light: {
                backgroundColor: palette.background.lightgrey,
                height: .5
            }
        },
        MuiTypography : {
            root: {
                '&::selection': {
                    background: palette.primary.main,
                    color: palette.primary.contrastText,
                },
            },
            colorTextPrimary: {
                color: palette.textPrimary,
            },
            colorTextSecondary: {
                color: palette.textSecondary,
            }
        },
        MuiOutlinedInput: {
            notchedOutline: {
                '&::selection': {
                    background: palette.primary.main,
                    color: palette.primary.contrastText,
                },
            }
        }
    },
};
