import React from 'react';

const Diamond = props => {
  return (
      <path d={props.d}>
          <animate
              attributeName="opacity"
              from={1}
              to={0}
              dur="1s"
              repeatCount="indefinite"
              begin={props.begin}
              fill="freeze"
              values="1;0.75;0.5;0"
              keyTimes="0;0.25;0.5;1"
          />
      </path>
      )
}

const AD13Loader = props => (
  <svg style={{ width: 110, height: 110}} transform="rotate(-45) scale(0.6)" {...props}>
    <g fill="#F3752B">
        <Diamond d="M0 0h30v30H0z" begin={0}/>
        <Diamond d="M40 40h30v30H40z" begin={'0.1s'}/>
        <Diamond d="M80 80h30v30H80z" begin={'0.2s'}/>
    </g>
  </svg>
)


export default AD13Loader
