import React from 'react'
import PropTypes from 'prop-types'
import {withStyles, Grid, Typography} from '@material-ui/core'

const styles = theme =>  ({
    separator: {
        position: 'relative',
        marginBottom: theme.spacing.unit * 6,
        marginTop: theme.spacing.unit * 6,
        '&::before': {
            content: '""',
            display: 'block',
            height: 4,
            width: 4,
            background: theme.palette.textSecondary,
            transform: 'rotateZ(45deg)',
            position: 'absolute',
            left: -32,
            top: 0,
        },
        '&::after': {
            content: '""',
            display: 'block',
            height: 4,
            width: 4,
            background: theme.palette.textSecondary,
            transform: 'rotateZ(45deg)',
            position: 'absolute',
            left: 32,
            top: 0,
        }
    },
    separatorItem: {
        height: 4,
        width: 4,
        background: theme.palette.textSecondary,
        transform: 'rotateZ(45deg)',
    }
})

class AD13Separator extends React.Component {
    render() {
        const {classes} = this.props
        return (
            <Grid container className={classes.separatorContainer} justify={'center'} alignItems={'center'}>
                <Grid item className={classes.separator}>
                    <Typography component={'span'} className={classes.separatorItem}/>
                </Grid>
            </Grid>
        )
    }
}

AD13Separator.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AD13Separator)

