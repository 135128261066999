import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { list, reset } from '../../actions/article/list';
import ListItem from './ListItem';
import {Grid, Typography} from '@material-ui/core';
import AD13Loader from "../partials/AD13Loader";
import AD13Button from '../partials/AD13Button';

let category;

class List extends Component {
    static propTypes = {
        retrieved: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        eventSource: PropTypes.instanceOf(EventSource),
        deletedItem: PropTypes.object,
        list: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired
    };

    getCategoryByParam = (page) => {
        switch (page) {
            case 'reglementation':
                category = '/articles?category.identifier=CATEGORY_REGULATION'
                break;
            case 'association':
                category = '/articles?category.identifier=CATEGORY_ASSOCIATION'
                break;
            case 'actualite':
                category = '/articles?category.identifier=CATEGORY_ACTUALITY'
                break;
            default:
                category = '/articles'
                break;
        }
    };

    componentDidMount() {
        this.getCategoryByParam(this.props.page);
        this.props.list(decodeURIComponent(category));
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.page !== nextProps.page) {
            this.getCategoryByParam(nextProps.page)
            this.props.list(decodeURIComponent(category));
        }
    };

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    };

    render() {
        const {loading, deletedItem, error, retrieved} = this.props,
            items = retrieved && retrieved['hydra:member'].filter(item => 'PUBLISHED' === item.status.identifier);
        return (
           items ?
               0 < items.length ?
                    <Grid container spacing={40}>
                        {items.map(item => (
                            <Grid item xs={12} md={6} key={item['@id']}>
                                <ListItem item={item}/>
                            </Grid>
                        ))}
                    </Grid>
                   :
                   <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                       <Typography gutterBottom  style={{marginBottom: 16, marginTop: 16}}>
                           Aucun article n'a été publié dans cette catégorie pour le moment
                       </Typography>
                       <AD13Button href='/' text={'Retourner à l\'accueil'}/>
                   </Grid>
                :
                <Grid container justify="center">
                    {loading && <AD13Loader />}
                    {deletedItem && <Typography>{deletedItem['@id']} deleted.</Typography>}
                    {error && <Typography>{error}</Typography>}
                </Grid>
        );
    }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem
  } = state.article.list;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);


