import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import ListItem from './ListItem';
import ListItemMobile from './ListItemMobile';
import {withStyles, Grid} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import Swiper from 'react-id-swiper';

const styles = theme => ({
    mobileSliderWrapper: {
        marginBottom: theme.spacing.unit * 5
    },
    sliderWrapper: {
        flexGrow: 1,
    },
    sliderContainer: {
        minWidth: '100vw',
        zIndex: 100,
    },
    container: {
        margin: 0,
        overflow: 'hidden',
        width: '100%'
    }
});

class AD13Swiper extends Component {
    render() {
        const {classes, width, items, history} = this.props,
            isMobile = !isWidthUp('md', width),
            isHome = '/' === history.location.pathname,
            params = {
                slidesPerView: 'auto',
                spaceBetween: 32,
                containerClass: classes.container
            };
        return (
                isMobile && !isHome ?
                    <Grid container spacing={16} className={classes.mobileSliderWrapper}>
                        {items.splice(0, 4).map(item => (
                            <Grid item xs={6} md={6} lg={6} key={item['@id']}>
                                <ListItemMobile item={item} />
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <Grid container className={classes.sliderWrapper}>
                        <Grid container className={classes.sliderContainer}>
                            <Swiper {...params}>
                                {items.map(item => (
                                    <div key={item['@id']} style={{marginRight: 32, width: 400}}>
                                        <ListItem item={item}/>
                                    </div>
                                ))}
                            </Swiper>
                        </Grid>
                    </Grid>

        );
    }
}

AD13Swiper.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
};

export default compose(
    withRouter,
    withWidth(),
    withStyles(styles)
)(AD13Swiper);
