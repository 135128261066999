import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux'
import {withRouter} from 'react-router-dom'
import {withStyles, Grid, Typography} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';

import {KeyboardArrowLeft} from '@material-ui/icons/'

const styles = theme =>  ({
    goBack: {
        ...theme.mixins.gutters(),
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 15,
    },
    goBackContainer: {
        alignItems: 'center',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        padding: theme.spacing.unit + 'px 0',
        '&:hover': {
            color: theme.palette.secondary.main,
            cursor: 'pointer',
            textDecoration: 'none',
        }
    },
    ctaIcon: {
        color: 'inherit',
        fontSize: '2rem',
        marginRight: theme.spacing.unit * 1.5,
    }
})

class GoBackBar extends React.Component {

    redirect = () => {
        if (2 < this.props.history.length) {
            this.props.history.goBack();
        } else {
            this.props.history.push('/');
        }
    };

    render() {
        const {classes, width} = this.props,
            isMobile = !isWidthUp('md', width);

        if (isMobile) {
            return (
                <Grid item xs={12} className={classes.goBack} onClick={() => this.redirect()}>
                    <Grid container className={classes.goBackContainer}>
                        <KeyboardArrowLeft className={classes.ctaIcon}/>
                        <Typography color={'inherit'}>
                            Retour
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        return false;
    }
};

GoBackBar.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withWidth(),
    withStyles(styles)
)(GoBackBar);
