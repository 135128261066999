import React from 'react';
import {withRouter} from 'react-router-dom';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import AD13Button from '../partials/AD13Button';
import AD13Input from '../partials/AD13Input';
import {withStyles, TableCell, Grid, Table, TableBody, TableRow, Typography} from '@material-ui/core/';
import SectionTitle from '../partials/SectionTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import {fetch} from "../../utils/dataAccess";


const CustomTableCell = withStyles(theme => ({
    root: {
        border: 'none',
        paddingBottom: theme.spacing.unit,
        paddingLeft: 0,
        paddingRight: theme.spacing.unit * 5,
    },
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontFamily: 'OpenSans, sans-serif',
        fontSize: 14,
        fontWeight: 500,
    },
}))(TableCell);

class Contact extends React.Component {
    state = {
        form: {
            firstName: '',
            lastName: '',
            email: '',
            message: '',
        },
        constactUsSubmitted: false,
        modifyError: {},
    };

    handleChange = name => event => {
        let form = this.state.form;
        form[name] = event.target.value;
        this.setState({
            form: form,
        });
    };

    validateForm = () => {
        if (false === this.state.checked) {
            this.setState({modifyError: {checked: 'Vous devez accepter les conditions pour soumettre l\'article'}});
            return;
        }

        fetch('/contact_us', {
            method: 'POST',
            body: JSON.stringify(this.state.form)
        }).then(reponse => {
            this.setState(state => ({
                modifyError: {},
                constactUsSubmitted: true
            }))

        }).catch(error => {
            this.setState(state => {
                state.modifyError = error.errors;
                return state;
            })
        });

    };

    render() {
        return (
            <PageLayout title={"Nous contacter"}>
                <Container>
                    <React.Fragment>
                        <Main title={"Nous contacter"}>
                            {false === this.state.constactUsSubmitted ?
                                <Grid container style={{maxWidth: 500}}>
                                    <Grid item xs={12} style={{paddingBottom: 32}}>
                                        <Typography>
                                            Vous pouvez contacter l'association par courrier à l'adresse suivante :
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{paddingBottom: 32}}>
                                        <Typography>
                                            20 chemin de Roman, CD7
                                        </Typography>
                                        <Typography>
                                            13120 GARDANNE
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{paddingBottom: 32}}>
                                        <Typography>
                                            <Typography component={'span'}
                                                        style={{display: 'inline-block', minWidth: 152}}>
                                                Par téléphone :
                                            </Typography>
                                            04 42 90 49 10
                                        </Typography>
                                        <Typography>
                                            <Typography component={'span'}
                                                        style={{display: 'inline-block', minWidth: 152}}>
                                                Par fax :
                                            </Typography>
                                            04 42 90 49 11
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{paddingBottom: 32}}>
                                        <Typography>
                                            ou par mail en complétant notre formulaire de contact
                                        </Typography>
                                    </Grid>
                                    <form noValidate autoComplete="off" style={{width: '100%'}}>
                                        <AD13Input
                                            required
                                            id="outlined-name"
                                            label="Prénom"
                                            type="text"
                                            value={this.state.firstName}
                                            onChange={this.handleChange('firstName')}
                                            fullWidth
                                            error={this.state.modifyError.hasOwnProperty('firstName')}
                                        />
                                        {this.state.modifyError.hasOwnProperty('firstName') &&
                                        <FormHelperText
                                            error={true}>{this.state.modifyError.firstName}</FormHelperText>}
                                        <AD13Input
                                            required
                                            id="outlined-name"
                                            label="Nom"
                                            type="text"
                                            value={this.state.lastName}
                                            onChange={this.handleChange('lastName')}
                                            fullWidth
                                            error={this.state.modifyError.hasOwnProperty('lastName')}
                                        />
                                        {this.state.modifyError.hasOwnProperty('lastName') &&
                                        <FormHelperText error={true}>{this.state.modifyError.lastName}</FormHelperText>}
                                        <AD13Input
                                            required
                                            id="outlined-mail"
                                            label="Email"
                                            type="email"
                                            value={this.state.email}
                                            onChange={this.handleChange('email')}
                                            fullWidth
                                            error={this.state.modifyError.hasOwnProperty('email')}
                                        />
                                        {this.state.modifyError.hasOwnProperty('email') &&
                                        <FormHelperText error={true}>{this.state.modifyError.email}</FormHelperText>}
                                        <AD13Input
                                            required
                                            id="outlined-message"
                                            label="Message"
                                            value={this.state.message}
                                            onChange={this.handleChange('message')}
                                            fullWidth
                                            error={this.state.modifyError.hasOwnProperty('message')}
                                            multiline={true}
                                            rows={5}
                                            rowsMax={10}
                                        />
                                        {this.state.modifyError.hasOwnProperty('message') &&
                                        <FormHelperText error={true}>{this.state.modifyError.message}</FormHelperText>}
                                    </form>
                                    <Grid item xs={12} align={'right'} style={{marginRight: 20, marginTop: 16}}>
                                        <AD13Button text="valider" color={'primary'} onClick={this.validateForm}/>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container style={{maxWidth: 600}}>
                                    <Typography>
                                        Votre message a été transmis aux responsables de l'association.
                                    </Typography>
                                </Grid>
                            }
                        </Main>
                        <Aside>
                            <React.Fragment>
                                <SectionTitle title={'Horaires d\'ouverture'} color={'secondary'} paddingBottom={48}
                                              variant={'h4'}/>
                                <Table style={{maxWidth: 230}}>
                                    <TableBody>
                                        <TableRow style={{height: 'auto'}}>
                                            <CustomTableCell component="th" scope="row">Lundi</CustomTableCell>
                                            <CustomTableCell align="left">9h30</CustomTableCell>
                                            <CustomTableCell align="left">12h00</CustomTableCell>
                                        </TableRow>
                                        <TableRow style={{height: 'auto'}}>
                                            <CustomTableCell component="th" scope="row">Mardi</CustomTableCell>
                                            <CustomTableCell align="left">9h30</CustomTableCell>
                                            <CustomTableCell align="left">12h00</CustomTableCell>
                                        </TableRow>
                                        <TableRow style={{height: 'auto'}}>
                                            <CustomTableCell component="th" scope="row">Mercredi</CustomTableCell>
                                            <CustomTableCell align="left">14h00</CustomTableCell>
                                            <CustomTableCell align="left">17h00</CustomTableCell>
                                        </TableRow>
                                        <TableRow style={{height: 'auto'}}>
                                            <CustomTableCell component="th" scope="row">Jeudi</CustomTableCell>
                                            <CustomTableCell align="left">9h30</CustomTableCell>
                                            <CustomTableCell align="left">12h00</CustomTableCell>
                                        </TableRow>
                                        <TableRow style={{height: 'auto'}}>
                                            <CustomTableCell component="th" scope="row">Vendredi</CustomTableCell>
                                            <CustomTableCell align="left">Fermé</CustomTableCell>
                                            <CustomTableCell align="left"/>
                                        </TableRow>
                                        <TableRow style={{height: 'auto'}}>
                                            <CustomTableCell component="th" scope="row">Samedi</CustomTableCell>
                                            <CustomTableCell align="left">Fermé</CustomTableCell>
                                            <CustomTableCell align="left"/>
                                        </TableRow>
                                        <TableRow style={{height: 'auto'}}>
                                            <CustomTableCell component="th" scope="row">Dimanche</CustomTableCell>
                                            <CustomTableCell align="left">Fermé</CustomTableCell>
                                            <CustomTableCell align="left"/>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                        </Aside>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

export default withRouter(Contact)

