import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import AD13_ADCCSFLogo from '../../assets/AD13_ADCCSFLogo_grey.png';
import AD13_RCSCLogo from '../../assets/AD13_RCSCLogo_grey.png';
import PropTypes from 'prop-types';
import SectionTitle from './SectionTitle';
import CtaUp from './AD13CtaUp';
import LogoPurjus from './LogoPurjus';
import PartnersSwiper from './AD13PartnersSwiper';
import {Grid, List, ListItem, ListItemText, Typography, withStyles} from '@material-ui/core';

const styles = theme => ({
    footer: {
        ...theme.mixins.gutters(),
        background: theme.palette.background.black,
        color: theme.palette.textSecondary,
        fill: theme.palette.textSecondary,
        paddingTop: theme.spacing.unit * 9,
        zIndex: 1200,
    },
    footerContainer: {
        margin: 'auto',
        maxWidth: theme.layout.width,
        position: 'relative',
    },
    link: {
        color: 'inherit',
        transition: 'all .5s',
        '&:hover': {
            color: theme.palette.primary.contrastText,
            fill: theme.palette.primary.contrastText,
            textDecoration: 'none'
        }
    },
    inline: {
        display: 'inline-block',
    },
    logo: {
        fill: theme.palette.textSecondary,
        marginLeft: theme.spacing.unit
    },
    footerLinks: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    divider: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    mailto: {
        color: 'inherit',
        '&:hover': {
            color: theme.palette.primary.contrastText,
            fill: theme.palette.primary.contrastText,
            textDecoration: 'none'
        }
    }
});

const NavigationLinkItem = (props) => (
    <ListItem disableGutters>
        <Link to={props.pathname} className={props.classes}>
            <Typography color={'inherit'}>
                {props.text}
            </Typography>
        </Link>
    </ListItem>
);

const ContactListItem = (props) => (
    <ListItem disableGutters>
        <ListItemText disableTypography>
            <Typography color={'inherit'}>
                {props.text}
            </Typography>
        </ListItemText>
    </ListItem>
);

const Logo = (props) => (
    <Grid container justify={'center'} alignItems={'center'} style={{height: '100%'}}>
        <img src={props.src} alt={props.alt} className={props.classes}/>
    </Grid>
);

class Footer extends React.Component {
    render() {
        const {classes, history} = this.props,
            isPartnersPage = '/partenaires/' === history.location.pathname;
        return (
            <React.Fragment>
                {!isPartnersPage && <PartnersSwiper/>}
            <Grid container className={classes.footer}>
                <Grid container className={classes.footerContainer}>
                    <CtaUp/>
                    <Grid item xs={12} md={3}>
                      <SectionTitle title="Navigation"/>
                      <List className={classes.list}>
                          <NavigationLinkItem pathname={'/'} text={'Accueil'} classes={classes.link}/>
                          <NavigationLinkItem pathname={'/categorie/actualite/'} text={'Actualité'} classes={classes.link}/>
                          <NavigationLinkItem pathname={'/categorie/association/'} text={'L\'association'} classes={classes.link}/>
                          <NavigationLinkItem pathname={'/adherents_ad13/'} text={'Adhérents AD13'} classes={classes.link}/>
                          <NavigationLinkItem pathname={'/categorie/reglementation/'} text={'Réglementation'} classes={classes.link}/>
                          <NavigationLinkItem pathname={'/formations/'} text={'Formation'} classes={classes.link}/>
                      </List>
                  </Grid>
                    <Grid item xs={12} md={3}  className={classes.footerColumn}>
                      <SectionTitle title="Contactez-nous"/>
                      <List className={classes.list}>
                          <ContactListItem text={'20 chemin de Roman, CD7 13120 GARDANNE'}/>
                          <ContactListItem text={'Tél. : 04 42 90 49 10'}/>
                          <ContactListItem text={'Fax : 04 42 90 49 11'}/>
                          <a href="mailto:adccff13@comites-feux.com?subject=message envoyé depuis le site AD13" target={'_blank'} rel={'noreferrer noopener'} className={classes.link}>
                            <ContactListItem text={'adccff13@comites-feux.com'}/>
                          </a>
                      </List>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Logo src={AD13_ADCCSFLogo} alt={'ADCCSF'}/>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Logo src={AD13_RCSCLogo} alt={'RCSC'}/>
                    </Grid>
                </Grid>
                <Grid container className={classes.footerLinks}>
                    <Grid container className={classes.footerContainer}>
                        <Grid item xs={false} md={6}/>
                        <Grid item xs={12} md={6}>
                            <Grid container justify={'space-between'} alignItems={'center'}>
                                <Link to={"/legals/"} className={classes.link}>
                                    <Typography color={'inherit'}>
                                        Mentions Légales
                                    </Typography>
                                </Link>
                                <Link to={"/contact/"} className={classes.link}>
                                    <Typography color={'inherit'}>
                                        Nous contacter
                                    </Typography>
                                </Link>
                                <a href="http://www.purjus.fr/" target={'_blank'} rel={'noopener noreferrer'} className={classes.link}>
                                    <Typography color={'inherit'} className={classes.inline}>
                                        Design et conception:
                                    </Typography>
                                    <LogoPurjus height="10px" className={classes.logo}/>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </React.Fragment>
        )
    }
};

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default compose(
    withRouter,
    withStyles(styles)
)(Footer);
