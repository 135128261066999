import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';
import AD13Swiper from '../article/AD13Swiper';
import {Grid, Typography, withStyles} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';

const styles = theme => ({
    sliderWrapper: {
        [theme.breakpoints.up('md')]: {
            position: 'relative',
            marginTop: -112,
        },
        zIndex: 110,
    },
    wrapper: {
        ...theme.mixins.gutters(),
        marginBottom: 48,
        marginTop: 40,
        position: 'relative',
    },
    title: {
        color: theme.palette.background.lightgrey,
        marginBottom: theme.spacing.unit * 6,
    },
    pseudoAside: {
        background: theme.palette.background.lightgrey,
    }
});

class HomeSwiper extends React.Component {
    render() {
        const {classes, width, items} = this.props,
            filteredItems = items.filter(item => 'PUBLISHED' === item.status.identifier);
        return (
            <React.Fragment>
                <Grid item xs={12} md={7} className={classes.sliderWrapper}>
                    <Grid container className={classes.wrapper}>
                        <Grid item xs={12}>
                            <Typography variant={isWidthUp('md', width) ? 'h1' : 'h2'}  className={classes.title}>
                                Derniers articles
                            </Typography>
                        </Grid>
                        <AD13Swiper items={filteredItems}/>
                    </Grid>
                </Grid>
                <Grid item xs={false} md={5} component="aside" className={classes.pseudoAside}/>
            </React.Fragment>
        )
    }
}

HomeSwiper.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
}

export default compose(
    withRouter,
    withWidth(),
    withStyles(styles)
)(HomeSwiper)
