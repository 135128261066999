import React from 'react';
import {withStyles, Grid, Typography} from '@material-ui/core';
import AccountForm from '../account/AccountForm';
import AccountData from '../account/AccountData';
import CtaCheck from '../partials/AD13CtaCheck';
import CtaEdit from '../partials/AD13CtaEdit';

const styles = theme => ({
    container: {
        position: 'relative'
    },
    title: {
        paddingBottom: theme.spacing.unit * 2,
    }
})

class AccountAside extends React.Component {
    render() {
        const {classes, isEditable, handleEdit, association, validateForm, handleChange, modifyError} = this.props;

        return (
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Typography variant={'subtitle1'} className={classes.title}>
                        Informations
                    </Typography>
                </Grid>
                {isEditable ?
                    <React.Fragment>
                        <CtaCheck validateForm={validateForm}/>
                        <AccountForm handleChange={handleChange}  association={association} modifyError={modifyError}/>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <CtaEdit handleEdit={handleEdit}/>
                        <AccountData association={association}/>
                    </React.Fragment>
                }
            </Grid>

        )
    }
}

export default withStyles(styles)(AccountAside)

