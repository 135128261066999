import React from 'react';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link, Redirect} from 'react-router-dom';
import decodeJwt from "jwt-decode";
import FormHelperText from '@material-ui/core/FormHelperText';
import PageLayout from '../partials/PageLayout';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import Container from '../partials/Container';
import {withStyles, FormControl, FormControlLabel, FormLabel, Grid, Hidden, Radio, RadioGroup, Typography} from '@material-ui/core/'
import AD13Input from '../partials/AD13Input';
import AD13Button from '../partials/AD13Button';
import {fetch, normalize} from '../../utils/dataAccess'
import { modify} from "../../actions/member/adminAssociationModification";
import {loadState} from '../../store/localstorage';



const styles = theme => ({
    cta: {
        marginBottom: theme.spacing.unit * 10,
        marginRight: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit * 4,
    },
    link: {
        color: theme.palette.secondary.main,
        textDecoration: 'underline',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    formControl: {
        marginTop: theme.spacing.unit * 3,
        width: '100%'
    }
});

class Preferences extends React.Component {

    state = {
        isRetrieved : false,
        uuid : null,
        form: {},
        civilities: [],
    };

    componentDidMount() {
        if(this.props.token) {
            const decodedToken = decodeJwt(this.props.token);

            this.populatesCivilitiesChoices();

            let localState = loadState();

            fetch('/members/' + localState.storeAuth.user.uuid)
                .then(response =>
                    response
                        .json()
                        .then(retrieved => {
                                this.setState(state => {
                                    state.uuid = decodedToken.id;
                                    state.form.civilityId = retrieved.civility.uuid;
                                    state.form.firstName = retrieved.firstName;
                                    state.form.lastName = retrieved.lastName;
                                    state.form.postalCode = retrieved.postalCode;
                                    state.form.address = retrieved.address;
                                    state.form.email = retrieved.email;
                                    state.form.phoneNumber = retrieved.phoneNumber;
                                    state.isRetrieved = true;
                                    return state
                                });
                            }
                        ))
        }
    };

    componentWillReceiveProps(props) {
        if (props.hasOwnProperty('retrieved') && null !== props.retrieved) {
            this.setState(state => {
                state.form.civilityId = props.retrieved.civilityId;
                state.form.firstName = props.retrieved.firstName;
                state.form.lastName = props.retrieved.lastName;
                state.form.postalCode = props.retrieved.postalCode;
                state.form.address = props.retrieved.address;
                state.form.email = props.retrieved.email;
                state.form.phoneNumber = props.retrieved.phoneNumber;
                state.isRetrieved = true;
                return state
            });
        }
    };

    handleChange = name => event => {
        let value = event.target.value;
        this.setState(state => {
            state.form[name] = value;
            return state;
        });
    };

    populatesCivilitiesChoices = () => {
        return new Promise((resolve) => {
            fetch('/civilities')
                .then(response =>
                    response
                        .json()
                        .then(retrieved => {
                            retrieved = normalize(retrieved);
                            this.setState({civilities: retrieved['hydra:member']})
                        })
                )
        });
    };

    validateForm = () => {
        let localState = loadState();
        this.props.modify(localState.storeAuth.user.uuid, this.state.form);
    };

    render() {
        const {classes} = this.props;
        if (!this.props.isAuthenticated) {
            return (
                <Redirect to={'/inscription'}/>
            )
        }
        return (
            <PageLayout title={'Préférences'}>
                <Container>
                    <React.Fragment>
                        <Main title={'Préférences'}>
                            <Grid container style={{maxWidth: 500}}>
                                <Typography variant={'subtitle1'}>
                                    Informations
                                </Typography>
                                <form noValidate autoComplete="off">
                                    <FormControl className={classes.formControl}>
                                        <FormLabel component="legend">Civilité</FormLabel>
                                        {0 < this.state.civilities.length &&
                                        <RadioGroup
                                            aria-label="civility"
                                            name="Civilité"
                                            onChange={this.handleChange('civilityId')}
                                            value={this.state.form.civilityId ? this.state.form.civilityId : ''}
                                            row
                                        >
                                            {this.state.civilities.map(civility => (
                                                <FormControlLabel
                                                    key={civility.uuid}
                                                    value={civility.uuid}
                                                    control={<Radio color="primary" />}
                                                    label={civility.name}
                                                    labelPlacement="start"
                                                />
                                            ))}
                                        </RadioGroup>}
                                    </FormControl>
                                    <AD13Input
                                        id="outlined-firstName"
                                        label="Prénom"
                                        type="text"
                                        className={classes.textField}
                                        value={this.state.form.firstName ? this.state.form.firstName : ''}
                                        onChange={this.handleChange('firstName')}
                                        error={this.props.modifyError.hasOwnProperty('firstName')}
                                        fullWidth
                                    />
                                    {this.props.modifyError.hasOwnProperty('firstName') &&
                                    <FormHelperText error={true}>{this.props.modifyError.firstName}</FormHelperText>}

                                    <AD13Input
                                        id="outlined-name"
                                        label="Nom"
                                        type="text"
                                        className={classes.textField}
                                        value={this.state.form.lastName ? this.state.form.lastName : ''}
                                        onChange={this.handleChange('lastName')}
                                        error={this.props.modifyError.hasOwnProperty('lastName')}
                                        fullWidth
                                    />
                                    {this.props.modifyError.hasOwnProperty('lastName') &&
                                    <FormHelperText error={true}>{this.props.modifyError.lastName}</FormHelperText>}

                                    <AD13Input
                                        id="outlined-postalCode"
                                        label="Code postal"
                                        type="text"
                                        className={classes.textField}
                                        value={this.state.form.postalCode ? this.state.form.postalCode : ''}
                                        onChange={this.handleChange('postalCode')}
                                        error={this.props.modifyError.hasOwnProperty('postalCode')}
                                        fullWidth
                                    />
                                    {this.props.modifyError.hasOwnProperty('postalCode') &&
                                    <FormHelperText error={true}>{this.props.modifyError.postalCode}</FormHelperText>}

                                    <AD13Input
                                        id="outlined-address"
                                        label="Adresse"
                                        type="text"
                                        className={classes.textField}
                                        value={this.state.form.address ? this.state.form.address : ''}
                                        onChange={this.handleChange('address')}
                                        error={this.props.modifyError.hasOwnProperty('address')}
                                        fullWidth
                                    />
                                    {this.props.modifyError.hasOwnProperty('address') &&
                                    <FormHelperText error={true}>{this.props.modifyError.address}</FormHelperText>}

                                    <AD13Input
                                        id="outlined-tel"
                                        label="Téléphone"
                                        type="tel"
                                        className={classes.textField}
                                        value={this.state.form.phoneNumber ? this.state.form.phoneNumber : ''}
                                        onChange={this.handleChange('phoneNumber')}
                                        error={this.props.modifyError.hasOwnProperty('phoneNumber')}
                                        fullWidth
                                    />

                                    <FormHelperText>
                                        Je déclare être parfaitement informé par PURJUS COMMUNICATION que le renseignement des champs de ce formulaire constitue un traitement de données au sens du <a
                                        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees" target="_blank" rel="noopener noreferrer">Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016</a> et avoir notamment satisfait préalablement au respect des articles 6 et 15 à 22 du texte.
                                        <Link style={{display: 'block'}} to={'/consentement'}>En savoir plus</Link>
                                    </FormHelperText>

                                    {this.props.modifyError.hasOwnProperty('phoneNumber') &&
                                    <FormHelperText error={true}>{this.props.modifyError.phoneNumber}</FormHelperText>}

                                    {null !== this.props.modified &&
                                    <FormHelperText>Les préférences ont bien été validées</FormHelperText>}
                                </form>
                                <Grid item xs={12} align={'right'} className={classes.cta}>
                                    <AD13Button text={'valider'} onClick={this.validateForm}/>
                                </Grid>
                                <Typography>
                                    pour supprimer votre compte, <Link to={'/contact/'} className={classes.link}>cliquez-ici</Link>
                                </Typography>
                            </Grid>
                        </Main>
                        <Hidden smDown>
                            <Aside/>
                        </Hidden>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

Preferences.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        modifyError: state.member.adminAssociationModification.error || {},
        modifyLoading: state.member.adminAssociationModification.loading,
        modified: state.member.adminAssociationModification.modified,
        token: state.storeAuth.token,
        isAuthenticated: state.storeAuth.isAuthenticated,
    }
};

const mapDispatchToProps = dispatch => ({
    modify: (id, values) => dispatch(modify(id, values)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(Preferences);

