import React, {Component} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import FormationListItemDates from './FormationListItemDates';
import FormationListItemDiamond from './FormationListItemDiamond';
import FormationListItemMedia from './FormationListItemMedia';
import FormationListItemContent from './FormationListItemContent';
import FormationModal from './FormationModal';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {withStyles, Grid, Typography} from '@material-ui/core';
import {compute} from '../../actions/member/authenticate';
import ReactDom from 'react-dom';

const styles = theme => ({
    container: {
        paddingTop: theme.spacing.unit * 4,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing.unit * 3
        }
    },
    firstContainer: {
        overflow: 'hidden'
    }
});

class FormationListItem extends Component {

    state = {
        isModalOpen: false
    };

    handleModal = () => {
        if (this.state.isModalOpen) {
            this.props.fetchTrainings()
        }
        this.setState({isModalOpen: !this.state.isModalOpen})
    };

    handleLoginModal = () => {
        this.props.computeAuthentication(!this.props.isComputingAuthentication);
    };

    componentDidMount() {
        if (this.props.item['@id'] === this.props.nextFormation) {
            ReactDom.findDOMNode(this).scrollIntoView({behavior: 'smooth', block: "end"});
        };
    };

    render() {
        const {classes, width, item, isSectionChange, nextFormation, storeAuth} = this.props,
            isMobile = !isWidthUp('md', width)
        return (
            <Grid container wrap={'nowrap'} alignItems={isMobile ? 'flex-start' : 'center'} className={`${classes.container} ${isSectionChange && classes.firstContainer}`}>
                <FormationListItemDates item={item} nextFormation={nextFormation}/>
                <FormationListItemDiamond item={item} nextFormation={nextFormation}/>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <FormationListItemMedia item={item}/>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormationListItemContent item={item} handleModal={this.handleModal}/>
                    </Grid>
                    {!storeAuth.isAuthenticated &&
                        <Typography variant={'caption'} style={{paddingTop: 16}}>
                            Vous êtes responsable d'une commune ?
                            <Typography component={'span'} color={'secondary'}
                                style={{cursor: 'pointer', display: 'inline-block'}}
                                onClick={this.handleLoginModal}
                            >
                                &nbsp;Connectez-vous
                            </Typography>.
                        </Typography>
                    }
                </Grid>
                <FormationModal item={item} handleModal={this.handleModal} isModalOpen={this.state.isModalOpen}/>
            </Grid>
        );
    }
};

const mapStateToProps = state => {
    return {
        storeAuth: state.storeAuth,
        isComputingAuthentication: state.member.authenticate.compute
    }
};

const mapDispatchToProps = dispatch => ({
    computeAuthentication: (value) => dispatch(compute(value))
});

FormationListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    isSectionChange: PropTypes.bool.isRequired
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter,
    withWidth(),
    withStyles(styles)
)(FormationListItem);
