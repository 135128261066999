import React from 'react';
import {withStyles, Avatar, Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core';
import {fetch, normalize} from "../../utils/dataAccess";
import Scrollspy from 'react-scrollspy'
import { HashLink as Link } from 'react-router-hash-link';
import SectionTitle from '../partials/SectionTitle';

const style = theme => ({
    container: {
        ...theme.mixins.gutters(),
        marginTop: 200,
        paddingBottom: theme.spacing.unit * 6
    },
    list: {
        width: '100%'
    },
    listItem: {
        cursor: 'pointer',
        paddingBottom: 0,
        paddingTop: 0
    },
    avatar: {
        background: 'transparent',
        color: theme.palette.textPrimary
    },
    text: {
        textTransform: 'lowercase',
        '&::first-letter': {
            textTransform: 'uppercase',
        }
    },
    menuList: {
        marginTop: -100,
        paddingLeft: 0
    }
})

let letters = []

class CarteCommunesList extends React.Component {

    state = {
        associations: {}
    };

    componentDidMount() {
        fetch('associations/map?pagination=false').then(response => {
            response.json().then(data=>{
                let associations = normalize(data);

                let associationsPartOfAD13 = associations['hydra:member'].filter(
                    a => a.partOfAD13
                )

                associationsPartOfAD13.sort(function(a,b) {
                    if (a.mapZoneLabel[0] > b.mapZoneLabel[0]) {
                        return 1;
                    } else if (a.mapZoneLabel[0] < b.mapZoneLabel[0]) {
                        return -1;
                    }
                    return 0;
                })

                this.setState({associations: associationsPartOfAD13});
            })
        })
    }

    render() {
        const {associations} = this.state;
        const {classes, toggleDrawer} = this.props;
        let isLetterChange = true;
        let lastLetter = null;
        return (
            <Grid container className={classes.container}>
                <SectionTitle title={"Adhérents AD13"} color={'primary'} paddingBottom={16} variant={'h5'}/>
                <Grid item xs={10}>
                    <List className={classes.list} id={'list'}>
                        {0 < associations.length && associations.map((association, index) => (
                            isLetterChange = lastLetter !== association.mapZoneLabel[0],
                            lastLetter = association.mapZoneLabel[0],
                            association.partOfAD13 && isLetterChange && letters.push('listItem_' + index),
                            association.partOfAD13 &&
                            <ListItem disableGutters className={classes.listItem} key={index} onClick={() => toggleDrawer(associations[index])}>
                                <ListItemIcon>
                                    <Avatar className={classes.avatar} id={'listItem_' + index}>
                                        {isLetterChange && association.mapZoneLabel[0]}
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText disableTypography
                                    primary={
                                        <Typography variant={'body1'} color={'textSecondary'} className={classes.text}>
                                            {association.mapZoneLabel}
                                        </Typography>
                                    }/>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={2} style={{position: 'relative'}}>
                    <Grid container style={{position: 'fixed'}}>
                        <Scrollspy className={classes.menuList} items={[...letters]} currentClassName={'toto'} rootEl={'#list'}>
                            {0 < associations.length && associations.map((association, index) => (
                                isLetterChange = lastLetter !== association.mapZoneLabel[0],
                                lastLetter = association.mapZoneLabel[0],
                                association.partOfAD13 && isLetterChange &&
                                <Link  key={index} to={'#listItem_' + index} smooth>
                                    <ListItem>
                                        <ListItemText>
                                            {association.mapZoneLabel[0]}
                                        </ListItemText>
                                    </ListItem>
                                </Link>
                            ))}
                        </Scrollspy>
                    </Grid>
                </Grid>
            </Grid>

        )
    }
}


export default withStyles(style)(CarteCommunesList)
