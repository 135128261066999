import React from 'react';
import {withStyles, List, ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core'
import {LocationOn, Phone, Email, Public, Person, Group, Wc, LocalShipping} from '@material-ui/icons/'

const styles = theme => ({
    listItem: {
        alignItems: 'flex-start',
        minHeight: theme.spacing.unit * 4,
    },
    listItemIcon: {
        marginTop: theme.spacing.unit * 0.5,
    },
});

const DataListItem = (props) => {
    return (
        <ListItem className={props.classes.listItem}>
            <ListItemIcon className={props.classes.listItemIcon}>
                {props.icone}
            </ListItemIcon>
            <ListItemText
                primary={props.primary}
                secondary={props.secondary}
            />
        </ListItem>
    )
};

class AccountData extends React.Component {
    render() {
        const {classes, association} = this.props;
        return (
            <List>
                <DataListItem
                    classes={classes}
                    icone={<LocationOn color={'secondary'}/>}
                    primary={
                        <React.Fragment>
                            {association.address && association.address.split(',').map((address, index) =>
                                <Typography key={index}>{address}</Typography>
                            )}
                        </React.Fragment>
                    }
                />
                <DataListItem
                    classes={classes}
                    icone={<Phone color={'secondary'}/>}
                    primary={<Typography>{association.phoneNumber}</Typography>}
                />
                <DataListItem
                    classes={classes}
                    icone={<Email color={'secondary'}/>}
                    primary={<Typography>{association.email}</Typography>}
                />
                <DataListItem
                    classes={classes}
                    icone={<Public color={'secondary'}/>}
                    primary={<Typography>{association.websiteUrl}</Typography>}
                />
                <DataListItem
                    classes={classes}
                    icone={<Person color={'secondary'}/>}
                    primary={<Typography>{association.accountant?association.accountant.split(',').length:0} Responsable(s)</Typography>}
                    secondary={
                        <React.Fragment>
                            {association.accountant && association.accountant.split(',').map((responsible, index) =>
                                <Typography key={index} variant={'caption'}>{responsible}</Typography>
                            )}
                        </React.Fragment>
                    }
                />
                <DataListItem
                    classes={classes}
                    icone={<Group color={'secondary'}/>}
                    primary={<Typography>{association.assistant && association.assistant?association.assistant.split(',').length:0} Adjoint(s)</Typography>}
                    secondary={
                        <React.Fragment>
                            {association.assistant && association.assistant.split(',').map((deputy, index) =>
                                <Typography key={index} variant={'caption'}>{deputy}</Typography>
                            )}
                        </React.Fragment>
                    }
                />
                {/*<DataListItem*/}
                    {/*classes={classes}*/}
                    {/*icone={<GpsFixed color={'secondary'}/>}*/}
                    {/*primary={<Typography>zone 5</Typography>}*/}
                {/*/>*/}
                <DataListItem
                    classes={classes}
                    icone={<Wc color={'secondary'}/>}
                    primary={<Typography>{null === association.numberOfMembers ? 0 : association.numberOfMembers } membre(s)</Typography>}
                />
                <DataListItem
                    classes={classes}
                    icone={<LocalShipping color={'secondary'}/>}
                    primary={<Typography>{association.means}</Typography>}
                />
            </List>
        )
    }
}

export default withStyles(styles)(AccountData)

