import React from 'react'
import PropTypes from 'prop-types'
import AD13_twitter from '../../assets/AD13_twitter.svg'
import AD13_facebook from '../../assets/AD13_facebook.svg'
import MailIcon from '@material-ui/icons/Mail'
import {withStyles, Grid, IconButton, Tooltip} from '@material-ui/core'

const styles = theme => ({
    icon: {
        color: theme.palette.textSecondary,
    }
});

class AD13SocialLinks extends React.Component {
    render() {
        const {classes, socialData} = this.props,
            url = encodeURI(window.location.href),
            subject = encodeURI(socialData.subject);
        return (
            <Grid container direction={'column'} className={classes.socials}>
                <Grid item align={'center'}>
                    <Tooltip title={'Partagez cet article sur twitter'}>
                        <a target={'_blank'} rel={'noreferrer noopener'}
                           href={'https://twitter.com/intent/tweet?text=' + subject + ' sur: &url=' + url + '&hashtags=AD13'}>
                            <IconButton>
                                <img src={AD13_twitter} alt="twitter" height="18px"/>
                            </IconButton>
                        </a>
                    </Tooltip>
                </Grid>
                <Grid item align={'center'}>
                    <Tooltip title={'Partagez cet article sur facebook'}>
                        <a target={'_blank'} rel={'noreferrer noopener'}
                           href={'https://www.facebook.com/sharer/sharer.php?u=' + url + '&quote=' + subject}>
                            <IconButton>
                                <img src={AD13_facebook} alt="facebook" height="18px"/>
                            </IconButton>
                        </a>
                    </Tooltip>
                </Grid>
                <Grid item align={'center'}>
                    <Tooltip title={'Partagez cet article par mail'}>
                        <a target={'_blank'} rel={'noreferrer noopener'}
                           href={'mailto:?subject=Cet article pourrait vous intéresser: ' + subject + '&body=' + url}>
                            <IconButton>
                                <MailIcon className={classes.icon}/>
                            </IconButton>
                        </a>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }
}

AD13SocialLinks.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AD13SocialLinks);

