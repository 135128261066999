import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import {withStyles, Button, Grid, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Typography} from '@material-ui/core';

const styles = theme => ({
    rightPanel: {
        background: theme.palette.background.lightgrey,
        height: '100%',
        paddingBottom: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit * 5,
        paddingRight: theme.spacing.unit * 5,
        paddingTop: theme.spacing.unit * 4,
    },
    rightPanelContent: {
        flexGrow: 2,
    },
    rightPanelList: {
        flexGrow: 1,
        maxHeight: 250,
        overflowY: 'auto',
        width: '100%',
    },
    rightPanelActions: {
        marginTop: theme.spacing.unit * 3
    }
});

class SelectedMembersPanel extends Component {
    render() {
        const {classes, item, formationMembers, removeMemberToFormation, handleModal, storeSelectedMembers, numberOfParticipants, formationWaitingMembers} = this.props;
        return (
            <Grid container direction={'column'} className={classes.rightPanel}>
                <Grid container justify={'space-between'} className={classes.rightPanelHeader}>
                    <Typography variant={'h4'} color={'primary'}>
                        Sélectionnés ({formationMembers.length})
                    </Typography>
                    <Typography>
                        {item.numberMaxOfParticipants - numberOfParticipants} places restantes
                    </Typography>
                </Grid>
                <Grid container className={classes.rightPanelContent}>
                    <List className={classes.rightPanelList}>
                        {formationMembers.map((formationMember, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={formationMember.firstName + ' ' + formationMember.lastName}/>
                                <ListItemSecondaryAction>
                                    <IconButton aria-label="Delete" onClick={e => removeMemberToFormation(formationMember.id)}>
                                        <Close />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                {0 === item.numberMaxOfParticipants - numberOfParticipants &&
                    <Typography variant="caption" color={'primary'} style={{paddingBottom: 16}}>
                        Tout nouvel inscrit sera placé sur liste d'attente et pourra effectuer la formation en cas de désistement
                    </Typography>
                }
                {0 < formationWaitingMembers.length &&
                    <Grid container className={classes.rightPanelContent}>
                        <Typography variant="body2">Liste d'attente</Typography>
                        <List className={classes.rightPanelList}>
                            {formationWaitingMembers.map((formationWaitingMember, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={formationWaitingMember.firstName + ' ' + formationWaitingMember.lastName}/>
                                    <ListItemSecondaryAction>
                                        <IconButton aria-label="Delete" onClick={e => removeMemberToFormation(formationWaitingMember.id)}>
                                            <Close />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                }
                <Grid container justify={'flex-end'} spacing={16} className={classes.rightPanelActions}>
                    <Grid item>
                        <Button
                            variant={'contained'}
                            onClick={handleModal}
                        >
                            Annuler
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            onClick={storeSelectedMembers}
                        >
                            Terminer
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

SelectedMembersPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    formationMembers: PropTypes.array.isRequired,
    removeMemberToFormation: PropTypes.func.isRequired,
    handleModal: PropTypes.func.isRequired,
    storeSelectedMembers: PropTypes.func.isRequired,
};

export default withStyles(styles)(SelectedMembersPanel);
