import React from 'react';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import AccountMain from '../account/AccountMain';
import AccountAside from '../account/AccountAside';
import {fetch} from '../../utils/dataAccess';
import {loadState} from "../../store/localstorage";

class Account extends React.Component {
    state = {
        isEditable: false,
        association: null,
        modifyError: {}
    };

    fetchAssociationDatas = () => {
        fetch('/associations/' + this.props.match.params.id).then(response => {
            response.json().then(association => {

                let associationState = {};

                Object.keys(association).forEach(key=>{
                    associationState[key] = association[key]?association[key]:'';
                });


                this.setState(state => ({
                    association: associationState
                }))
            })
        })
    };

    componentDidMount() {
        this.fetchAssociationDatas()
    }

    handleChange = name => event => {
        let association = {...this.state.association}
        association[name] = event.target.value;
        this.setState({
            association: association,
        });
    };

    handleChangeValue = (name, value) => {
        let association = {...this.state.association}
        association[name] = value;
        this.setState({
            association: association,
        });
    };

    validateForm = () => {
        let values = {
            address: this.state.association.address,
            email: this.state.association.email,
            websiteUrl: this.state.association.websiteUrl,
            phoneNumber: this.state.association.phoneNumber,
            accountant: this.state.association.accountant,
            assistant: this.state.association.assistant,
            numberOfMembers: parseInt(this.state.association.numberOfMembers),
            means: this.state.association.means,
            description: this.state.association.description,
            image: this.state.association.image?this.state.association.image['@id']:null
        };

        fetch('/associations/' + this.props.match.params.id, {
            method: 'PUT',
            body: JSON.stringify(values)
        }).then(response => {
            response.json().then(association => {
                this.setState(state => ({
                    association: association
                }))
                this.handleEdit();
            })
        }).catch(error => {
            this.setState(state => ({
                modifyError: error.errors
            }))
        });

    };

    canModify = () =>  {
        const localState = loadState();

        if(false === localState.storeAuth.isAuthenticated){
            return false;
        }

        return localState.storeAuth.user.associations.some(association =>{
            return association.uuid===this.state.association.uuid;
        });
    };

    handleEdit = () => {
        this.setState({
            isEditable: !this.state.isEditable
        });
    };

    render() {
        const {isEditable, association, modifyError} = this.state;

        return association && this.canModify() && (
            <PageLayout title={association.mapZoneLabel}>
                <Container>
                    <React.Fragment>
                        <Main title={association.associationType?association.associationType.name + ' : ' + association.mapZoneLabel:''}>
                            <AccountMain
                                isEditable={isEditable}
                                handleEdit={this.handleEdit}
                                association={association}
                                validateForm={this.validateForm}
                                handleChangeValue={this.handleChangeValue}
                                fetchAssociationDatas={this.fetchAssociationDatas}
                            />
                        </Main>
                        <Aside>
                            <AccountAside
                                isEditable={isEditable}
                                handleEdit={this.handleEdit}
                                validateForm={this.validateForm}
                                handleChange={this.handleChange}
                                association={association}
                                modifyError={modifyError}
                            />
                        </Aside>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

export default Account;

