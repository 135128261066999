import {combineReducers} from 'redux';

export function compute(state = false, action) {
    switch (action.type) {
        case 'MEMBER_AUTHENTICATE_COMPUTE':
            return action.compute;

        default:
            return state;
    }
}

export function error(state = false, action) {
    switch (action.type) {
        case 'MEMBER_AUTHENTICATE_ERROR':
            return action.error;

        default:
            return state;
    }
}

export function loading(state = false, action) {
    switch (action.type) {
        case 'MEMBER_AUTHENTICATE_LOADING':
            return action.loading;

        default:
            return state;
    }
}

export function authenticated(state = null, action) {
    switch (action.type) {
        case 'MEMBER_AUTHENTICATE_SUCCESS':
            return action.authenticated;

        default:
            return state;
    }
}

export default combineReducers({error, loading, authenticated, compute});
