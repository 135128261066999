import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {withStyles, Grid, Typography} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'

const styles = theme => ({
    dateContainer: {
        color: theme.palette.textPrimary,
        [theme.breakpoints.up('md')]:{
            minWidth: 70,
        }
    },
    dateContainerActive: {
        color: theme.palette.primary.main,
    },
    month: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
})

class FormationListItemDates extends Component {
    render() {
        const {classes, width, item, nextFormation} = this.props,
            isMobile = !isWidthUp('md', width),
            formationDay = new Date(item.events[0].dateStart).toLocaleString('fr-FR', {day: "numeric"}),
            formationMonth = new Date(item.events[0].dateStart).toLocaleString('fr-FR', {month: isMobile ? 'short' : "long"}),
            isNext = item['@id'] === nextFormation
        return (
            <Grid item className={`${classes.dateContainer} ${isNext && classes.dateContainerActive}`}>
                <Typography color={'inherit'} variant={'h6'} align={'right'}>
                    {formationDay}
                </Typography>
                <Typography color={'inherit'} variant={'caption'} align={'right'} className={classes.month}>
                    {formationMonth}
                </Typography>
            </Grid>
        );
    }
}

FormationListItemDates.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    nextFormation: PropTypes.string.isRequired,
}

export default compose(
    withWidth(),
    withStyles(styles)
)(FormationListItemDates);
