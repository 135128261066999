import {ENTRYPOINT} from './config/entrypoint'


const Config =  {
    api: {
        entryPoint: ENTRYPOINT
    }
};

export default Config;
