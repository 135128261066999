import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {withStyles} from '@material-ui/core/';

const styles = theme => ({
    svg: {
        cursor: 'pointer',
        pointerEvents: 'all',
        position: 'absolute',
        left: 'calc(50% - 40px)',
        bottom: -40,
        zIndex: 600,
        [theme.breakpoints.up('md')]: {
            left: 'calc(50% - 40px)',
            bottom: 80,
        }
    },
    container: {
        fill: theme.palette.primary.main,
        '&:hover': {
            fill: theme.palette.primary.light,
        }
    }
})

const AD13CtaDown = props => (
    <Link to={"#slider"} smooth={true} className={props.classes.svg}>
        <svg width={80} height={80} {...props}>
            <path className={props.classes.container} d="M-.022 40L39.999-.022 80.022 40 40 80.022z"/>
            <path
                d="M48.455 34.312l1.414 1.415-9.9 9.899-1.413-1.414z"
                fill="#fff"
            />
            <path
                d="M41.385 44.273l-1.415 1.414-9.9-9.9 1.415-1.414z"
                fill="#fff"
            />
        </svg>
    </Link>
)

export default withStyles(styles)(AD13CtaDown)
