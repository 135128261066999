import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormationListItem from './FormationListItem';
import {Grid} from '@material-ui/core';
import FormationListTitle from './FormationListTitle';

class FormationList extends Component {

    reverseArray = (arr) => {
        var newArray = [];
        for (var i = arr.length - 1; i >= 0; i--) {
            newArray.push(arr[i]);
        }
        return newArray;
    };

    render() {
        const {items, nextFormation, fetchTrainings} = this.props,
            reversedArray = this.reverseArray(items['hydra:member']);

        let isSectionChange = true;
        let lastMonth = null;

        return (
            reversedArray.map((item, index) => {
                let year = new Date(item.events[0].dateStart).toLocaleString('fr-Fr', {year: 'numeric'});
                let month = new Date(item.events[0].dateStart).toLocaleString('fr-Fr', {month: 'long'});

                isSectionChange = lastMonth !== month;
                lastMonth = month;
                return (
                    <Grid container key={item['@id']}>
                        {isSectionChange &&
                            <FormationListTitle item={item} month={month} year={year} index={index}/>
                        }
                        <Grid item xs={12} key={item['@id']}>
                            <FormationListItem
                                item={item}
                                nextFormation={nextFormation}
                                isSectionChange={isSectionChange}
                                fetchTrainings={fetchTrainings}
                            />
                        </Grid>
                    </Grid>
                )
            })
        );
    }
};

FormationList.propTypes = {
    items: PropTypes.object.isRequired,
    nextFormation: PropTypes.string.isRequired,
};

export default FormationList;
