import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/';
import {Grid} from '@material-ui/core';
import AD13_croix from '../../assets/AD13_croix.svg';

const size = 60,
    coteCta = Math.sqrt(Math.pow(size, 2) / 2),
    pointe = (size - coteCta) / 2

const styles = theme => ({
    cta: {
        height: size,
        position: 'absolute',
        right: 40,
        top: 220 - (size / 2),
        width: size,
        zIndex: 6000,
    },
    ctaAction: {
        alignItems: "center",
        color: theme.palette.textSecondary,
        display: "flex",
        height: size,
        justifyContent: "center",
        margin: 'auto',
        position: 'relative',
        textDecoration: 'none',
        width: size,
        zIndex: 10,
        '&:hover': {
            color: theme.palette.textSecondary,
            textDecoration: 'none',
        },
        '&::before': {
            backgroundColor: theme.palette.tertiary.main,
            content: "''",
            display: 'block',
            height: coteCta,
            left: pointe,
            position: 'absolute',
            top: pointe,
            transform: 'rotateZ(45deg)',
            transition: theme.transition.smooth,
            width: coteCta,
            zIndex: 0,
        },
        '&:hover::before': {
            background: theme.palette.tertiary.light,
            cursor: 'pointer',
        },
    },
    ctaIcon: {
        zIndex: "inherit",
        height: '30%',
        transform: 'rotateZ(45deg)',
        width: '30%',
    }
});

class CarteDrawerCTA extends React.Component {
    render() {
        const {classes, toggleDrawer} = this.props;
        return (
            <Grid item className={classes.cta}>
                <Grid item onClick={() => toggleDrawer(false)} className={classes.ctaAction}>
                    <img src={AD13_croix} alt="voir la suite" className={classes.ctaIcon}/>
                </Grid>
            </Grid>
        )
    }
}

CarteDrawerCTA.propTypes = {
    classes: PropTypes.object.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
}

export default withStyles(styles)(CarteDrawerCTA);
