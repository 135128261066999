import React from 'react'
import {withStyles} from '@material-ui/core/';

const styles = theme => ({
    ctaEdit: {
        fill: theme.palette.primary.main,
        position: 'absolute',
        right: 0,
        top: -64,
        zIndex: 600,
        [theme.breakpoints.up('md')]: {
            left: - 80,
            top: -100,
        },
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.primary.light,
        }
    },
    square: {
        fill: 'inherit'
    },
    icon: {
        fill: theme.palette.secondary.contrastText,
    }
});

const AD13CtaEdit = props => (
    <svg width={80} height={80} className={props.classes.ctaEdit} onClick={props.handleEdit}>
        <path className={props.classes.square} d="M-.022 40L39.999-.022 80.022 40 40 80.022z" />
        <path className={props.classes.icon} d="M30 45.8l12.3-12.3 4.2 4.2L34.2 50H30v-4.2zm19.7-11.3l-2 2-4.2-4.2 2-2c.2-.2.5-.3.8-.3s.6.1.8.3l2.6 2.6c.2.2.3.5.3.8s-.1.6-.3.8z"/>
    </svg>
);

export default withStyles(styles)(AD13CtaEdit);
