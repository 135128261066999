import React, {Component} from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withStyles, Button, Card, FormHelperText, Grid, Modal, Typography} from '@material-ui/core';
import {fetch} from '../../utils/dataAccess';
import {list} from '../../actions/training/list';

const styles = theme => ({
    card: {
        [theme.breakpoints.down('md')]: {
            marginTop: '25vh'
        },
        [theme.breakpoints.up('md')]: {
            margin: 'auto',
            marginTop: 150,
            maxWidth: 840
        }
    },
    panel: {
        paddingBottom: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 4,
        width: '100%'
    },
    panelActions: {
        marginTop: theme.spacing.unit * 2
    }
});

class AD13MembersRemoveModal extends Component {

    constructor(props) {

        super(props);
        this.state = {
            submissionError: null,
            modifyError: {},
            formValidated: false
        };
    }

    validateForm = () => {
        const data = {
            memberId: this.props.memberUuid,
            associationId: this.props.association.uuid
        };
        fetch('/members/volunteer_removing_from_association', {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response => {
            this.setState(state => ({
                modifyError: {},
                formValidated: true
            }));
            this.props.fetchAssociationDatas();
            this.props.handleModal();
        }).catch(error => {
            if (error.hasOwnProperty('errors')) {
                this.setState(state => {
                    state.modifyError = error.errors;
                    return state;
                })
            } else {
                this.setState(state => {
                    state.modifyError = {error: error.message};
                    return state;
                })
            }
        });
    };

    render() {
        const {classes, isModalOpen, handleModal} = this.props;

        return (
            <Modal open={isModalOpen} onClose={handleModal}>
                <Card className={classes.card}>
                    <Grid container>
                        <Grid item xs={12}  className={classes.panel}>
                            <Grid container>
                                <Grid container justify={'space-between'} className={classes.title}>
                                    <Typography variant={'h4'} color={'textPrimary'} gutterBottom>
                                        Attention
                                    </Typography>
                                </Grid>
                                <Grid container direction={'column'} justify={'flex-start'} className={classes.title}>
                                    <Typography variant={'body1'} color={'textPrimary'}>
                                        Vous allez désinscrire ce bénévole
                                    </Typography>

                                    <Typography variant={'body2'} color={'textPrimary'}>
                                        Vous n'aurez plus accès à son profil et il sera désinscrit de toutes les formations à venir
                                    </Typography>
                                </Grid>
                                <Grid container justify={'space-between'} className={classes.panelActions}>
                                    <Button variant={'text'} onClick={handleModal}>Annuler</Button>
                                    <Button variant={'contained'} color={'primary'} onClick={this.validateForm}>Désinscrire</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {null !== this.state.submissionError &&
                            <FormHelperText error={true}>{this.state.submissionError}</FormHelperText>}
                        </Grid>
                    </Grid>
                </Card>
            </Modal>
        );
    }
}

AD13MembersRemoveModal.propTypes = {
    classes: PropTypes.object.isRequired,
    handleModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    association: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
    retrieveTrainings: () => dispatch(list())
});

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withStyles(styles)
)(AD13MembersRemoveModal);
