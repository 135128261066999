import React from 'react';
import ReactDom from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import * as serviceWorker from './serviceWorker';
import Routes from './Routes';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import MuiTheme from './MuiTheme';
import Login from './Components/layouts/Login';
import Footer from './Components/partials/Footer';
import Helmet from 'react-helmet';
import store from './store/store';
const theme = createMuiTheme(MuiTheme);

ReactDom.render(
    <MuiThemeProvider theme={theme}>
        <Helmet defaultTitle="AD 13" titleTemplate="AD 13 | %s " />
        <CssBaseline/>
        <Provider store={store}>
            <Router>
                <React.Fragment>
                    <Routes/>
                    <Login/>
                    <Footer/>
                </React.Fragment>
            </Router>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
