import React from 'react';
import compose from 'recompose/compose';
import {Grid, Typography, withStyles} from '@material-ui/core';
import background from '../../assets/AD13_HomeBg.jpg';
import PropTypes from 'prop-types';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import CtaDown from '../partials/AD13CtaDown';

const styles = theme => ({
    homeIntro: {
        height: 800,
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none'
    },
    homeIntroMobile: {
        height: '90vh',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    background: {
        backgroundImage: 'url(' + background + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.up('md')]: {
            backgroundPosition: '100% 0%',
            backgroundSize: '110%',
        },
        height: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '50%',
    },
    backgroundMobile: {
        width: '100%',
    },
    content: {
        height: '100%',
        margin: 'auto',
        maxWidth: theme.layout.width,
        position: 'relative',
        zIndex: 300,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    text: {
        color: theme.palette.primary.contrastText,
        pointerEvents: 'all',
        textShadow: '0px 8px 16px rgba(0,0,0,.5)'
    }
});

class HomeIntro extends React.Component {
    render() {
        const {classes, width} = this.props,
            isMobile = !isWidthUp('md', width);
        return (
            <Grid container className={isMobile ? classes.homeIntroMobile : classes.homeIntro} >
                <Grid item className={ `${classes.background} ${isMobile && classes.backgroundMobile}`}/>
                <Grid container className={classes.content} justify={isMobile ? 'flex-start' : 'flex-end'} alignItems={'center'} >
                    <Typography variant={!isMobile ? "h1" : "h2"} className={classes.text}>
                        Prévention<br/>Formation<br/>Sensibilisation
                    </Typography>
                    <CtaDown/>
                </Grid>
            </Grid>
        )
    }
}

HomeIntro.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
}

export default compose(
    withWidth(),
    withStyles(styles)
)(HomeIntro)
