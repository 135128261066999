import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {Grid, withStyles} from '@material-ui/core'
import aix_marseille from '../../assets/aix_marseille.svg';
import departement_bdr from '../../assets/departement_bdr.svg';
import onf from '../../assets/onf.svg';
import pompiers_bdr from '../../assets/pompiers_bdr.svg';
import prefecture_bdr from '../../assets/prefecture_bdr.jpg';
import region_sud from '../../assets/region_sud.svg';
import valabre from '../../assets/valabre.svg';
import Swiper from 'react-id-swiper';

const styles = theme => ({
    container: {
        background: theme.palette.background.default,
        paddingBottom: theme.spacing.unit * 4,
        paddingTop: theme.spacing.unit * 4,
        zIndex: 100,
    },
    headerContent: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters()
        },
        margin: 'auto',
        marginBottom: 0,
        marginTop: 0,
        maxWidth: theme.spacing.unit * 70
    },
    headerTitle: {
        '&:first-letter': {
            TextTransform: 'uppercase'
        }
    },
    contentSection: {
        marginBottom: theme.spacing.unit * 6
    },
    title: {
        textTransform: 'uppercase'
    },

    link: {
        display: 'flex',
        alignItems: 'center',
        justify: 'center',
        flexGrow: 1
    },
    image: {
        display: 'block',
        margin: 'auto',
    }
});

const PartnerIItem = props => {
    return (
        <Grid container direction={'column'} justify={'center'} alignItems={'center'} style={{height: '100%'}}>
            <img src={props.image} alt={props.title} style={{display: 'block', margin: 'auto'}} height={50} width={'auto'}/>
        </Grid>
    )
}

class AD13PartnersSwiper extends React.Component {
    render() {
        const {classes} = this.props,
            params = {
                slidesPerView: 'auto',
                spaceBetween: 32,
                loop: true
            };

        return (
            <Link to={'/partenaires/'}>
                <Grid container className={classes.container}>
                    <Swiper {...params}>
                        <div style={{width: 200}}>
                            <PartnerIItem
                                image={aix_marseille}
                                title={'La Métropole Aix-Marseille-Provence'}
                            />
                        </div>
                        <div style={{width: 200}}>
                            <PartnerIItem
                                image={departement_bdr}
                                title={'Le Conseil Départemental des Bouches du Rhône'}
                            />
                        </div>
                        <div style={{width: 200}}>
                            <PartnerIItem
                                image={onf}
                                title={'L\'Office national des forêts'}
                            />
                        </div>
                        <div style={{width: 200}}>
                            <PartnerIItem
                                image={pompiers_bdr}
                                title={'Le Service Départemental d\'Incendie et de Secours des Bouches du Rhône'}
                            />
                        </div>
                        <div style={{width: 200}}>
                            <PartnerIItem
                                image={prefecture_bdr}
                                title={'Préfecture de région Provence-Alpes-Côte d\'Azur'}
                            />
                        </div>
                        <div style={{width: 200}}>
                            <PartnerIItem
                                image={region_sud}
                                title={'La Région Sud'}
                            />
                        </div>
                        <div style={{width: 200}}>
                            <PartnerIItem
                                image={valabre}
                                title={'Le centre de Valabre'}
                            />
                        </div>
                    </Swiper>
                </Grid>
            </Link>
        )
    }
};

AD13PartnersSwiper.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AD13PartnersSwiper);
