import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import AD13Button from '../partials/AD13Button';
import {withStyles, Grid, Typography} from '@material-ui/core/';

const styles = theme => ({
    asideContent: {
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
            maxWidth: 460,
        },
        background: theme.palette.background.lightgrey,
        paddingBottom: theme.spacing.unit * 5,
        zIndex: 2000,
    },
    captionTitle: {
      textTransform: 'uppercase',
    },
    asideContentBlock: {
        marginBottom: theme.spacing.unit * 2,
        maxWidth: 300,
    }
});

class AD13PageAside extends React.Component {
    render() {
        const {classes, isAuthenticated} = this.props;
        return (
            <Grid container className={classes.asideContent}>
                <Grid item xs={12} id="content" className={classes.asideContentBlock}>
                    <Typography variant={'button'} color={'secondary'} className={classes.captionTitle}>
                        {isAuthenticated ? 'Soumettre un article' : 'Guide'}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.asideContentBlock}>
                    <Typography variant={'h3'}>
                        {isAuthenticated ?
                            'Participez à la vie de l\'AD13 en nous envoyant vos articles'
                            :
                            'Comment réagir face à un incendie qui menace votre habitation ?'
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.asideContentBlock}>
                    <AD13Button blank={!isAuthenticated}
                        text={isAuthenticated ? 'Soumettre' : 'Découvrir'}
                        href={isAuthenticated ? '/soumission/' : '/guides/'}/>
                </Grid>
            </Grid>
        )
    }
}

AD13PageAside.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    isAuthenticated: state.storeAuth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withStyles(styles)
)(AD13PageAside);

