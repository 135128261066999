import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {Grid, Typography, withStyles} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import AD13SingleHeader from '../partials/AD13SingleHeader';
import AD13SingleContent from '../partials/AD13SingleContent';
import logoBg from '../../assets/AD13_logo_bg.svg';
import aix_marseille from '../../assets/aix_marseille.svg';
import departement_bdr from '../../assets/departement_bdr.svg';
import onf from '../../assets/onf.svg';
import pompiers_bdr from '../../assets/pompiers_bdr.svg';
import prefecture_bdr from '../../assets/prefecture_bdr.jpg';
import region_sud from '../../assets/region_sud.svg';
import valabre from '../../assets/valabre.svg';
import {Helmet} from 'react-helmet';
import GoBackBar from '../navigation/GoBackBar';

const styles = theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing.unit * 6
        },
        maxWidth: '100%'
    },
    headerContent: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters()
        },
        margin: 'auto',
        marginBottom: 0,
        marginTop: 0,
        maxWidth: theme.spacing.unit * 70
    },
    headerTitle: {
        '&:first-letter': {
            TextTransform: 'uppercase'
        }
    },
    contentSection: {
        marginBottom: theme.spacing.unit * 6
    },
    partnerItem: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing.unit * 6
        },
        padding: theme.spacing.unit * 4
    },
    title: {
        textTransform: 'uppercase'
    },
    link: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexGrow: 1
    },
    image: {
        display: 'block',
        margin: '0 auto'
    }
});

const Partner = props => {
    return (
        <Grid item xs={12} md={props.md} className={props.classes.partnerItem}>
            <Grid container direction={'column'} style={{height: '100%'}}>
                <a href={props.href} target={'_blank'} rel={'noreferrer noopener'} className={props.classes.link}>
                    <img src={props.src} alt={props.title} height={props.height} width="auto" className={props.classes.image}/>
                </a>
                <Typography variant={'body1'} align={'center'}>
                    {props.title}
                </Typography>
            </Grid>
        </Grid>
    )
}


class Partners extends React.Component {
    render() {
        const {classes, width} = this.props,
            {isMobile} = !isWidthUp('md', width);

        return (
            <React.Fragment>
                <Helmet>
                    <title>{'Nos partenaires'}</title>
                </Helmet>
                <Grid container className={classes.container} id={'top'}>
                    <GoBackBar/>
                    <AD13SingleHeader itemImage={logoBg}>
                        <Grid container className={classes.headerContent}>
                            <Grid item xs={12}>
                                <Typography variant={isMobile ? 'h3' : "h2"} color="inherit"
                                            className={classes.headerTitle}>
                                    Les partenaires de l'ADCCFF13
                                </Typography>
                            </Grid>
                        </Grid>
                    </AD13SingleHeader>

                    <AD13SingleContent socialData={{subject: 'Nos partenaires'}}>
                        <React.Fragment>
                            <Grid container className={classes.contentSection}>
                                <Typography variant={isMobile ? 'h4' : "h3"} color="primary" className={classes.title}>
                                    Nos partenaires
                                </Typography>
                            </Grid>
                            <Grid container className={classes.contentSection}>
                                <Typography>
                                    La représentativité que l’ADCCFF 13 exerce depuis plusieurs années auprès de nos
                                    différents partenaires (Union des Maires, Conseil Départemental 13, Conseil Régional
                                    PACA, Préfecture des Bouches du Rhône, ONF, SDIS13, DDTM13, Entente
                                    Interdépartementale…) permet aux Comités Communaux Feux de Forêt et aux Réserves
                                    Communales de Sécurité Civile des Bouches du Rhône, d’être reconnus pour leurs
                                    actions
                                    et officiellement associées aux secours professionnels existants.
                                </Typography>
                            </Grid>
                            <Grid container>
                                <Partner
                                    classes={classes}
                                    href={'http://www.bouches-du-rhone.gouv.fr/Politiques-publiques/Environnement-risques-naturels-et-technologiques/La-prevention'}
                                    src={prefecture_bdr}
                                    title={'Les risques dans les Bouches du Rhône'}
                                    height={'150px'}
                                    md={12}
                                />
                                <Partner
                                    classes={classes}
                                    href={'https://www.maregionsud.fr/'}
                                    src={region_sud}
                                    title={'La Région Sud'}
                                    height={'50px'}
                                    md={6}
                                />
                                <Partner
                                    classes={classes}
                                    href={'https://www.departement13.fr/'}
                                    src={departement_bdr}
                                    title={'Le Conseil Départemental des Bouches du Rhône'}
                                    height={'50px'}
                                    md={6}
                                />
                                <Partner
                                    classes={classes}
                                    href={'https://www.onf.fr/'}
                                    src={onf}
                                    title={'L\'Office national des forêts'}
                                    height={'50px'}
                                    md={6}
                                />
                                <Partner
                                    classes={classes}
                                    href={'http://www.sdis13.fr/'}
                                    src={pompiers_bdr}
                                    title={'Le Service Départemental d\'Incendie et de Secours des Bouches du Rhône'}
                                    height={'50px'}
                                    md={6}
                                />
                                <Partner
                                    classes={classes}
                                    href={'https://www.ampmetropole.fr/'}
                                    src={aix_marseille}
                                    title={'La Métropole Aix-Marseille-Provence'}
                                    height={'100px'}
                                    md={6}
                                />
                                <Partner
                                    classes={classes}
                                    href={'http://www.prevention-incendie-foret.com'}
                                    src={valabre}
                                    title={'ENTENTE Information et Prévention'}
                                    height={'100px'}
                                    md={6}
                                />
                            </Grid>
                        </React.Fragment>
                    </AD13SingleContent>
                </Grid>
            </React.Fragment>
        )
    }
};

Partners.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withWidth(),
    withStyles(styles)
)(Partners);
