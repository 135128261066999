import React from 'react';
import PropTypes from 'prop-types';

import {withStyles, Grid} from '@material-ui/core/';

const styles = theme => ({
    container: {
        margin: 'auto',
        maxWidth: theme.layout.width,
        position: 'relative',
    },
});

class Container extends React.Component {
    render() {
        const {classes, children, marginTop} = this.props;
        return (
            <Grid container className={classes.container} style={{marginTop: marginTop ? marginTop : 0}}>
                {children}
            </Grid>
        )
    }
}

Container.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    marginTop: PropTypes.number,
}

export default withStyles(styles)(Container)

