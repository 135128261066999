import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import Container from '../partials/Container'
import SectionTitle from '../partials/SectionTitle'
import {withStyles, Grid} from '@material-ui/core'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'

const styles = theme =>  ({
    sliderWrapper: {
        ...theme.mixins.gutters(),
        background: theme.palette.background.lightgrey,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
        paddingBottom: theme.spacing.unit * 5,
        marginTop: theme.spacing.unit * 5,
    }
});

class AD13SwiperContainer extends React.Component {
    render() {
        const {classes, width, title, children} = this.props,
            isMobile = !isWidthUp('md', width);
        return (
            <Grid container className={classes.sliderWrapper}>
                <Container>
                    <React.Fragment>
                        <SectionTitle
                            title={title}
                            color={'secondary'}
                            paddingBottom={48}
                            paddingTop={48}
                            variant={isMobile ? 'h4' : 'h3'}
                        />
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    </React.Fragment>
                </Container>
            </Grid>
        )
    }
}

AD13SwiperContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
};

export default compose (
    withWidth(),
    withStyles(styles)
)(AD13SwiperContainer)

