import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import {reset} from '../../actions/article/list';
import {retrieve} from '../../actions/article/show';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import AD13SingleMetas from '../partials/AD13SingleMetas';
import AD13SingleHeader from '../partials/AD13SingleHeader';
import AD13SingleContent from '../partials/AD13SingleContent';
import AD13SwiperContainer from '../partials/AD13SwiperContainer';
import AD13Swiper from '../article/AD13Swiper';
import SliderNotReady from '../article/SliderNotReady';
import ShowNotReady from '../partials/ShowNotReady';
import {withStyles, Grid, Hidden, Typography} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {fetch} from '../../utils/dataAccess';
import GoBackBar from '../navigation/GoBackBar';

const styles = theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing.unit * 6,
        },
        maxWidth: '100%',
    },
    headerContent: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters()
        },
        margin: 'auto',
        marginBottom: 0,
        marginTop: 0,
        maxWidth: theme.spacing.unit * 70,
    },
    headerTitle: {
        '&:first-letter': {
            TextTransform: 'uppercase'
        }
    },
    headerCategory: {
        textTransform: 'uppercase'
    },

    sliderWrapper: {
        ...theme.mixins.gutters(),
        background: theme.palette.background.lightgrey,
        [theme.breakpoints.up('md')]: {
            height: 530,
            position: 'relative'
        },
        marginTop: theme.spacing.unit * 5,
    }
});

class ArticleSingle extends React.Component {

    state = {
        item: null,
        items: []
    };

    fetchArticle = (id) => {
        fetch('articles/' + id)
            .then(response => {
                response.json().then(data=>{
                    this.setState({item: data});
                    this.fetchAllArticlesFromSameCategory(data.uuid);
                })
            })
    };

    fetchAllArticlesFromSameCategory = (id) => {
        if (this.state.item) {
            fetch('articles?status.identifier=PUBLISHED&category.identifier=' + this.state.item.category.identifier)
                .then(response => {
                    response.json().then(data=>{
                        let articles = [...data['hydra:member']];
                        articles = articles.filter(item => item.uuid !== id)
                        this.setState({items: articles})
                    })
                })
        }
    };

    componentDidMount() {
        this.fetchArticle(this.props.match.params.id);
        this.fetchAllArticlesFromSameCategory(this.props.match.params.id);
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.fetchArticle(nextProps.match.params.id);
            this.fetchAllArticlesFromSameCategory(nextProps.match.params.id);
        }
    };

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    }

    render() {
        const {classes, width, loading, deleteError, deletedItem, error} = this.props,
            {item, items} = this.state,
            isMobile = !isWidthUp('md', width),
            options = {year: "numeric", month: "numeric", day: "numeric"},
            CreationDate = item && new Date(item['dateCreate']).toLocaleString('fr-FR', options),
            UpdateDate = item && new Date(item['dateUpdate']).toLocaleString('fr-FR', options),
            PublicationDate = item && (item['dateUpdate'] !== item['dateCreate']) ? UpdateDate : CreationDate,
            itemImage = item && null !== item['image'] ? item['image']['url'] : darkRabbit;
        return (
            item ?
                <React.Fragment>
                    <GoBackBar/>
                    <AD13SingleMetas item={item}/>
                    <Grid container className={classes.container}>
                        <AD13SingleHeader itemImage={itemImage}>
                            <Grid container className={classes.headerContent} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" color="inherit">
                                        {PublicationDate}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={isMobile ? 'h3' : "h2"} color="inherit" className={classes.headerTitle}>
                                        {item['title']}
                                    </Typography>
                                </Grid>
                                <Hidden mdDown>
                                    <Grid item xs={12}>
                                        <Typography variant="button" color="inherit" className={classes.headerCategory}>
                                            {item['category'].name}
                                        </Typography>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </AD13SingleHeader>
                        <AD13SingleContent socialData={{subject:item['title']}}>
                            {item['content'] && <Grid container dangerouslySetInnerHTML={{__html: '<div>' + item['content'] + '</div>'}}/>}
                        </AD13SingleContent>
                        {0 < items.length ?
                            <AD13SwiperContainer title={'Dans la même catégorie'}>
                                <AD13Swiper items={items}/>
                            </AD13SwiperContainer>
                            :
                            <SliderNotReady loading={loading} error={error} deletedItem={deletedItem}/>
                        }
                    </Grid>
                </React.Fragment>
                :
                <ShowNotReady loading={loading} error={error} deleteError={deleteError} deletedItem={deletedItem}/>

        )
    }
};

ArticleSingle.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    error: state.article.show.error,
    loading: state.article.show.loading,
    deleteError: state.article.del.error,
    deleteLoading: state.article.del.loading,
    eventSource: state.article.show.eventSource,
});

const mapDispatchToProps = dispatch => ({
    retrieve: id => dispatch(retrieve(id)),
    reset: eventSource => dispatch(reset(eventSource))
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter,
    withWidth(),
    withStyles(styles)
)(ArticleSingle);


