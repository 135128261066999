import React, {Component} from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withStyles, Button, Card, FormHelperText, Grid, Modal, Typography} from '@material-ui/core';
import {fetch} from '../../utils/dataAccess';
import {list} from '../../actions/training/list';
import AD13Input from './AD13Input';
import {loadState} from '../../store/localstorage';
import {Link} from 'react-router-dom';

const styles = theme => ({
    card: {
        [theme.breakpoints.down('md')]: {
            marginTop: '25vh'
        },
        [theme.breakpoints.up('md')]: {
            margin: 'auto',
            marginTop: 150,
            maxWidth: 840
        }
    },
    panel: {
        paddingBottom: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 4,
        width: '100%'
    },
    panelActions: {
        marginTop: theme.spacing.unit * 2
    }
});

class AD13MembersAddModal extends Component {

    constructor(props) {
        const localState = loadState();

        super(props);
        this.state = {
            submissionError: null,
            associationMembersIdsToAdd: [],
            associationMembersIdsToRemove: [],
            associationMembers: [],
            form: {
                email: '',
                firstName: '',
                lastName: '',
                associationId: localState.storeAuth.user.associations[0].uuid,
            },
            modifyError: {},
            formValidated: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (false === nextProps.isModalOpen) {
            this.setState(state => ({
                associationMembers: [],
                associationMembersIdsToAdd: [],
                associationMembersIdsToRemove: []
            }))
        } else {
            fetch('/members?pagination=false&enabled=1').then(response => {
                response
                    .json().then(data => {
                    this.setState(state => {
                        state.associationMembers = data['hydra:member'];
                        return state;
                    })
                })
            });
        }
    };

    handleChange = name => event => {
        let form = this.state.form;
        form[name] = event.target.value;
        this.setState({
            form: form,
        });
    };

    validateForm = () => {
        fetch('/members/volunteer_registration', {
            method: 'POST',
            body: JSON.stringify(this.state.form)
        }).then(response => {
            this.setState(state => ({
                modifyError: {},
                formValidated: true
            }));
            this.props.fetchAssociationDatas();
            this.props.handleModal();
        }).catch(error => {
            if (error.hasOwnProperty('errors')) {
                this.setState(state => {
                    state.modifyError = error.errors;
                    return state;
                })
            } else {
                this.setState(state => {
                    state.modifyError = {error: error.message};
                    return state;
                })
            }
        });
    };

    render() {
        const {classes, isModalOpen, handleModal} = this.props;
        return (
            <Modal open={isModalOpen} onClose={handleModal}>
                <Card className={classes.card}>
                    <Grid container>
                        <Grid item xs={12}  className={classes.panel}>
                            <Grid container>
                                <Grid container justify={'space-between'} className={classes.title}>
                                    <Typography variant={'h4'} color={'textPrimary'}>
                                        Ajouter un membre
                                    </Typography>
                                </Grid>
                                <form style={{width: '100%'}}>
                                    <AD13Input
                                        id="members-outlined-firstName"
                                        label={'Prénom'}
                                        type={'text'}
                                        value={this.state.form.firstName}
                                        onChange={this.handleChange('firstName')}
                                        fullWidth
                                        error={this.state.modifyError.hasOwnProperty('firstName')}
                                    />
                                    {this.state.modifyError.hasOwnProperty('firstName') &&
                                    <FormHelperText error={true}>{this.state.modifyError.firstName}</FormHelperText>}
                                    <AD13Input
                                        id="members-outlined-lastName"
                                        label={'Nom'}
                                        type={'text'}
                                        value={this.state.form.lastName}
                                        onChange={this.handleChange('lastName')}
                                        fullWidth
                                        error={this.state.modifyError.hasOwnProperty('lastName')}
                                    />
                                    {this.state.modifyError.hasOwnProperty('lastName') &&
                                    <FormHelperText error={true}>{this.state.modifyError.lastName}</FormHelperText>}
                                    <AD13Input
                                        id="members-outlined-email"
                                        label={'Email'}
                                        type={'email'}
                                        value={this.state.form.email}
                                        onChange={this.handleChange('email')}
                                        fullWidth
                                        error={this.state.modifyError.hasOwnProperty('email')}
                                    />
                                    {this.state.modifyError.hasOwnProperty('email') &&
                                    <FormHelperText error={true}>{this.state.modifyError.email}</FormHelperText>}
                                    {this.state.modifyError.hasOwnProperty('error') &&
                                    <FormHelperText error={true}>{this.state.modifyError.error}</FormHelperText>}
                                    <FormHelperText>
                                        Je déclare être parfaitement informé par PURJUS COMMUNICATION que le renseignement des champs de ce formulaire constitue un traitement de données au sens du <a
                                        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees" target="_blank" rel="noopener noreferrer">Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016</a> et avoir notamment satisfait préalablement au respect des articles 6 et 15 à 22 du texte.
                                        <Link style={{display: 'block'}} to={'/consentement'}>En savoir plus</Link>
                                    </FormHelperText>
                                </form>
                                <Grid container justify={'space-between'} className={classes.panelActions}>
                                    <Button variant={'text'} onClick={handleModal}>Annuler</Button>
                                    <Button variant={'contained'} color={'primary'} onClick={this.validateForm}>Ajouter</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {null !== this.state.submissionError &&
                            <FormHelperText error={true}>{this.state.submissionError}</FormHelperText>}
                        </Grid>
                    </Grid>
                </Card>
            </Modal>
        );
    }
}

AD13MembersAddModal.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object,
    handleModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    retrieveTrainings: PropTypes.func.isRequired,
    members: PropTypes.array.isRequired,
};

const mapDispatchToProps = dispatch => ({
    retrieveTrainings: () => dispatch(list())
});

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withStyles(styles)
)(AD13MembersAddModal);
