import React from 'react';
import {withRouter} from 'react-router-dom';
import PageLayout from '../partials/PageLayout';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import Container from '../partials/Container';
import AD13Button from '../partials/AD13Button';
import AD13Input from '../partials/AD13Input';
import {Grid, Typography} from '@material-ui/core/';
import {fetch} from "../../utils/dataAccess";
import FormHelperText from '@material-ui/core/FormHelperText';

class PasswordRecovery extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            form: {
                token: this.props.match.params.token,
                password: '',
                passwordBis: '',
            },
            modifyError: {},
            formValidated: false
        };
    }

    handleChange = name => event => {
        let form = this.state.form;
        form[name] = event.target.value;
        this.setState({
            form: form,
        });
    };

    validateForm = () => {

        fetch('/members/password_modification', {
            method: 'POST',
            body: JSON.stringify(this.state.form)
        }).then(response => {
            this.setState(state => ({
                modifyError: {},
                formValidated: true
            }))

        }).catch(error => {
            if (error.hasOwnProperty('errors')) {
                this.setState(state => {
                    state.modifyError = error.errors;
                    return state;
                })
            } else {
                this.setState(state => {
                    state.modifyError = {error: error.message};
                    return state;
                })
            }
        });
    };


    render() {
        return (
            <PageLayout title={'Nouveau mot de passe'}>
                <Container>
                    <React.Fragment>
                        {false === this.state.formValidated ?
                            <Main title={'Nouveau mot de passe'}>
                                <Grid container style={{maxWidth: 500}}>
                                    <Typography>
                                        Veuillez renseigner votre nouveau mot de passe
                                    </Typography>
                                    <form noValidate autoComplete="off" style={{width: '100%'}}>
                                        <AD13Input
                                            required
                                            id="outlined-mail"
                                            label="Nouveau mot de passe"
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.handleChange('password')}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            error={this.state.modifyError.hasOwnProperty('password')}
                                        />
                                        {this.state.modifyError.hasOwnProperty('password') &&
                                        <FormHelperText error={true}>{this.state.modifyError.password}</FormHelperText>}

                                        <AD13Input
                                            required
                                            id="outlined-mail"
                                            label="Renseignez à nouveau votre nouveau mot de passe"
                                            type="password"
                                            value={this.state.password}
                                            onChange={this.handleChange('passwordBis')}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            error={this.state.modifyError.hasOwnProperty('passwordBis')}
                                        />
                                        {this.state.modifyError.hasOwnProperty('passwordBis') &&
                                        <FormHelperText
                                            error={true}>{this.state.modifyError.passwordBis}</FormHelperText>}

                                        {this.state.modifyError.hasOwnProperty('token') &&
                                        <FormHelperText error={true}>{this.state.modifyError.token}</FormHelperText>}

                                        {this.state.modifyError.hasOwnProperty('error') &&
                                        <FormHelperText error={true}>{this.state.modifyError.error}</FormHelperText>}
                                    </form>
                                    <Grid container justify={'flex-end'} style={{marginRight: 20, marginTop: 16}}>
                                        <AD13Button text="valider" color={'primary'} onClick={this.validateForm}/>
                                    </Grid>
                                </Grid>
                            </Main>
                            :
                            <Main title={"Mot de passe modifié"}>
                                <Grid container style={{maxWidth: 600}}>
                                    <Typography>
                                        Votre mot de passe a été modifié. Vous pouvez dès à présent vous connecter avec votre nouveau mot de passe.
                                    </Typography>
                                </Grid>
                            </Main>
                        }
                        <Aside>
                        </Aside>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

export default withRouter(PasswordRecovery)

