import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import Navbar from '../navigation/Navbar';
import logoBg from '../../assets/AD13_logo_bg.svg';
import {withStyles, Grid} from '@material-ui/core';
import {Helmet} from 'react-helmet';
import Topbar from '../navigation/Topbar';

const styles = theme =>  ({
    page: {
        overflow: 'hidden',
    },
    pageBackground: {
        [theme.breakpoints.up('md')]: {
            background: 'linear-gradient(to right, transparent 80%,' + theme.palette.background.lightgrey +' 80%)',
        },
    },
    wrapper: {
        height: '100%',
    },
    wrapperBackground: {
        backgroundImage: 'url(' + logoBg + ')',
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('xs')]: {
            backgroundSize: 'contain',
        },
    },
});

class PageLayout extends React.Component {
    render() {
        const {classes, noBackground, noLogo, children, title} = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <Topbar/>
                <Grid container className={`${classes.page} ${noBackground ? '' : classes.pageBackground}`}>
                    <Navbar/>
                    <Grid container className={`${classes.wrapper} ${noLogo ? '' : classes.wrapperBackground}`}>
                        {children}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

PageLayout.propTypes = {
    children: PropTypes.object.isRequired,
    noBackground: PropTypes.bool,
    noLogo: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return state
};

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(PageLayout);

