import Config from '../Config';
import {SubmissionError} from 'redux-form';
import has from 'lodash/has';
import {loadState} from '../store/localstorage';
import {logout} from '../actions/member/authenticate'
import store from '../store/store';

const MIME_TYPE = 'application/ld+json';

export function fetch(id, options = {}) {
    if ('undefined' === typeof options.headers) options.headers = new Headers();
    if (null === options.headers.get('Accept'))
        options.headers.set('Accept', MIME_TYPE);

    const localState = loadState();
    if (localState.storeAuth.hasOwnProperty('token') && null !== localState.storeAuth.token) {
        options.headers.set('Authorization', 'Bearer ' + localState.storeAuth.token);
    }

    if (
        'undefined' !== options.body &&
        !(options.body instanceof FormData) &&
        null === options.headers.get('Content-Type')
    )
        options.headers.set('Content-Type', MIME_TYPE);

    return global.fetch(new URL(id, Config.api.entryPoint), options).then(response => {
        if (response.ok) return response;

        return response.json().then(json => {
            const error = json['hydra:description'] || response.statusText;
            if (!json.violations && 401 === json.code && localState.storeAuth.hasOwnProperty('isAuthenticated') && true === localState.storeAuth.isAuthenticated) {
                store.dispatch(logout());
                window.location.href = '/';
                return;
            } else if(!json.violations) {
                throw Error(error);
            }

            let errors = {_error: error};
            json.violations.map(
                violation => (errors[violation.propertyPath] = violation.message)
            );

            throw new SubmissionError(errors);
        });
    });
}

export function mercureSubscribe(url, topics) {
    topics.forEach(topic =>
        url.searchParams.append('topic', new URL(topic, Config.api.entryPoint))
    );

    return new EventSource(url.toString());
}

export function normalize(data) {
    if (has(data, 'hydra:member')) {
        // Normalize items in collections
        data['hydra:member'] = data['hydra:member'].map(item => normalize(item));

        return data;
    }

    // return brut datas
    return data;

}

export function extractHubURL(response) {
    const linkHeader = response.headers.get('Link');
    if (!linkHeader) return null;

    const matches = linkHeader.match(
        /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
    );

    return matches && matches[1] ? new URL(matches[1], Config.api.entryPoint) : null;
}
