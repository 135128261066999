import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import {withStyles} from '@material-ui/core/';

const styles = theme => ({
    svg: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        top: -110,
        zIndex: 600,
    },
    container: {
        fill: theme.palette.secondary.main,
        '&:hover': {
            fill: theme.palette.secondary.light,
        }
    }
})

const AD13CtaUp = props => (
    <Link to={"#top"} smooth={true} className={props.classes.svg}>
        <svg width={80} height={80} {...props}>
            <path className={props.classes.container} d="M-.022 40L39.999-.022 80.022 40 40 80.022z"/>
            <path d="M31.485 45.627l-1.415-1.414 9.9-9.9 1.414 1.415z" fill="#fff" />
            <path d="M38.555 35.788l1.414-1.415 9.9 9.9-1.414 1.414z" fill="#fff" />
        </svg>
    </Link>
)

export default withStyles(styles)(AD13CtaUp)
