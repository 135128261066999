import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import logoColor from '../../assets/AD13_logo.svg';
import logoWhite from '../../assets/AD13_logo_white.svg';
import Topbar from './Topbar';
import MenuItems from './MenuItems';
import DrawerButton from './DrawerButton';
import NavbarDrawer from './NavbarDrawer';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {withStyles, AppBar, Grid} from '@material-ui/core/';
import {palette} from '../../MuiTheme';

const styles = theme => ({
    appbar: {
        [theme.breakpoints.up('md')]: {
            zIndex: 600,
        },
        pointerEvents: 'none',
    },
    navbar: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing.unit * 2,
        },
        margin: 'auto',
        marginTop: theme.spacing.unit * 1.5,
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing.unit * 3.25,
        },
        maxWidth: theme.layout.width,
        paddingTop: theme.spacing.unit * 6,
        position: 'relative',
    },
    navbarLogo: {
        [theme.breakpoints.up('md')]: {
            marginRight: 150,
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 300,
        },
        pointerEvents: 'all',
    },
    navbarMenu: {
        flexGrow: 1,
        pointerEvents: 'all',
    },
    navbarMobileMenu: {
        pointerEvents: 'all',
    },
    navbarLinks: {
        color: theme.palette.textPrimary,
        fontFamily: "'FjallaOne', 'OpenSans', sans-serif",
        fontSize: 16,
        letterSpacing: 0,
        LineHeight: '20px',
        position: 'relative',
        textDecoration: 'none',
        textTransform: 'capitalize',
        transition: 'all .5s',
        '&::before': {
            background: theme.palette.secondary.main,
            content: "''",
            display: 'block',
            height: 3,
            position: 'absolute',
            top: '150%',
            transition: 'all .5s',
            width: 0,
        },
        '&:hover': {
            color: theme.palette.textPrimary,
            textDecoration: 'none',
            '&::before': {
                width: '100%',
            }
        },
        '&::selection': {
            background: palette.primary.main,
            color: palette.primary.contrastText,
        },
    },
    navbarLinksActive: {
        '&::before': {
            background: theme.palette.secondary.main,
            content: "''",
            display: 'block',
            height: 3,
            position: 'absolute',
            top: '150%',
            transition: 'all .5s',
            width: '100%',
        },
    },
});

class Navbar extends React.Component {
    render() {
        const {classes, width, storeAuth} = this.props,
            slug = window.location.pathname,
            isHome = '/' === slug,
            isMobile = !isWidthUp('md', width)

        return (
            <AppBar id={"top"} position={'absolute'} color="default" className={classes.appbar}>
                <Topbar/>
                <Grid container wrap="nowrap" justify="space-between" className={classes.navbar}>
                    <Grid item className={classes.navbarLogo}>
                        <Link to="/">
                            <img
                                src={(!isHome || !isMobile) ? logoColor : logoWhite}
                                alt="logo AD13"
                                height={!isMobile ? 200 : 140}
                            />
                        </Link>
                    </Grid>
                    {isWidthUp('md', width) ?
                        <Grid container alignItems="flex-start" justify="space-between" className={classes.navbarMenu}>
                            {MenuItems.map((item, index) => (
                                (index !== MenuItems.length - 1 && (item.authenticationRequired === storeAuth.isAuthenticated || !item.authenticationRequired))  &&
                                <Link key={index} to={item.href} className={`${slug === item.href && classes.navbarLinksActive} ${classes.navbarLinks}`}>
                                    {item.name}
                                </Link>
                            ))}
                        </Grid>
                        :
                        <Grid container alignItems="flex-start" justify="flex-end" className={classes.navbarMobileMenu}
                              style={isHome ? {color: '#1B1C1E'} : {color: '#1B1C1E'}}>
                            <DrawerButton text="menu"/>
                        </Grid>
                    }
                </Grid>
                <NavbarDrawer/>
            </AppBar>
        )
    }
}
const mapStateToProps = state => {
    return {
        storeAuth: state.storeAuth
    }
};

Navbar.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
}

export default compose(
    connect(
        mapStateToProps
    ),
    withWidth(),
    withStyles(styles)
)(Navbar)



