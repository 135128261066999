import React from 'react';
import PropTypes from 'prop-types';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import SectionTitle from '../partials/SectionTitle';
import {Grid, Typography, withStyles} from '@material-ui/core';

const styles = theme => ({
    aboutContainer: {
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
            maxWidth: 460,
            paddingTop: theme.spacing.unit * 8,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing.unit * 48,
        },
    },
    aboutContainerBtn: {
        marginTop: theme.spacing.unit * 8,
    },
    aboutContainerText: {
        marginBottom: theme.spacing.unit * 5,
        maxWidth: theme.spacing.unit * 50,
    },
    aboutContainerMedia: {
        maxWidth: '100%',
    },
});

class HomeAbout extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <Grid container
                className={classes.aboutContainer}
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >
                <SectionTitle
                    title={'Qui sommes nous'}
                    color={'primary'}
                    paddingBottom={32}
                />
                <Typography variant="body2" color="textPrimary" className={classes.aboutContainerText}>
                    L’Association Départementale des Comités Communaux Feux de Forêts et des Réserves
                    Communales de Sécurité Civile des Bouches du Rhône est l’unique organe qui regroupe les
                    communes ayant mis en place de tels dispositifs de prévention et d’assistance à la
                    sauvegarde, non seulement des espaces sensibles, mais également des populations.
                </Typography>
                <img src={darkRabbit} alt="illustration lapin" className={classes.aboutContainerMedia}/>
            </Grid>
        )
    }
}

HomeAbout.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HomeAbout)
