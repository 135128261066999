import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SectionTitle from '../partials/SectionTitle';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {Grid} from '@material-ui/core';

class FormationListTitle extends Component {

    render() {
        const {width, month, year, index} = this.props,
            isMobile = !isWidthUp('md', width);

        return (
            <Grid item xs={12} id={'formations_' + month + year} style={{marginTop: 0 !== index ? 48 : 0, paddingTop: 0 !== index ? 16 : 0}}>
                <SectionTitle
                    title={month + ' ' + year }
                    color={'primary'}
                    variant={isMobile ? 'h5' : 'h3'}
                />
            </Grid>
        )
    }
};

FormationListTitle.propTypes = {
    item: PropTypes.object.isRequired, width: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired
};

export default withWidth()(FormationListTitle);
