export function sortItemsByDate(items) {
    items['hydra:member'].sort(function(a,b) {
            if (new Date(a.events[0].dateStart) > new Date(b.events[0].dateStart)) {
                return 1;
            } else if (new Date(a.events[0].dateStart) < new Date(b.events[0].dateStart)) {
                return -1;
            }
            return 0;
        }
    );
}

export function getNextFormations(items) {
    return items['hydra:member'].filter(item => {
        return new Date() < new Date(item.events[0].dateStart)
    })

}

export function rankFormationByMonth(items) {
    const formationByYear = []

    for (const formation of items) {
        const currentDate = new Date(formation.events[0].dateStart)
        const currentMonth = currentDate.toLocaleString('fr-Fr', {month: 'numeric'})
        const currentMonthDisplay = currentDate.toLocaleString('fr-Fr', {month: 'long'})
        const currentYear = currentDate.toLocaleString('fr-Fr', {year: 'numeric'})

        // création clef de l'année si pas existante
        let formationForThisYear = null
        if (!(currentYear in formationByYear)) {
            formationForThisYear = []
            formationByYear[currentYear] = formationForThisYear
        } else {
            formationForThisYear = formationByYear[currentYear]
        }

        // création clef du mois dans l'année si pas existant
        let currentValue = null
        if (!(currentMonth in formationForThisYear)) {
            currentValue = {display: currentMonthDisplay, count: 0, items: []}
            formationForThisYear[currentMonth] = currentValue
        } else {
            currentValue = formationForThisYear[currentMonth]
        }

        currentValue['count'] += 1
        currentValue['items'].push(formation)
    }

    return formationByYear
}
