import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {Link, withRouter} from 'react-router-dom'
import {Grid, List, ListItem, ListItemText, Typography, withStyles} from '@material-ui/core'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import FireGuideManualDownloadable from '../../assets/ImagesGuidesFeux/AD13_Guide_Feux.pdf'
import FireGuideManual from '../../assets/ImagesGuidesFeux/AD13_Guide_Feux.jpg'
import FireMemo from '../../assets/ImagesGuidesFeux/AD13_emploi_du_feu.pdf'
import FireMemo2 from '../../assets/ImagesGuidesFeux/AD13_memo_emploi_du_feu_2.pdf'
import VisualHarmonisation from '../../assets/ImagesGuidesFeux/AD13_visuelharmonisation.pdf'
import MassifAccess from '../../assets/ImagesGuidesFeux/AD13_acces_massifs_2016.pdf'
import AD13Button from '../partials/AD13Button';
import HeaderBackground from '../../assets/AD13_HomeBg.jpg';
import AD13SingleHeader from '../partials/AD13SingleHeader';
import AD13SingleContent from '../partials/AD13SingleContent';
import {Helmet} from 'react-helmet';

const styles = theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing.unit * 6
        },
        maxWidth: '100%'
    },
    headerContent: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters()
        },
        margin: 'auto',
        marginBottom: 0,
        marginTop: 0,
        maxWidth: theme.spacing.unit * 70
    },
    headerTitle: {
        '&:first-letter': {
            TextTransform: 'uppercase'
        }
    },
    headerCategory: {
        textTransform: 'uppercase'
    },
    content: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters()
        },
        margin: 'auto',
        marginBottom: 48,
        maxWidth: theme.spacing.unit * 70,
        minHeight: 300
    },
    paragraph: {
        paddingBottom: 48
    },
    downloableLink: {
        color: theme.palette.primary.main,
        textDecoration: 'underline'
    },
    interTitle: {
        marginBottom: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 3
    }
});

const AD13ListItem = props => {
    return (
        <ListItem>
            <ListItemText>
                <Typography>
                    - {props.text}
                </Typography>
            </ListItemText>
        </ListItem>
    )
}

const AD13ListSubheader = props => {
    return (
        <Grid item xs={12} style={{marginBottom: 16, marginTop: 32}}>
            <Typography variant={props.isMobile ? 'h5' : "h4"} color={props.color ? props.color : 'textPrimary'}>
                {props.text}
            </Typography>
        </Grid>
    )
}

const AD13InterTitle = props => {
    return (
        <Grid item xs={12}>
            <Typography variant={props.isMobile ? 'h4' : "h3"} color="primary"
                        style={{marginBottom: 24, marginTop: 32}}>
                {props.text}
            </Typography>
        </Grid>
    )
}

class FireGuide extends React.Component {

    redirect = () => {
        this.props.history.push('/');
    };

    render() {
        const {classes} = this.props,
            isMobile = !isWidthUp('md', this.props.width);

        return (
            <React.Fragment>
                <Helmet>
                    <title>{'Feu de forêt: Les bons réflexes'}</title>
                </Helmet>
                <Grid container className={classes.container} id={'top'}>
                    <AD13SingleHeader itemImage={HeaderBackground}>
                        <Grid container className={classes.headerContent} alignItems={'center'}>
                            <Grid item xs={12}>
                                <Typography variant={isMobile ? 'h3' : "h2"} color="inherit" className={classes.headerTitle}>
                                    Comment réagir face à un incendie qui menace votre habitation ?
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="button" color="inherit" className={classes.headerCategory}>
                                    Le feu fait partie du contexte de la forêt méditerranéenne.
                                </Typography>
                                <Typography variant="button" color="inherit" className={classes.headerCategory}>
                                    Avoir la chance d’habiter à proximité ou en son sein comporte nécessairement des risques.
                                </Typography>
                            </Grid>
                        </Grid>
                    </AD13SingleHeader>
                    <AD13SingleContent socialData={{subject:'Feu de forêt: Les bons réflexes'}}>
                        <Grid container className={classes.content}>
                            <AD13ListSubheader isMobile={isMobile} text={'Comment s\'y préparer'}/>
                            <List dense>
                                <AD13ListItem text={'Au préalable, débroussailler, espacer les arbres, élaguer et ratisser les aiguilles autour de chez soi jusqu’à 50 mètres, même au-delà de votre propriété, est la meilleure protection pour vous-même et pour vos biens (consulter la rubrique RÉGLEMENTATION - OLD sur le site)'}/>
                                <AD13ListItem
                                    text={'Le respect de ces précautions va ralentir la propagation du feu, diminuer sa puissance et éviter que les flammes n’atteignent les parties inflammables de votre habitation.'}/>
                                <AD13ListItem text={'Il est impératif que toute branche d’arbre soit éloignée d’au moins 3 mètres de votre habitation.'}/>
                                <AD13ListItem text={'Il faut éviter tout arbre ou arbuste, y compris ornemental, à proximité de la maison car il peut devenir une véritable torchère quand il prend feu. (en particulier les pins, le cyprès, le mimosa…)'}/>
                                <AD13ListItem text={'Le toit de votre habitation doit être débarrassé des feuilles et aiguilles de pin.'}/>
                                <AD13ListItem text={'Votre chemin d’accès doit également être entretenu et d’une largeur d’au moins 4 mètres pour permettre la circulation des engins de secours.'}/>
                                <AD13ListItem text={'ATTENTION : Les arbres le long du chemin ne doivent pas le couvrir comme un tunnel. En cas d’incendie, cette végétation embrasée constituera un véritable piège pour vous et interdira l’accès à votre maison pour les secours.'}/>
                                <AD13ListItem text={'Avec leurs petites ouverture sur les faces nord et nord-ouest, les maisons méditerranéennes sont conçues pour se protéger du vent dominant (Mistral ou Tramontane). '}/>
                                <AD13ListItem text={'Elles servent souvent à entreposer le bois, les bouteilles de gaz, l’huile, le carburant pour la débroussailleuse et le tri sélectif (cartons, emballages, …). '}/>
                                <AD13ListItem text={'Les incendies violents portés par le vent vont toucher en premier ces faces, qui si elles sont encombrées, vont accentuer le feu pour toucher les parties sensibles de la maison (poutres apparentes, charpentes, volets…). '}/>
                                <AD13ListItem text={'Les volets, les baies vitrés et les gouttières en PVC sont à proscrire. Ces matériaux n’ont pas besoin d’être léchés par les flammes pour prendre feu. '}/>
                                <AD13ListItem text={'La température à 50 mètres du front peut monter à 200°C, ce qui est largement suffisant pour l’auto-combustion du plastique. Il est donc préférable que tous ces éléments soient constitués en bois plein épais, qui lui résiste bien au passage du front de flammes. Si vous possédez une piscine, envisagez l’achat d’une motopompe thermique (l\'électricité risque d\'être coupée) avec un tuyau de longueur suffisante pour protéger toute votre propriété et notamment votre maison. '}/>
                            </List>
                            <Typography variant={'subtitle1'}>
                                VOILA ! En cas d’incendie de forêt, vous êtes prêt.
                            </Typography>
                            <AD13ListSubheader isMobile={isMobile} text={'Le feu se dirige vers votre habitation … '}/>
                            <div>
                                <Typography variant={'h6'} color={'primary'}>
                                    (Téléphone secours : 18 ou 112)
                                </Typography>
                                <Typography variant={'subtitle1'} style={{marginTop: 16}}>
                                    Pas de panique !
                                </Typography>
                                <Typography style={{marginBottom: 8, marginTop: 8}}>
                                    Si votre maison est en dur et que vous avez pris toutes les précautions préalables, elle constitue votre meilleure protection.
                                </Typography>
                                <Typography color={'primary'}>
                                    <Typography variant={'subtitle1'} component={'span'}
                                        style={{display: 'inline'}} color={'inherit'}
                                    >
                                        ATTENTION :&nbsp;
                                    </Typography>
                                    N’évacuez que sur ordres des autorités car vous risquez de bloquer les secours en créant un embouteillage et vous mettre en grave danger.
                                </Typography>
                            </div>
                            <ol style={{fontWeight: 100, fontSize: '0.875rem'}}>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Rassemblez votre famille et les animaux domestiques dans la maison.
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Fermez tous les volets.
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Calfeutrez toutes les ouvertures avec du linge humide.
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Fermez la trappe de la cheminée et arrêtez la VMC.
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Mettez vos bouteilles de gaz dans votre piscine ou dans une baignoire remplie d’eau.
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Rentrez le mobilier de jardin en plastique car s’il s’enflamme, il devient un grave danger pour la maison.
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Ouvrir le portail de votre propriété pour permettre l’accès des sapeurs-pompiers.</li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Abritez votre voiture, vitres fermées à l’opposé du vent contre la maison.
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Habillez-vous avec des vêtements en coton épais couvrant tout votre corps – pas de tissus synthétique – une casquette, des lunettes, un foulard, des chaussures montantes et des gants si possible en cuir.
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    S’il vous reste du temps aspergez d’eau le toit, les murs et les volets qui font face au front de feu (inutile d’arroser la végétation alentour car inefficace face à la puissance d’un incendie de forêt).
                                </li>
                                <li style={{paddingBottom: 4, paddingTop: 4}}>
                                    Rentrez le tuyau et la motopompe dans la maison ou à l’abri du feu.
                                </li>
                            </ol>
                            <AD13ListSubheader isMobile={isMobile} text={'Le feu passe très vite !'}/>
                            <div>
                                <Typography variant={'subtitle1'}>
                                    Quelques minutes tout au plus !
                                </Typography>
                                <Typography variant={'subtitle1'} color={'primary'}>
                                    Si les autorités vous l’ont demandé :&nbsp;
                                    <Typography variant={'subtitle1'} component={'span'} style={{display: 'inline'}}>
                                        « Restez bien à l’abri dans votre maison »
                                    </Typography>-
                                </Typography>
                            </div>
                            <AD13ListSubheader isMobile={isMobile} text={'Après son passage !'}/>
                            <List dense>
                                <AD13ListItem
                                    text={'Tout ce qui est enflammé doit être noyé en utilisant votre tuyau d’arrosage et/ou votre motopompe (si vous en possédez une …).'}/>
                                <AD13ListItem
                                    text={'Accordez une attention particulière à votre habitation, à sa toiture et ses combles en recherchant et en surveillant les braises qui auraient pu s’introduire sous les tuiles ou par les orifices d’aération.'}/>
                                <AD13ListItem text={'Inquiétez vous des voisins et, le cas échéant, apportez leurs de l’aide.'}/>
                            </List>
                        </Grid>
                    </AD13SingleContent>
                </Grid>
            </React.Fragment>
        )
    }
};

FireGuide.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired
};

export default compose(
    withRouter,
    withWidth(),
    withStyles(styles)
)(FireGuide);
