const initialState = { isDrawerOpen: false }

function storeDrawerState(state = initialState, action) {
    let nextState
    switch (action.type) {
        case 'TOGGLE_DRAWER':
            nextState = {
                ...state,
                isDrawerOpen: action.value
            }
            return nextState || state
        default:
            return state
    }
}

export default storeDrawerState
