import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import {withStyles, Card, CardHeader, CardMedia, Grid, Typography} from '@material-ui/core';

const styles = (theme) => ({
    card: {
        background: 'transparent',
        borderRadius: 0,
        transition: 'all .5s',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    mediaContainer: {
        background: 'url(' + darkRabbit + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: theme.spacing.unit * 12.5,
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing.unit * 25,
        },
        overflow: 'hidden',
        position: 'relative',
        transition: theme.transition.smooth,
    },
    media: {
        backgroundSize: 'cover',
        height: theme.spacing.unit * 12.5,
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing.unit * 25,
        },
        transition: theme.transition.smooth,
        width: '100%',
    },
    header: {
        paddingBottom: theme.spacing.unit,
        paddingLeft: 0,
        paddingTop: theme.spacing.unit,
    },
    content: {
        paddingLeft: 0,
        paddingTop: 0,
    },
    category: {
        fontWeight: 800,
        textTransform: 'uppercase',
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        }
    }
})

class ListItemMobile extends Component {

    redirect = () => {
        const uri = encodeURIComponent(this.props.item['uuid']);
        const isSinglePage = this.props.history.location.pathname.includes('/show')
        const pathname = isSinglePage ? `${uri}` : `/article/${uri}`;
        this.props.history.push(pathname);
    };

    render() {
        const {classes, item} = this.props;
        return (
            <Card elevation={0} className={classes.card} onClick={this.redirect}>
                <Grid container className={classes.mediaContainer}>
                    <CardMedia
                        className={classes.media}
                        image={null !== item['image'] ? item['image']['url'] : ' '}
                        title={item['title']}
                    />
                </Grid>
                <CardHeader disableTypography className={classes.header}
                    title={
                        <Typography variant={'subtitle1'}>
                            {item['title']}
                        </Typography>
                    }
                />
            </Card>
        );
    }
}

ListItemMobile.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

export default compose(
    withRouter,
    withStyles(styles)
)(ListItemMobile);
