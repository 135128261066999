import React from 'react';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import Main from '../partials/Main';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';

class Consent extends React.Component {
    render() {
        return (
            <PageLayout title={'Données personnelles'}>
                <Container>
                    <React.Fragment>
                        <Main title={'Données personnelles'}>
                            <React.Fragment>
                                <Typography variant={'body2'} style={{marginBottom: 8}}>
                                    La société Purjus communication est soucieuse de la protection des données personnelles.
                                    Nous nous engageons à assurer le meilleur niveau de protection à vos données personnelles
                                    en conformité avec la loi applicable et le <a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees" target={'_blank'} rel={'noreferrer noopener'}>règlement général sur la protection des données personnelles</a>.
                                </Typography>
                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16, marginTop: 24}}>
                                    RESPONSABLE DU TRAITEMENT
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Les données personnelles collectées sur ce site internet sont enregistrées dans une base de données informatisée par le responsable du traitement :
                                </Typography>
                                <Typography>
                                    Purjus communication
                                </Typography>
                                <Typography>
                                    Le beau réveil
                                </Typography>
                                <Typography>
                                    164 Rte Dept 10
                                </Typography>
                                <Typography>
                                    Le Taron
                                </Typography>
                                <Typography>
                                    13126 Vauvenargues
                                </Typography>
                                <Typography>
                                    <a href="mailto:contact@purjus.fr" target={'_blank'} rel={'noreferrer noopener'}>
                                        contact@purjus.fr
                                    </a>
                                </Typography>

                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16, marginTop: 32}}>
                                    FINALITÉS DU TRAITEMENT
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Les données personnelles collectées sont utilisées pour la création de compte client, le support client, la connexion aux espaces privés, l'envoi d'emails de suivi d'abonnement, l'envoi d'emails de réponses aux formulaires de contact, l'envoi par emails de lettres d'informations.
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Certaines données sont collectées automatiquement du fait de vos actions sur le site afin d'effectuer des mesures d'audience (le nombre de pages vues, le nombre de visites du site, les produits consultés, etc.) ou sont nécessaires à la prévention et la résolution d’incidents techniques.
                                </Typography>

                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16, marginTop: 32}}>
                                    DONNÉES COLLECTÉES
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Les données personnelles collectées et traitées sont notamment vos nom, prénom, civilité, email, mot de passe, numéro de téléphone, adresse IP, adresse postale, données de connexions.
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Nous nous engageons à ne collecter que le minimum de données nécessaires au bon fonctionnement du service fourni par ce site internet. Le caractère obligatoire ou facultatif des données collectées vous est signalé au moment de leur saisie par une mention "Obligatoire" (ou tout autre indicateur visuel approrié comme un astérisque ou un élément en gras) ou "Facultatif".
                                </Typography>

                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16, marginTop: 32}}>
                                    DURÉE DE CONSERVATION
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Les données sont conservées pendant toute la durée d'utilisation du service puis sont archivées pour une durée supplémentaire en lien avec les durées de prescription et de conservation légale pour des raisons strictement limitées et autorisées par la loi (paiement, garantie, litiges, etc.). Passé ce délai, elles sont supprimées.
                                </Typography>

                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16, marginTop: 32}}>
                                    DESTINATAIRES
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Les données collectées sont destinées à notre service commercial, à notre service marketing et à notre service technique. Elles sont également transmises aux tiers suivants :
                                    <br/>
                                    - Google Analytics (analytics.google.com) en qualité de plateforme de mesure d'audience
                                </Typography>

                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16, marginTop: 32}}>
                                    RETRAIT DU CONSENTEMENT
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Vous pouvez retirer à tout moment votre consentement sur l'utilisation de vos données personnelles non-indispensables à la bonne utilisation du service fourni par ce site internet en vous connectant à <Link to={'/preferences/'}>votre compte</Link>.
                                </Typography>
                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16, marginTop: 32}}>
                                    DROIT D'ACCÈS
                                </Typography>
                                <Typography style={{marginBottom: 8}}>
                                    Vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier. Ce droit peut être exercé par voie postale auprès de Purjus communication - Le beau réveil – 164 Rte Dept 10 – Le Taron – 13126 Vauvenargues, par voie électronique à l’adresse email suivante : <a href="mailto:contact@purjus.fr" target={'_blank'} rel={'noreferrer noopener'}>contact@purjus.fr</a> ou en vous connectant à <Link to={'/preferences/'}>votre compte</Link>.
                                </Typography>
                            </React.Fragment>
                        </Main>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

export default Consent;

