import React from 'react';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';
import PageLayout from '../partials/PageLayout';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import Container from '../partials/Container';
import SignUpStepper from '../signUp/SignUpStepper'
import {Hidden} from '@material-ui/core'

class SignUp extends React.Component {

    render() {
        if (this.props.isAuthenticated) {
            return (
                <Redirect to={'/preferences/'}/>
            )
        }
        return (
            <PageLayout  title={'Inscription'}>
                <Container>
                    <React.Fragment>
                        <Main>
                            <SignUpStepper state={this.state}/>
                        </Main>
                        <Hidden mdDown>
                            <Aside/>
                        </Hidden>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.storeAuth.isAuthenticated,
    }
};

export default compose(
    connect(
        mapStateToProps,
        null
    ),
    withRouter
)(SignUp);


