import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import SectionTitle from '../partials/SectionTitle';
import MailIcon from '@material-ui/icons/Mail'
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import FormHelperText from '@material-ui/core/FormHelperText';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import AD13Input from '../partials/AD13Input';
import {withStyles, Button, Card, CardContent, CardMedia, Grid, InputAdornment, Modal, Typography} from '@material-ui/core/'
import {authenticate, compute, reset} from '../../actions/member/authenticate';

const styles = theme => ({
    card: {
        background: 'transparent',
        borderRadius: 0,
        color: theme.palette.primary.contrastText,
        margin: 'auto',
        [theme.breakpoints.up('md')]: {
            marginTop: '10vh',
        },
        paddingTop: 125,
        maxWidth: 352
    },
    cardContent: {
        background: theme.palette.background.black,
        paddingLeft: theme.spacing.unit * 5,
        paddingRight: theme.spacing.unit * 5
    },
    mediaContainer: {
        background: theme.palette.background.black,
        height: 125,
        position: 'relative'
    },
    media: {
        backgroundSize: '160%',
        borderRadius: '50%',
        bottom: 0,
        height: 250,
        position: 'absolute',
        width: 250
    },
    input: {
        color: theme.palette.tertiary.light,
        fontFamily: "'OpenSans', sans-serif"
    },
    inputFocused: {
        color: theme.palette.primary.contrastText,
        '& > div': {
            color: theme.palette.primary.main,
        },
        '& > fieldset': {
            borderColor: theme.palette.primary.main + '!important',
        }
    },
    notchedOutline: {
        borderRadius: 0,
        borderColor: theme.palette.textSecondary + '!important'
    },
    label: {
        color: theme.palette.tertiary.light,
        fontFamily: "'OpenSans', sans-serif"
    },
    labelFocused: {
        color: theme.palette.primary.main + ' !important'
    },
    icon: {
        color: 'inherit'
    },
    helperLink: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    helperText: {
        fontFamily: "'OpenSans', sans-serif",
        textAlign: 'right'
    }
});

class Login extends React.Component {

    state = {
        mail: '',
        password: '',
    };

    handleLoginModal = () => {
        this.props.computeAuthentication(!this.props.isComputingAuthentication);
    };

    authenticate = () => {
        this.props.authenticate(this.state.mail, this.state.password);
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };

    validateOnEnter = (e) => {
        if(e.keyCode === 13) {
            this.authenticate()
        }
    };

    render() {
        const {classes, isComputingAuthentication} = this.props;
        return (
            <Modal disableAutoFocus
               open={isComputingAuthentication}
               onClose={this.handleLoginModal}
               onKeyDown={e => this.validateOnEnter(e)}
               BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.35)'}}}
            >
                <Card className={classes.card} elevation={0}>
                    <Grid container justify={'center'} className={classes.mediaContainer}>
                        <CardMedia
                            className={classes.media}
                            image={darkRabbit}
                        />
                    </Grid>
                    <CardContent className={classes.cardContent}>
                        <SectionTitle title={'Se connecter'} paddingBottom={16}/>
                        <form className={classes.form} noValidate autoComplete="off" >
                            <AD13Input
                                id="login-outlined-mail"
                                label="Email "
                                type="email"
                                value={this.state.mail}
                                onChange={this.handleChange('mail')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <MailIcon className={classes.icon}/>
                                        </InputAdornment>,
                                }}
                            />
                            <AD13Input
                                id="login-outlined-password"
                                label="Mot de passe"
                                type="password"
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <EyeIcon className={classes.icon}/>
                                        </InputAdornment>,
                                }}
                            />
                            {this.props.error &&
                            <FormHelperText error={true}>
                                Identifiants invalides
                            </FormHelperText>
                            }
                            <FormHelperText>
                                <Link to={'/recuperation_mot_de_passe/'} className={classes.helperLink} onClick={this.handleLoginModal}>
                                    Mot de passe oublié ?
                                </Link>
                            </FormHelperText>
                        </form>
                        <Grid container justify={'flex-end'} style={{marginBottom: 16, marginTop: 16}}>
                            <Button variant={'contained'} color={'primary'} fullWidth onClick={this.authenticate}>
                                Connexion
                            </Button>
                        </Grid>
                        <Typography variant={'caption'} color={'inherit'} align={'center'}>
                            Vous êtes responsable d'un CCFF ou d'un RCSC,&nbsp;
                            <Link onClick={this.handleLoginModal} to={'/inscription/'} className={classes.helperLink}>
                                inscrivez-vous ici
                            </Link>
                        </Typography>
                    </CardContent>
                </Card>
            </Modal>
        )
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const {authenticated, error, loading} = state.member.authenticate;
    const isAuthenticated = state.storeAuth.isAuthenticated;
    const isComputingAuthentication = state.member.authenticate.compute;

    return {authenticated, error, loading, isAuthenticated, isComputingAuthentication};
};

const mapDispatchToProps = dispatch => ({
    authenticate: (username, password) => dispatch(authenticate(username, password)),
    computeAuthentication: (value) => dispatch(compute(value)),
    reset: () => dispatch(reset())
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter,
    withStyles(styles)
)(Login);


