import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Typography
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

class MembersList extends Component {
    static propTypes = {
        formationUuid: PropTypes.string.isRequired,
        formationMembers: PropTypes.array.isRequired,
        formationWaitingMembers: PropTypes.array.isRequired,
        addMemberToFormation: PropTypes.func.isRequired,
        removeMemberToFormation: PropTypes.func.isRequired,
        error: PropTypes.string,
        eventSource: PropTypes.instanceOf(EventSource),
        deletedItem: PropTypes.object,
    };

    doesMembersBelongToFormation(associationMember) {
        return this.props.formationMembers.some(formationMember => formationMember.id === associationMember.uuid) || this.props.formationWaitingMembers.some(formationWaitingMember => formationWaitingMember.id === associationMember.uuid);
    };

    render() {
        const {loading, deletedItem, error, associationMembers} = this.props;

        return (
            <React.Fragment>
                {associationMembers ?
                    <List style={{maxHeight: 350, overflowY: 'scroll', width: '100%'}}>
                        {associationMembers.map(associationMember => (
                            <ListItem key={associationMember['@id']}>
                                <ListItemIcon style={{color: '#F1F1F1'}}>
                                    <AccountCircle color={'inherit'} style={{height: 42, width: 42}}/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={associationMember['firstName'] + ' ' + associationMember['lastName']}
                                    secondary={associationMember['email']}
                                />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        checked={this.doesMembersBelongToFormation(associationMember)}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                this.props.addMemberToFormation(associationMember['uuid']);
                                            } else {
                                                this.props.removeMemberToFormation(associationMember['uuid'])
                                            }
                                        }}
                                        color="primary"
                                        id={associationMember['uuid']}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                    :
                    <Grid container justify="center">
                        {loading && <Typography>Loading...</Typography>}
                        {deletedItem && <Typography>{deletedItem['@id']} deleted.</Typography>}
                        {error && <Typography>{error}</Typography>}
                    </Grid>
                }
            </React.Fragment>
        );
    }
}

export default MembersList;
