import React from 'react';
import PropTypes from 'prop-types';
import AD13_ADCCSFLogo from '../../assets/AD13_ADCCSFLogo.png';
import AD13_RCSCLogo from '../../assets/AD13_RCSCLogo.png';
import AD13Button from '../partials/AD13Button';
import SectionTitle from '../partials/SectionTitle';
import {withStyles, Grid, Typography} from '@material-ui/core/';

const styles = theme => ({
    asideContent: {
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
            maxWidth: 460,
        },
        background: theme.palette.background.lightgrey,
        paddingBottom: theme.spacing.unit * 5,
        paddingTop: theme.spacing.unit * 5,
        zIndex: 2000,
    },
    asideContentBlock: {
        marginBottom: theme.spacing.unit * 4,
        maxWidth: '80%',
    }
});

class HomeAside extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <Grid container className={classes.asideContent}>
                <Grid item xs={12} id="content">
                    <SectionTitle
                        title="L’association"
                        color={"secondary"}
                        paddingBottom={32}
                    />
                </Grid>
                <Grid item xs={12} className={classes.asideContentBlock}>
                    <Typography>
                        L’ADCCFF13, depuis plus de 35 ans, s'attache à former, coordonner et soutenir, aux côtés des
                        Maires, l'action des 2600 bénévoles des Bouches du Rhône.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.asideContentBlock}>
                    <img src={AD13_ADCCSFLogo} alt="ADCCSF" height="100%" width="auto" style={{marginRight: 40}}/>
                    <img src={AD13_RCSCLogo} alt="RCSC" height="100%" width="auto"/>
                </Grid>
                <Grid item xs={12} className={classes.asideContentBlock}>
                    <AD13Button text="Découvrir" href={"/categorie/association/"}/>
                </Grid>
            </Grid>
        )
    }
}

HomeAside.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HomeAside)
