import React from 'react';
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styles = theme => ({
    demoWrapper: {
        marginTop: theme.spacing.unit * 2,
    },
    demoEditor: {
        border: '1px solid ' + theme.palette.background.lightgrey,
        minHeight: 200,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    }
})

class AD13Wysiwyg extends React.Component {
    constructor(props){
        super(props);

        const contentBlock = htmlToDraft(props.defaultContent);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState,
            };
        }
    }

    onEditorStateChange: Function = (editorState) => {
        this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        this.setState({
            editorState,
        });
    };

    render() {
        const {classes} = this.props;
        const {editorState} = this.state;
        return (
            <Editor
                editorState={editorState}
                wrapperClassName={classes.demoWrapper}
                editorClassName={classes.demoEditor}
                onEditorStateChange={this.onEditorStateChange}
            />
        )
    }
}

AD13Wysiwyg.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AD13Wysiwyg)

