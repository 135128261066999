import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Drawer, Typography, withStyles} from '@material-ui/core';
import SectionTitle from '../partials/SectionTitle';

const styles = theme => ({
    drawer: {
        position: 'relative',
        top: 0,
        left: 0,
        pointerEvents: 'none',
        zIndex: 15000,
    },
    drawerPaper: {
        background: 'transparent',
        border: 'none',
        position: 'absolute',
        width: 400
    },
    drawerContent: {
        padding: 0,
        paddingBottom: theme.spacing.unit * 11,
        paddingRight: 40,
        paddingTop: 380,
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters(),
            zIndex: 2000,
        },
    },
    drawerText: {
        maxWidth: 360,
        position: 'relative',
        zIndex: 1000,
    },
});

class CarteDrawerLeft extends React.Component {
    render() {
        const {classes, isDrawerOpen} = this.props;
        return (
            <Drawer open={!isDrawerOpen} anchor={'left'} variant={'persistent'}
                className={classes.drawer} classes={{paper: classes.drawerPaper}}
            >
                <div tabIndex={0} role="button">
                    <Grid item xs={12}  className={classes.drawerContent}>
                        <SectionTitle title={"Adhérents AD13"} color={'primary'} paddingBottom={48} variant={'h3'}/>
                        <Typography className={classes.drawerText}>
                            Comités Communaux Feux de Forêt et Réserves Communales de Sécurité Civile adhérents
                        </Typography>
                        <Typography variant={'caption'}>
                            Adhérents 2019
                        </Typography>
                    </Grid>
                </div>
            </Drawer>
        )
    }
}

CarteDrawerLeft.propTypes = {
    classes: PropTypes.object.isRequired,
    isDrawerOpen: PropTypes.bool.isRequired,
}

export default withStyles(styles)(CarteDrawerLeft)
