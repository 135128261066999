import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import {Hidden, Grid, Typography} from '@material-ui/core';
import {list, reset} from "../../actions/document/list";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import compose from 'recompose/compose';

class Documents extends React.Component {

    static propTypes = {
        retrieved: PropTypes.object,
        eventSource: PropTypes.instanceOf(EventSource),
        list: PropTypes.func.isRequigred,
        reset: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string
    };

    fetchDocuments = () => {
        this.props.list(
            this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page)
        );
    }

    componentDidMount() {
        this.fetchDocuments()
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.page !== nextProps.match.params.page)
            nextProps.list(
                nextProps.match.params.page &&
                decodeURIComponent(nextProps.match.params.page)
            );
    };

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    };

    render() {
        const {retrieved} = this.props;

        const documents = retrieved ? retrieved['hydra:member'] : [];
        return (
            <PageLayout title={'Documents'}>
                <Container>
                    <React.Fragment>
                        <Main title={'Documents'}>
                            <div style={{paddingTop: 16}}>
                                {documents.map((document) => {
                                    return (
                                        <Grid container key={document['@id']} style={{paddingBottom: 16}}>
                                            <Grid item xs={12} md={5}>
                                                <Typography color={'inherit'} variant={'h6'} align={'left'}>
                                                    {document.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={7}>
                                                <a href={document.file.url} target="_blank" download>
                                                    <Typography color={'inherit'} align={'left'}>
                                                        {document.file.originalName}
                                                    </Typography>
                                                </a>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                }
                            </div>
                        </Main>
                        <Hidden mdDown>
                            <Aside/>
                        </Hidden>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

Documents.propTypes = {
    match: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const {
        retrieved,
        loading,
        error,
        eventSource
    } = state.document.list;
    return {retrieved, loading, error, eventSource};
};

const mapDispatchToProps = dispatch => ({
    list: page => dispatch(list(page)),
    reset: eventSource => dispatch(reset(eventSource))
})
;

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter
)(Documents);


