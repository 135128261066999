import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {withStyles, Drawer, Grid, List, ListItem, Typography} from '@material-ui/core/';
import DrawerButton from './DrawerButton';
import MenuItems from './MenuItems';
import {compute} from '../../actions/member/authenticate';

const styles = theme => ({
    drawer: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.contrastText,
        height: '100%',
        width: '100%',
        zIndex: 10000
    },
    paper: {
        background: theme.palette.background.redOverlay,
        boxShadow: 'none',
        height: '100vh',
        width: '100%'
    },
    drawerContent: {
        flexGrow: 1,
        overflowX: 'hidden',
        paddingTop: theme.spacing.unit * 4
    },
    drawerHeaderContainer: {
        ...theme.mixins.gutters()
    },
    drawerHeader: {
        position: 'relative'
    },
    drawerLink: {
        alignItems: 'center',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.secondary.main,
            textDecoration: 'none'
        }
    },
    drawerIcon: {
        fontSize: theme.spacing.unit * 4,
        marginRight: theme.spacing.unit
    },
    drawerButtonContainer: {
        position: 'absolute',
        right: 0,
        top: 10
    },
    drawerMenu: {
        marginTop: theme.spacing.unit * 4
    },
    drawerLinks: {
        color: theme.palette.primary.contrastText,
        paddingLeft: theme.spacing.unit * 8,
        position: 'relative',
        textAlign: 'center',
        textDecoration: 'none',
        '&::first-letter': {
            textTransform: 'capitalize'
        },
        '&:hover': {
            color: theme.palette.secondary.main,
            textDecoration: 'none'
        },
        '&::before': {
            backgroundColor: theme.palette.primary.contrastText,
            content: "''",
            display: 'block',
            height: 3,
            left: 0,
            position: 'absolute',
            top: 'calc(50% - 1.5px)',
            transition: theme.transition.smooth,
            width: 0
        },
        '&:hover::before': {
            backgroundColor: theme.palette.secondary.main,
            width: theme.spacing.unit * 4
        }
    },
    drawerLinksActive: {
        '&::before': {
            backgroundColor: theme.palette.primary.contrastText,
            content: "''",
            display: 'block',
            height: 3,
            left: 0,
            position: 'absolute',
            top: 'calc(50% - 1.5px)',
            transition: theme.transition.smooth,
            width: theme.spacing.unit * 4
        }
    },
    loginModal: {
        minHeight: '100vh'
    }
});


class NavbarDrawer extends React.Component {

    handleDrawer = () => {
        const action = {type: 'TOGGLE_DRAWER', value: !this.props.isDrawerOpen}
        this.props.dispatch(action)
    }

    handleLoginModal = () => {
        this.props.computeAuthentication(!this.props.isComputingAuthentication);
    };

    render() {
        const {classes, storeAuth} = this.props,
            slug = window.location.pathname;
        return (
            <Drawer
                open={this.props.isDrawerOpen}
                anchor="top"
                onClose={this.handleDrawer}
                className={classes.drawer}
                classes={{paper: classes.paper}}
                transitionDuration={500}
                hideBackdrop
            >
                <div
                    tabIndex={0}
                    role="button"
                    onClick={this.handleDrawer}
                    onKeyDown={this.handleDrawer}
                >
                    <Grid container className={classes.drawerContent} direction="column">
                        <Grid container className={classes.drawerHeaderContainer}>
                            <Grid container alignItems="center" justify="space-between"
                                  className={classes.drawerHeader}>
                                <Grid container alignItems={'center'}>
                                    <AccountCircle className={classes.drawerIcon}/>
                                    <Grid item>
                                        <Link to="/inscription/" className={classes.drawerLink}>
                                            <Typography color="inherit">
                                                S'inscrire
                                            </Typography>
                                        </Link>
                                        <Typography color="inherit" onClick={this.handleLoginModal}>
                                            Se connecter
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.drawerButtonContainer}>
                                    <DrawerButton text="fermer"/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <List className={classes.drawerMenu}>
                            {MenuItems.map((item, index) => (
                                <ListItem key={index} disableGutters>
                                    {(item.authenticationRequired === storeAuth.isAuthenticated || !item.authenticationRequired) &&
                                    <Link to={item.href}
                                          className={`${slug === item.href && classes.drawerLinksActive} ${classes.drawerLinks}`}>
                                        <Typography color="inherit" variant="h4">
                                            {item.name}
                                        </Typography>
                                    </Link>}
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </div>

            </Drawer>
        )
    }
}

NavbarDrawer.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    isDrawerOpen: state.storeDrawerState.isDrawerOpen,
    isLoginOpen: state.storeAuth.isLoginOpen,
    isComputingAuthentication: state.member.authenticate.compute,
    storeAuth: state.storeAuth
});

const mapDispatchToProps = (dispatch) => {
    return {
        computeAuthentication: (value) => dispatch(compute(value)),
        dispatch: (action) => {
            dispatch(action)
        }
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(NavbarDrawer)

