import React from 'react';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import {Hidden, List, ListItem, ListItemText, Typography} from '@material-ui/core';

class Legals extends React.Component {
    render() {
        return (
            <PageLayout title={'Mentions légales'}>
                <Container>
                    <React.Fragment>
                        <Main title={'Mentions légales'}>
                            <React.Fragment>
                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16, marginTop: 16}}>
                                    Éditeur
                                </Typography>
                                <Typography>
                                    Dénomination : Association départementale des Comités Communaux Feux de Forêts des
                                    Bouches du Rhône
                                </Typography>
                                <Typography>
                                    Forme sociale : Association loi 1901
                                </Typography>
                                <Typography>
                                    Adresse / siège : CD 7 - 20 Chemin de Roman - 13120 GARDANNE
                                </Typography>
                                <Typography>
                                    Directeur de la publication : Philippe CHARRIN, Président
                                </Typography>

                                <Typography variant={'body1'} style={{marginBottom: 8, marginTop: 16}}>
                                    Conception du site
                                </Typography>
                                <Typography>
                                    Purjus communication
                                </Typography>
                                <Typography>
                                    Adresse / siège : Le beau réveil – 164 Rte Dept 10 – Le Taron
                                </Typography>
                                <Typography>
                                    13126 Vauvenargues
                                </Typography>
                                <a href="http://www.purjus.fr">
                                    <Typography color={'inherit'}>
                                        http://www.purjus.fr
                                    </Typography>
                                </a>

                                <Typography variant={'body1'} style={{marginBottom: 8, marginTop: 16}}>
                                    Hébergeur
                                </Typography>
                                <Typography>
                                    ÉVOLIX
                                </Typography>
                                <Typography>
                                    Adresse / siège : 7, rue Guibal
                                </Typography>
                                <Typography>
                                    13003 Marseille
                                </Typography>
                                <a href="http://evolix.com/">
                                    <Typography color={'inherit'}>
                                        http://evolix.com/
                                    </Typography>
                                </a>

                                <Typography variant={'caption'}>
                                    L'internaute est informé qu'il existe des moyens techniques permettant de
                                    restreindre l'accès à certains services et/ou informations disponibles sur Internet.
                                </Typography>
                                <Typography variant={'h4'} color={'secondary'}
                                            style={{marginBottom: 16, marginTop: 32}}>
                                    Protection des données des utilisateurs et Déclaration CNIL
                                </Typography>
                                <Typography>
                                    Conformément aux dispositions de l'article 34 de la loi 78-17du 6 janvier 1978 (Loi
                                    "Informatique et Libertés"), toute personne bénéficie d'un droit d'accès et de
                                    rectification des informations nominatives le concernant en adressant un e-mail à
                                    l’adresse suivante : adccff13(at)comites-feux.com ou en écrivant à ADCCFF 13, CD 7 -
                                    20 Chemin de Roman - 13120 GARDANNE
                                </Typography>
                                <Typography>
                                    N° de déclaration CNIL : En conformité à la Dispense n° 8 - Délibération n° 2010-229
                                    du 10 juin 2010
                                </Typography>

                                <Typography variant={'h4'} color={'secondary'}
                                            style={{marginBottom: 16, marginTop: 32}}>
                                    Avertissement
                                </Typography>
                                <Typography color={'textPrimary'}>
                                    Dans le cadre de ce site, et notamment des forums de discussions, l'utilisateur
                                    s'engage à respecter les droits d'autrui, les bonnes mœurs, l'ordre public et les
                                    règles de bienséance en usage. A ce titre, l'utilisateur s'engage à ne pas utiliser
                                    le site pour afficher, télécharger, envoyer ou transmettre des contenus :
                                </Typography>
                                <List>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - contraires à l'ordre public ou aux bonnes mœurs
                                            </Typography>
                                        }/>
                                    </ListItem>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - à caractère violent, pornographique ou pédophile ou susceptible de
                                                porter atteinte au respect et à la dignité de la personne humaine, à l\
                                                'égalité entre les hommes et les femmes, à la protection des enfants et
                                                des adolescents
                                            </Typography>
                                        }/>

                                    </ListItem>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - qui encouragent à la commission de crimes ou de délits ou qui puisse
                                                inciter à la consommation de substances interdites
                                            </Typography>
                                        }/>
                                    </ListItem>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - relatifs à la vente d\'organes ou d\'embryons humains
                                            </Typography>
                                        }/>

                                    </ListItem>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - à caractère raciste ou xénophobe ou susceptible de provoquer la
                                                discrimination, la haine, la violence en raison de la race, de l\'ethnie
                                                ou de la nation
                                            </Typography>
                                        }/>

                                    </ListItem>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - qui soient nuisibles, menaçants, abusifs, constitutifs de harcèlement,
                                                diffamatoires, dénigrants, vulgaires, obscènes, menaçants pour la vie
                                                privée d\'autrui ou de nature à heurter la sensibilité de certaines
                                                personnes
                                            </Typography>
                                        }/>
                                    </ListItem>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - qui conduisent à l\'apologie du terrorisme, des crimes de guerre, du
                                                nazisme, à la contestation de l\'existence de crimes contre l\'Humanité,
                                                ou encore à la diffusion de fausse nouvelle
                                            </Typography>
                                        }/>
                                    </ListItem>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - qui portent atteinte aux droits de tiers et notamment, sans que cette
                                                liste soit exhaustive, à tout secret de fabrication, secret
                                                professionnel ou information confidentielle, marque, brevet, droit
                                                d\'auteur et d\'une manière générale tout droit de propriété
                                                intellectuelle ou tout autre droit de propriété
                                            </Typography>
                                        }/>
                                    </ListItem>
                                    <ListItem dense>
                                        <ListItemText disableTypography primary={
                                            <Typography variant={'body2'}>
                                                - comprenant des virus informatiques ou tout autre code ou programme,
                                                conçus ou ayant pour effet d\'interrompre, détruire ou limiter la
                                                fonctionnalité de tout logiciel, ordinateur ou outil de
                                                télécommunication
                                            </Typography>
                                        }/>
                                    </ListItem>
                                </List>

                                <Typography variant={'h4'} color={'secondary'} style={{marginBottom: 16}}>
                                    Crédits
                                </Typography>
                                <Typography style={{marginBottom: 16}}>
                                    <Typography component={'span'} style={{display: 'inline', fontWeight: 700}}>
                                        Textes :&nbsp;
                                    </Typography>
                                    Décharge de responsabilité : l'ADCCFF 13 n'est tenu que d'une simple obligation de
                                    moyens concernant les informations qu'il met à disposition des personnes qui
                                    accèdent à son site Web. Alors même que nous avons effectué toutes les démarches
                                    pour nous assurer de la fiabilité des informations contenues sur ce site Web,
                                    l'ADCCFF 13 ne peut encourir aucune responsabilité du fait d'erreurs, d'omissions,
                                    ou pour les résultats qui pourraient être obtenus par l'usage de ces informations.
                                    Notamment, l'usage de liens hypertextes peut conduire votre consultation de notre
                                    site vers d'autres serveurs pour prendre connaissance de l'information recherchée,
                                    serveurs sur lesquels le Aixtend n'a aucun contrôle.
                                </Typography>
                                <Typography>
                                    <Typography component={'span'} style={{display: 'inline', fontWeight: 700}}>
                                        Droits d'auteurs :&nbsp;
                                    </Typography>
                                    En application du Code français de la Propriété Intellectuelle et, plus
                                    généralement, des traités et accords internationaux comportant des dispositions
                                    relatives à la protection des droits d'auteurs, vous vous interdirez de reproduire
                                    pour un usage autre que privé, de vendre, de distribuer, d'émettre, de diffuser,
                                    d'adapter, de modifier, de publier, de communiquer intégralement ou partiellement,
                                    sous quelque forme que ce soit, les données, la présentation ou l'organisation du
                                    site ou les oeuvres protégées par le droit d'auteur qui figurent sur ce site sans
                                    autorisation écrite particulière et préalable du détenteur du droit d'auteur attaché
                                    à l'oeuvre, à la présentation ou à l'organisation du site ou à la donnée reproduite.
                                </Typography>
                            </React.Fragment>
                        </Main>
                        <Hidden mdDown>
                            <Aside/>
                        </Hidden>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

export default Legals;

