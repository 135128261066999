import React from 'react';
import AD13Input from '../partials/AD13Input';
import {FormHelperText, Typography} from '@material-ui/core';

class AccountForm extends React.Component {
    render() {
        const {handleChange, association, modifyError} = this.props;
        return (
            null !== association && <form noValidate autoComplete="off">
                <AD13Input
                    type={'text'}
                    id="outlined-address"
                    label={'Adresse'}
                    value={association.address}
                    onChange={handleChange('address')}
                    fullWidth
                    error={modifyError.hasOwnProperty('address')}
                />
                {modifyError.hasOwnProperty('address') &&
                <FormHelperText error={true}>{modifyError.address}</FormHelperText>}
                <AD13Input
                    type={'tel'}
                    id="outlined-phone"
                    label={'Téléphone'}
                    value={association.phoneNumber}
                    onChange={handleChange('phoneNumber')}
                    fullWidth
                    error={modifyError.hasOwnProperty('phoneNumber')}
                />
                {modifyError.hasOwnProperty('phoneNumber') &&
                <FormHelperText error={true}>{modifyError.phoneNumber}</FormHelperText>}
                <AD13Input
                    type={'email'}
                    id="outlined-email"
                    label={'Email'}
                    value={association.email}
                    onChange={handleChange('email')}
                    fullWidth
                    error={modifyError.hasOwnProperty('email')}
                />
                {modifyError.hasOwnProperty('email') &&
                <FormHelperText error={true}>{modifyError.email}</FormHelperText>}
                <AD13Input
                    type={'text'}
                    id="outlined-web"
                    label={'Site web'}
                    value={association.websiteUrl}
                    onChange={handleChange('websiteUrl')}
                    fullWidth
                    error={modifyError.hasOwnProperty('websiteUrl')}
                />
                {modifyError.hasOwnProperty('websiteUrl') &&
                <FormHelperText error={true}>{modifyError.websiteUrl}</FormHelperText>}
                <AD13Input
                    type={'text'}
                    id="outlined-responsible"
                    label={'Responsable'}
                    value={association.accountant}
                    onChange={handleChange('accountant')}
                    fullWidth
                    error={modifyError.hasOwnProperty('accountant')}
                />
                {modifyError.hasOwnProperty('accountant') &&
                <FormHelperText error={true}>{modifyError.accountant}</FormHelperText>}
                <AD13Input
                    type={'text'}
                    id="outlined-deputy"
                    label={'Adjoint(s)'}
                    value={association.assistant}
                    onChange={handleChange('assistant')}
                    fullWidth
                    helperText={<Typography component={'span'} variant={'caption'}>Séparez les noms d'une virgule</Typography>}
                    error={modifyError.hasOwnProperty('assistant')}
                />
                {modifyError.hasOwnProperty('assistant') &&
                <FormHelperText error={true}>{modifyError.assistant}</FormHelperText>}
                <AD13Input
                    type={'number'}
                    id="outlined-members"
                    label={'Nombre de membres'}
                    value={association.numberOfMembers?association.numberOfMembers.toString():''}
                    onChange={handleChange('numberOfMembers')}
                    fullWidth
                    error={modifyError.hasOwnProperty('numberOfMembers')}
                />
                {modifyError.hasOwnProperty('numberOfMembers') &&
                <FormHelperText error={true}>{modifyError.numberOfMembers}</FormHelperText>}
                <AD13Input
                    type={'text'}
                    id="outlined-means"
                    label={'Moyens et véhicules'}
                    value={association.means}
                    onChange={handleChange('means')}
                    fullWidth
                    error={modifyError.hasOwnProperty('means')}
                />
                {modifyError.hasOwnProperty('means') &&
                <FormHelperText error={true}>{modifyError.means}</FormHelperText>}
            </form>
        )
    }
}

export default AccountForm

