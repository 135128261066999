import {fetch} from '../../utils/dataAccess';

export function error(error) {
    return {type: 'MEMBER_ADMIN_ASSOCIATION_MODIFICATION_ERROR', error};
}

export function loading(loading) {
    return {type: 'MEMBER_ADMIN_ASSOCIATION_MODIFICATION_LOADING', loading};
}

export function success(modified) {
    return {type: 'MEMBER_ADMIN_ASSOCIATION_MODIFICATION_SUCCESS', modified};
}

export function modify(id, values) {

    return dispatch => {
        dispatch(loading(true));
        return fetch('admin_association_modification/' + id, {method: 'PUT', body: JSON.stringify(values)})
            .then(response => {
                dispatch(loading(false));
                return response.json();
            })
            .then(modified => {
                dispatch(success(modified));
                dispatch(error(null));
            })
            .catch(e => {
                dispatch(success(null))
                dispatch(loading(false));
                dispatch(error(e.errors));
            });
    };
}

export function reset() {
    return dispatch => {
        dispatch(loading(false));
        dispatch(error(null));
    };
}
