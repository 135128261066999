import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import SectionTitle from './SectionTitle';
import {withStyles, Grid} from '@material-ui/core/';

const styles = theme => ({
    main: {
        padding: 0,
        paddingBottom: theme.spacing.unit * 6,
        paddingRight: theme.spacing.unit * 5,
        paddingTop: theme.spacing.unit * 27,
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters(),
            zIndex: 100,
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing.unit * 11,
            paddingTop: theme.spacing.unit * 47.5,
        },
    },
    mainWithBackground: {
        [theme.breakpoints.down('md')]: {
            background: theme.palette.background.bottomGradient,
        },
    }
});

class Main extends React.Component {
    render() {
        const {classes, width, title, children} = this.props;
        const isMobile = !isWidthUp('md', width)
        return (
            <Grid item xs={12} md={9} component="main" className={classes.main}>
                {title && <SectionTitle title={title} color={'primary'} paddingBottom={isMobile ? 16 : 32} variant={isMobile ? 'h5' : 'h3'}/>}
                {children}
            </Grid>
        )
    }
}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.object,
}

export default compose(
    withWidth(),
    withStyles(styles)
)(Main)

