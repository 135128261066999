import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/';
import {Grid} from '@material-ui/core';
import {KeyboardArrowLeft} from '@material-ui/icons/'
import { compose } from 'redux'
import {withRouter} from 'react-router-dom'

const size = 80,
    coteCta = Math.sqrt(Math.pow(size, 2) / 2),
    pointe = (size - coteCta) / 2

const styles = theme => ({
    cta: {
        height: size,
        position: 'absolute',
        left: 0,
        [theme.breakpoints.down('md')]: {
            left: 16,
        },
        top: - (60 + (size / 2)),
        width: size,
        zIndex: 600,
    },
    ctaAction: {
        alignItems: "center",
        color: theme.palette.primary.contrastText,
        display: "flex",
        height: size,
        justifyContent: "center",
        margin: 'auto',
        position: 'relative',
        textDecoration: 'none',
        width: size,
        zIndex: 10,
        '&:hover': {
            color: theme.palette.primary.contrastText,
            textDecoration: 'none',
        },
        '&::before': {
            backgroundColor: theme.palette.primary.main,
            content: "''",
            display: 'block',
            height: coteCta,
            left: pointe,
            position: 'absolute',
            top: pointe,
            transform: 'rotateZ(45deg)',
            transition: theme.transition.smooth,
            width: coteCta,
            zIndex: 0,
        },
        '&:hover::before': {
            background: theme.palette.primary.light,
            cursor: 'pointer',
        },
    },
    ctaIcon: {
        zIndex: "inherit",
    }
});

class AD13ReturnCTA extends React.Component {

    redirect = () => {
        if (2 < this.props.history.length) {
            this.props.history.goBack();
        } else {
            this.props.history.push('/');
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <Grid item className={classes.cta} onClick={() => this.redirect()}>
                <Grid container className={classes.ctaAction}>
                    <KeyboardArrowLeft className={classes.ctaIcon}/>
                </Grid>
            </Grid>
        )
    }
}

AD13ReturnCTA.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(AD13ReturnCTA);
