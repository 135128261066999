import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, Grid, Typography} from '@material-ui/core';
import SectionTitle from '../partials/SectionTitle';
import { HashLink as Link } from 'react-router-hash-link';

const styles = theme => ({
    asideContainer: {
        position: 'relative',
    },
    asideContent: {
        paddingBottom: theme.spacing.unit * 5,
        zIndex: 0,
    },
    captionTitle: {
        marginTop: theme.spacing.unit * 2,
        textTransform: 'uppercase',
    },
    asideContentBlock: {
        marginBottom: theme.spacing.unit * 2,
        maxWidth: 300,
    },
    link: {
        color: theme.palette.textPrimary,
        transition: 'all .3s',
        '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
        }
    },
    activeLink: {
        color: 'red'
    }
});

class FormationAside extends React.Component {

    state = {
        formationByYear: []
    };

    rankFormationByMonth = (formations) => {
        const formationByYear = [];

        for (const formation of formations) {

            const currentDate = new Date(formation.events[0].dateStart);
            const currentMonth = currentDate.toLocaleString('fr-Fr', {month: 'numeric'});
            const currentMonthDisplay = currentDate.toLocaleString('fr-Fr', {month: 'long'});
            const currentYear = currentDate.toLocaleString('fr-Fr', {year: 'numeric'});

            // création clef de l'année si pas existante
            if (!(currentYear in formationByYear)) {
                formationByYear[currentYear] = {year: currentYear, months: []};
            }

            // création clef du mois dans l'année si pas existant
            let currentValue = null;
            if (!(currentMonth in formationByYear[currentYear].months)) {
                currentValue = {display: currentMonthDisplay, count: 0, items: []};
                formationByYear[currentYear].months[currentMonth] = currentValue;
            } else {
                currentValue = formationByYear[currentYear].months[currentMonth];
            }

            currentValue['count'] += 1;
            currentValue['items'].push(formation);
        }

        const cleanedTab = formationByYear.filter(formation => formation !== undefined)

        const reversedTab = this.reverseArray(cleanedTab)

        /*inverse l'ordre des mois pour l'aside*/
        reversedTab.map(formation =>
            formation.months.reverse()
        )

        this.setState({formationByYear: reversedTab});
    };

    componentDidMount() {
        if(0 === this.state.formationByYear.length) {
            this.rankFormationByMonth(this.props.items['hydra:member'])
        }
    };

    reverseArray = (arr) => {
        var newArray = [];
        for (var i = arr.length - 1; i >= 0; i--) {
            newArray.push(arr[i]);
        }
        return newArray;
    };

    render() {
        const {classes} = this.props;
        return (
            <Grid container className={classes.asideContainer}>
                <Grid container className={classes.asideContent}>
                    {this.state.formationByYear.map((formation, index) => (
                        <React.Fragment key={index}>
                            <SectionTitle
                                title={formation.year}
                                color={'secondary'}
                                paddingBottom={16}
                                paddingTop={index !== 0 ? 32 : 0}
                                variant={'h4'}
                            />
                            {formation.months.map((month, i) => (
                                <Grid item xs={12} key={i} style={{paddingBottom: 16}}>
                                    <Link className={classes.link}
                                          to={'/formations/#formations_' + month.display + formation.year} smooth>
                                        <Typography color={'inherit'}>
                                            {month.display} ({month.count})
                                        </Typography>
                                    </Link>
                                </Grid>
                            ))}
                        </React.Fragment>
                    ))}
                </Grid>
            </Grid>
        )
    }
};

FormationAside.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired
};

export default withStyles(styles)(FormationAside);
