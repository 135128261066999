import React from 'react'
import PropTypes from 'prop-types'
import DrawerCTA from './CarteDrawerCTA'
import {
    withStyles,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Drawer
} from '@material-ui/core'
import {
    LocationOn,
    Phone,
    Email,
    Public,
    Person,
    Group,
    Wc,
    LocalShipping,
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons/'
import AD13Button from '../partials/AD13Button';
import {loadState} from '../../store/localstorage';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';

const styles = theme => ({
    drawerPaper: {
        boxShadow: '0 4px 16px 0 rgba(0,0,0,0.2)',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 400
        }
    },
    card: {
        borderRadius: 0,
        overflowY: 'scroll',
        position: 'relative',
    },
    cardMedia: {
        backgroundColor: theme.palette.primary.main,
        height: 220,
    },
    cardHeader: {
        background: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText,
    },
    listItem: {
        alignItems: 'flex-start',
        minHeight: theme.spacing.unit * 4,
    },
    listItemIcon: {
        marginTop: theme.spacing.unit * 0.5,
    },
    iconButton: {
        marginLeft: theme.spacing.unit * 1.5,
        padding: 0,
    },
    listItemCollapsible: {
        paddingTop: 0,
    }
})

const DrawerListItem = (props) => {
    return (
        <ListItem className={props.classes}>
            <ListItemIcon className={props.classes.listItemIcon}>
                {props.icone}
            </ListItemIcon>
            <ListItemText
                primary={props.primary}
                secondary={props.secondary}
            />
        </ListItem>
    )
};

class CarteDrawerRight extends React.Component {

    state = {
        isDescriptionCollapsed: false,
        isDeputyCollapseOpen: false
    };

    toggleDescription = () => {
        this.setState({isDescriptionCollapsed: !this.state.isDescriptionCollapsed})
    };

    toggleDeputyCollapse = () => {
        this.setState({isDeputyCollapseOpen: !this.state.isDeputyCollapseOpen})
    };

    canModify = () => {
        const localState = loadState();

        if (false === localState.storeAuth.isAuthenticated) {
            return false;
        }

        return localState.storeAuth.user.associations.some(association => {
            return association.uuid === this.props.commune.uuid;
        });
    };

    render() {
        const {classes, isDrawerOpen, toggleDrawer, commune} = this.props;
        const {isDescriptionCollapsed} = this.state;
        if (undefined !== commune) {
            const {description, address, phoneNumber, email, websiteUrl, accountant, assistant, members, means, mapZoneLabel, uuid, image, numberOfMembers} = commune,
            communeImage = image ? image.url : darkRabbit;
            return (
                <Drawer open={isDrawerOpen}
                        anchor={'right'}
                        variant={'temporary'}
                        className={classes.drawer}
                        classes={{paper: classes.drawerPaper}}
                        disableAutoFocus
                        onBackdropClick={() => toggleDrawer(false)}
                >
                    <Card elevation={0} className={classes.card}>
                        <DrawerCTA toggleDrawer={toggleDrawer}/>
                        <CardMedia
                            image={communeImage}
                            className={classes.cardMedia}
                        />
                        <CardHeader
                            title={commune.associationType &&
                            <Typography color={'inherit'} variant={'subtitle1'} style={{textTransform: 'uppercase'}} gutterBottom>
                                {commune.associationType.name} de
                            </Typography>
                            }
                            subheader={
                                <Typography color={'inherit'} variant={'h4'} style={{textTransform: 'capitalize'}}>
                                    {mapZoneLabel}
                                </Typography>
                            }
                            className={classes.cardHeader}
                            disableTypography
                        />
                        {description &&
                        <CardContent>
                            <Grid container justify={'flex-start'} alignItems={'center'}>
                                <Typography>
                                    Lire la suite
                                </Typography>
                                <IconButton onClick={this.toggleDescription}>
                                    {isDescriptionCollapsed ?
                                        <KeyboardArrowUp/>
                                        :
                                        <KeyboardArrowDown/>
                                    }
                                </IconButton>
                            </Grid>
                            {isDescriptionCollapsed && <Typography dangerouslySetInnerHTML={{__html: '<div>' + description + '</div>'}}/>}
                        </CardContent>
                        }
                        <Divider/>
                        <CardContent>
                            <List>
                                <DrawerListItem
                                    classes={classes.listItem}
                                    icone={<LocationOn color={'primary'}/>}
                                    primary={
                                        address &&
                                        <Typography>{address}</Typography>
                                    }
                                />
                                <DrawerListItem
                                    classes={classes.listItem}
                                    icone={<Phone color={'primary'}/>}
                                    primary={
                                        phoneNumber &&
                                        <Typography>{phoneNumber}</Typography>
                                    }
                                />
                                <DrawerListItem
                                    classes={classes.listItem}
                                    icone={<Email color={'primary'}/>}
                                    primary={
                                        email &&
                                        <Typography>{email}</Typography>
                                    }
                                />
                                <DrawerListItem
                                    classes={classes.listItem}
                                    icone={<Public color={'primary'}/>}
                                    primary={
                                        websiteUrl &&
                                        <Typography>{websiteUrl}</Typography>
                                    }
                                />
                                <DrawerListItem
                                    classes={classes.listItem}
                                    icone={<Person color={'primary'}/>}
                                    primary={
                                        <Typography>
                                            {'Responsable(s)'}
                                        </Typography>
                                    }
                                    secondary={
                                        accountant &&
                                        <Typography variant={'caption'}>
                                            {accountant}
                                        </Typography>
                                    }
                                />
                                <DrawerListItem
                                    classes={classes.listItem}
                                    icone={<Group color={'primary'}/>}
                                    primary={
                                        <Typography>
                                            {'Adjoint(s)'}
                                        </Typography>
                                    }
                                    secondary={
                                        assistant &&
                                        <Typography variant={'caption'}>
                                            {assistant}
                                        </Typography>
                                    }
                                />
                                <DrawerListItem
                                    classes={classes.listItem}
                                    icone={<Wc color={'primary'}/>}
                                    primary={
                                        <Typography>{null === numberOfMembers ? 0 : numberOfMembers } membre(s)</Typography>
                                    }
                                />
                                <DrawerListItem
                                    classes={classes.listItem}
                                    icone={<LocalShipping color={'primary'}/>}
                                    primary={
                                        means &&
                                        <Typography>{means}</Typography>
                                    }
                                />
                            </List>
                            {this.canModify() && <AD13Button text="Éditer la fiche" href={"/compte/" + uuid}/>}
                        </CardContent>
                    </Card>
                </Drawer>
            )

        }
    }
}

CarteDrawerRight.propTypes = {
    isDrawerOpen: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    commune: PropTypes.object.isRequired,
};

export default withStyles(styles)(CarteDrawerRight);
