import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

class Ad13FileList extends React.Component {

    state = {
        previewFiles: [],
        articleSubmitted: false,
        snackbarIsOpen: false,
        snackbarMessage: null,
    };

    constructor(props) {
        super(props);

        if (props.hasOwnProperty('previewFiles')) {
            this.state = {previewFiles: props.previewFiles};
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.hasOwnProperty('previewFiles')) {
            this.setState(state => {
                return {previewFiles: nextProps.previewFiles}
            });
        }
    }

    updateClipboard(elementId) {
        // Select the email link anchor text
        var fileLink = document.querySelector('#' + elementId);
        var range = document.createRange();
        range.selectNode(fileLink);
        window.getSelection().addRange(range);

        try {
            // Now that we've selected the anchor text, execute the copy command
            document.execCommand('copy');
        } catch(err) {
            console.log('Oops, unable to copy');
        }

        // Remove the selections - NOTE: Should use
        // removeRange(range) when it is supported
        window.getSelection().removeAllRanges();
    };


    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({snackbarIsOpen: false});
    };

    render() {
        const {snackbarIsOpen, snackbarMessage} = this.state;

        return (0 < this.state.previewFiles.length &&
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Nom</TableCell>
                        <TableCell align="left">Url</TableCell>
                        <TableCell align="left">Supprimer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.previewFiles.map(file => {
                        return (
                            <TableRow key={file.name}>
                                <TableCell>
                                    {file.name}
                                </TableCell>
                                <TableCell>
                                    <a href="javascript:void(0)" id={'file-' + file.uuid} onClick={() => this.updateClipboard('file-' + file.uuid)} title={"Copier le lien"}>
                                        {file.url}
                                        <IconButton aria-label="Copier le lien">
                                            <LinkIcon/>
                                        </IconButton>
                                    </a>
                                </TableCell>
                                <TableCell>
                                    <IconButton title={"Supprimer"} aria-label="Supprimer" onClick={() => {
                                        this.props.onDelete(file['uuid'])
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackbarIsOpen}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <span id="message-id">
                                                        {snackbarMessage}
                                                    </span>
                    }
                    action={
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.handleClose}
                        >
                            <CloseIcon/>
                        </IconButton>
                    }
                />
            </Table>
        )
    }
}


export default  Ad13FileList
