import React from 'react'
import PropTypes from 'prop-types'
import {withStyles, Grid} from '@material-ui/core'

const styles = theme => ({
    header: {
        color: theme.palette.primary.contrastText,
        height: theme.spacing.unit * 52.5,
        maxHeight: '100%',
        position: 'relative',
        overflow: 'hidden'
    },
    headerBlured: {
        '&:-webkit': {
            filter: 'blur(50px)',
        },
        '&:-moz': {
            filter: 'blur(50px)',
        },
        '&:-o': {
            filter: 'blur(50px)',
        },
        filter: 'blur(50px)',
        height: theme.spacing.unit * 52.5,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0
    },
    headerContainer: {
        zIndex: 10
    },
    overlay: {
        background: theme.palette.background.overlay,
        height: 'inherit',
    }
});

class AD13SingleHeader extends React.Component {
    render() {
        const {classes, itemImage, children} = this.props;
        return (
            <Grid container className={classes.header}>
                <Grid container className={classes.headerBlured} style={{background: 'url(' + itemImage + ') 50% 50% / cover no-repeat'}}/>
                <Grid container className={classes.headerContainer} style={{background: 'url(' + itemImage + ') 50% 50% / contain no-repeat'}}>
                    <Grid container className={classes.overlay} alignItems={'center'}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
};

AD13SingleHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    itemImage: PropTypes.string
};

export default withStyles(styles)(AD13SingleHeader);

