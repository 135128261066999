import React from 'react';
import PropTypes from 'prop-types';
import AD13Button from '../partials/AD13Button';
import AD13Input from '../partials/AD13Input';
import AD13Autocomplete from '../partials/AD13Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import {Checkbox, Collapse, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, withStyles, Button, Grid, Stepper, Step, StepLabel, StepConnector, Typography} from '@material-ui/core/';
import {Link} from 'react-router-dom';

import {
    fetch,
    normalize,
} from '../../utils/dataAccess';

const styles = theme => ({
    stepper: {
        background: 'transparent',
        paddingLeft: 0,
        marginBottom: theme.spacing.unit * 2,
    },
    stepLabel: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
        fontSize: '18px',
        marginLeft: theme.spacing.unit,
    },
    stepLabelActive: {
        [theme.breakpoints.down('md')]: {
            display: 'block'
        },
        fontWeight: 700 + '!important',
    },
    stepIcon: {
        background: theme.palette.textSecondary,
        fill: 'transparent',
        transform: 'rotate(45deg)',
        transformOrigin: '50% 50%'
    },
    stepIconActive: {
        background: theme.palette.primary.main,
    },
    stepIconText: {
        transform: 'rotate(-45deg)',
        transformOrigin: '50% 50%',
    },
    connectorContainer: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    form: {
        width: '100%',
    },
    ctaContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2.5,
        marginTop: theme.spacing.unit * 4,
        '&:hover': {
            cursor: 'pointer',
        }
    },
    buttonContainer: {
        height: theme.spacing.unit * 5,
        width: theme.spacing.unit * 5,
    },
    buttonMail: {
        background: theme.palette.primary.main,
    },
    iconContainer: {
        marginRight: theme.spacing.unit * 2,
        height: 'inherit',
    },
    icon: {
        color: theme.palette.primary.contrastText,
    },
    textContainer: {
        color: theme.palette.primary.contrastText,
        padding: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        width: 250,
    },
    textMail: {
        background: theme.palette.primary.main,
    },
});

const consent = 'Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Purjus communication.\n' +
    ' Elles sont conservées pendant 36 mois et sont destinées à la gestion de l\'association AD13.\n' +
    'Conformément aux article 15 à 22 RGPD, concernant les données que nous détenons sur vous vous disposez des droits suivants : \n' +
    '- droit d’accès (article 15 du RGPD)\n' +
    '- droit de rectification (article 16 du RGPD)\n' +
    '- droit d’effacement  (article 17 du RGPD)\n' +
    '- droit à la limitation du traitement  (article 18 du RGPD)\n' +
    '- droit de notification des = rectifications,  effacements, limitation  (article 19 du RGPD)\n' +
    '- droit à la portabilité des données  (article 20 du RGPD)\n' +
    '- droit d’opposition  (article 21 du RGPD)\n' +
    '- droit de ne pas faire l’objet d’un profilage (article 22 du RGPD) \n' +
    '\n' +
    'Vous pouvez exercer ces droit en contactant : Purjus communication - Le beau réveil – 164 Rte Dept 10 – Le Taron – 13126 Vauvenargues - contact@purjus.fr.\n' +
    'Nous vous informons de l’existence de la liste d\'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/. '


class SignUpStepper extends React.Component {

    state = {
        activeStep: 0,
        form: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            associationId: '',
            civilityId: '',
            address: '',
            postalCode: '',
            consent: consent
        },
        civilities: [],
        errors: {},
        consentOpened: false,
        consentCheckbox: false
    };


    componentDidMount() {
        this.populatesCivilitiesChoices();
    }

    handleChange = name => event => {
        let value = event.target.value;
        this.setState(state => {
            state.form[name] = value;
            return state;
        });
    };

    prevStep = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };

    nextStep = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        });
    };

    submitRegistration = () => {
        let values = this.state.form;

        fetch('admin_association_registration', {method: 'POST', body: JSON.stringify(values)})
            .then(response => {
                return response.json();
            })
            .then(retrieved => {
                this.nextStep();
            })
            .catch(e => {
                this.setState({errors: e.errors});
            });
    };

    getAssociationsSuggestions = (value) => {
        return new Promise((resolve) => {
            fetch('/associations?mapZoneLabel=' + value)
                .then(response =>
                    response
                        .json()
                        .then(retrieved => {
                            retrieved = normalize(retrieved);

                            resolve(retrieved['hydra:member'].map(item => ({
                                id: item['uuid'],
                                label: item['mapZoneLabel']
                            })));
                        })
                )
        });
    };

    populatesCivilitiesChoices = () => {
        return new Promise((resolve) => {
            fetch('/civilities')
                .then(response =>
                    response
                        .json()
                        .then(retrieved => {
                            retrieved = normalize(retrieved);
                            this.setState({civilities: retrieved['hydra:member']})
                        })
                )
        });
    };

    getSelectedAssociation = (item) => {
        this.setState(state => {
            if (null === item) {
                state.form['associationId'] = '';
            } else {
                state.form['associationId'] = item['id'];
            }
            return state;
        });
    };

    getSteps() {
        return ['Inscription', 'Informations', 'Confirmation'];
    }

    toggleConsent = () => {
        this.setState({consentOpened: !this.state.consentOpened})
    }

    checkConsent = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    getStepContent(stepIndex, classes) {
        switch (stepIndex) {
            case 0:
                return (
                    <Grid container style={{maxWidth: 500}}>
                        <Typography variant={'body2'} color={'textPrimary'} style={{marginBottom: 16}}>
                            L'inscription est exclusivement réservée aux Responsables des CCFF/RCSC adhérents de
                            l'ADCCFF des Bouches-du-Rhône. Cette inscription vous permettra ainsi de compléter la fiche de
                            votre CCFF/RCSC, de la tenir à jour, d’y publier des informations sur vos activités et d'inscrire
                            vos bénévoles aux formations.
                        </Typography>
                        <Typography variant={'body2'} color={'textPrimary'}>
                            Si vous êtes « Equipiers » au sein d’un CCFF/RCSC, seul votre responsable peut demander
                            l’ouverture d’un compte sur le site de l'AD13.
                        </Typography>
                        <Grid container justify={'flex-end'} alignItems={'center'} className={classes.ctaContainer}>
                            <AD13Button text="S'inscrire" color={'primary'} onClick={this.nextStep}/>
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container style={{maxWidth: 500}}>
                        <Typography variant={'body2'} color={'textPrimary'} style={{marginBottom: 16}}>
                            Complétez le formulaire pour faire votre demande de création de compte.
                        </Typography>
                        <form className={classes.form} noValidate autoComplete="off">
                            <FormControl className={classes.formControl}>
                                <FormLabel component="legend">Civilité</FormLabel>
                                {0 < this.state.civilities.length && <RadioGroup

                                    aria-label="civility"
                                    name="Civilité"
                                    value={this.state.form.civilityId}
                                    onChange={this.handleChange('civilityId')}
                                    row
                                >
                                    {this.state.civilities.map(civility => (
                                        <FormControlLabel
                                            key={civility.uuid}
                                            value={civility.uuid}
                                            control={<Radio color="primary" />}
                                            label={civility.name}
                                            labelPlacement="start"
                                        />
                                    ))}
                                </RadioGroup>}
                            </FormControl>
                            <AD13Input
                                required
                                id="outlined-firstName"
                                label="Prénom"
                                type="text"
                                value={this.state.form.firstName}
                                onChange={this.handleChange('firstName')}
                                error={this.state.errors.hasOwnProperty('firstName')}
                                fullWidth
                            />
                            {this.state.errors.hasOwnProperty('firstName') &&
                            <FormHelperText error={true}>{this.state.errors.firstName}</FormHelperText>}
                            <AD13Input
                                required
                                id="outlined-name"
                                label="Nom"
                                type="text"
                                value={this.state.form.lastName}
                                onChange={this.handleChange('lastName')}
                                error={this.state.errors.hasOwnProperty('lastName')}
                                fullWidth
                            />
                            {this.state.errors.hasOwnProperty('lastName') &&
                            <FormHelperText error={true}>{this.state.errors.lastName}</FormHelperText>}
                            <AD13Input
                                required
                                id="outlined-mail"
                                label="Email"
                                type="email"
                                value={this.state.form.email}
                                onChange={this.handleChange('email')}
                                error={this.state.errors.hasOwnProperty('email')}
                                fullWidth
                            />
                            {this.state.errors.hasOwnProperty('email') &&
                            <FormHelperText error={true}>{this.state.errors.email}</FormHelperText>}
                            <AD13Input
                                id="outlined-postalCode"
                                label="Code postal"
                                type="text"
                                className={classes.textField}
                                value={this.state.form.postalCode}
                                onChange={this.handleChange('postalCode')}
                                error={this.state.errors.hasOwnProperty('postalCode')}
                                fullWidth
                            />
                            {this.state.errors.hasOwnProperty('postalCode') &&
                            <FormHelperText error={true}>{this.state.errors.postalCode}</FormHelperText>}
                            <AD13Input
                                id="outlined-address"
                                label="Adresse"
                                type="text"
                                className={classes.textField}
                                value={this.state.form.address}
                                onChange={this.handleChange('address')}
                                error={this.state.errors.hasOwnProperty('address')}
                                fullWidth
                            />
                            {this.state.errors.hasOwnProperty('address') &&
                            <FormHelperText error={true}>{this.state.errors.address}</FormHelperText>}

                            <AD13Autocomplete
                                required
                                id="outlined-Commune"
                                label="Commune"
                                error={this.state.errors.hasOwnProperty('associationId')}
                                fullWidth
                                callbackDataRetriever={this.getAssociationsSuggestions}
                                callbackSelectedItem={this.getSelectedAssociation}
                            />
                            {this.state.errors.hasOwnProperty('associationId') &&
                            <FormHelperText error={true}>{this.state.errors.associationId}</FormHelperText>}

                            <AD13Input
                                required
                                id="outlined-tel"
                                label="Téléphone"
                                type="tel"
                                value={this.state.form.phoneNumber}
                                onChange={this.handleChange('phoneNumber')}
                                error={this.state.errors.hasOwnProperty('phoneNumber')}
                                fullWidth
                            />
                            {this.state.errors.hasOwnProperty('phoneNumber') &&
                            <FormHelperText error={true}>{this.state.errors.phoneNumber}</FormHelperText>}
                            <Grid container style={{marginTop: 16}}>
                                <Grid item xs={12}>
                                    <Grid container justify={'flex-start'} alignItems={'center'} wrap={'nowrap'}>
                                        <Checkbox
                                            color="primary"
                                            checked={this.state.consentCheckbox}
                                            value={'consentCheckbox'}
                                            onChange={this.checkConsent('consentCheckbox')}
                                            required
                                        />
                                        <FormHelperText>
                                            J´accepte que mes données personnelles soient utilisées à des fins de gestion du fonctionnement de l'association (obligatoire)
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Collapse collapsedHeight={'110px'} in={this.state.consentOpened}>
                                        <div style={{border: '1px solid red', padding: 16}}>
                                            <Typography>
                                                {this.state.form.consent}
                                            </Typography>
                                            <Typography>
                                                Vous pouvez consulter les détails du consentement en vous rendant <Link to={'/consentement/'}>ici</Link>
                                            </Typography>
                                        </div>
                                    </Collapse>
                                </Grid>
                                {!this.state.consentOpened &&
                                    <Grid item xs={12} align={'right'} style={{marginBottom: 16, marginTop: 16}}>
                                        <Button onClick={this.toggleConsent} size={'small'}>
                                            Voir les termes du consentement
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </form>
                        <Grid container justify={'space-between'} className={classes.ctaContainer}>
                            <Button variant={'contained'} color={'default'} onClick={this.prevStep}>retour</Button>
                            <AD13Button
                                disabled={!this.state.consentCheckbox}
                                text="valider"
                                color={'primary'}
                                onClick={this.submitRegistration}
                            />
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <Grid container style={{maxWidth: 500}}>
                        <Typography variant={'body2'} color={'textPrimary'} style={{fontWeight: 700, marginBottom: 16}}>
                            Votre demande a bien été prise en compte.
                        </Typography>
                        <Typography variant={'body2'} color={'textPrimary'} gutterBottom >
                            Si elle est acceptée, vous recevrez un email de confirmation.
                        </Typography>
                        <Typography variant={'body2'} color={'textPrimary'} gutterBottom>
                            Vous avez choisi l'identification avec votre compte Email.
                        </Typography>
                        <Typography variant={'body2'} color={'textPrimary'}>
                            Utilisez-le pour vous connecter lors de votre prochaine visite.
                        </Typography>
                        <Grid container  justify={'flex-end'} alignItems={'center'} className={classes.ctaContainer}>
                            <AD13Button text="accueil" color={'primary'} href={'/'}/>
                        </Grid>
                    </Grid>
                );
            default:
                return ' ';
        }
    }


    render() {
        const {classes} = this.props;
        const steps = this.getSteps();

        const connector = (
            <StepConnector
                classes={{
                    root: classes.connectorContainer,
                    line: classes.connectorLine,
                    active: classes.connectorActive,
                    completed: classes.connectorCompleted
                }}
            />
        );

        return (
            <React.Fragment>
                <Stepper nonLinear activeStep={this.state.activeStep} classes={{root: classes.stepper}}
                         connector={connector}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel
                                className={classes.stepButton}
                                classes={{
                                    label: classes.stepLabel,
                                    active: classes.stepLabelActive,
                                    iconContainer: classes.iconContainer,
                                }}
                                StepIconProps={{
                                    classes: {
                                        root: classes.stepIcon,
                                        active: classes.stepIconActive,
                                        completed: classes.stepIconCompleted,
                                        text: classes.stepIconText,
                                    }
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <React.Fragment>
                    {this.getStepContent(this.state.activeStep, classes)}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

SignUpStepper.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(SignUpStepper);
