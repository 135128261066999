import React from 'react';
import PropTypes from 'prop-types';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import {Helmet} from 'react-helmet';

class AD13SingleMetas extends React.Component {
    render() {
        const {item} = this.props,
            itemTitle = item['title'] ? item['title'] : item['name'],
            itemImage = null !== item['image'] ? item['image']['url'] : darkRabbit;
        return (
            <Helmet>
                <title>{itemTitle}</title>
                <meta name="twitter:card" content={'summary_large_image'} />
                <meta name="twitter:title" content={itemTitle} />
                <meta property="twitter:image" content={itemImage} />
                <meta property="og:url" content={encodeURI(window.location.pathname)} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={itemTitle} />
                <meta property="og:description" content={item['description'].replace(/<[^>]*>/g, '')} />
                <meta property="og:image" content={itemImage} />
            </Helmet>
        )
    }
};

AD13SingleMetas.propTypes = {
    item: PropTypes.object.isRequired
};

export default AD13SingleMetas;


