import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import AD13_404_Illustration from '../../assets/AD13_404_Illustration.svg';

import {withStyles, Button, Grid, Typography} from '@material-ui/core'

const styles = theme => ({
    main: {
        background: theme.palette.background.black,
        color: theme.palette.primary.contrastText,
        minHeight: '100vh',
    },
    container: {
        ...theme.mixins.gutters()
    },
    title: {
        marginBottom: theme.spacing.unit * 4,
    },
    image: {
        background: 'url(' + AD13_404_Illustration + ')',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: '40vh',
        marginBottom: theme.spacing.unit * 4,
    },
    text: {
        marginBottom: theme.spacing.unit * 4,
    },
    link: {
        '&:hover': {
            textDecoration: 'none',
        }
    }
})


class NotFound extends React.Component {
    render() {
        const {classes} = this.props
        return (
            <Grid container component={'main'} className={classes.main}>
                <Grid container className={classes.container} alignItems={'center'} justify={'center'}>
                    <Grid item xs={12} className={classes.title} align={'center'}>
                        <Typography variant={'h3'} color={'inherit'}>
                            Êtes-vous perdu ?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.image}/>
                    <Grid item xs={12} align={'center'}>
                        <Typography variant={'body2'} color={'inherit'} className={classes.text}>
                            Ne vous inquiétez pas, nous avons trouvé le chemin vers la sortie
                        </Typography>
                        <Link to={"/"} className={classes.link}>
                            <Button variant={'contained'} color={'primary'}>
                                retour à l'accueil
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NotFound)

