import React from 'react';
import PropTypes from 'prop-types';

import {withStyles, Grid} from '@material-ui/core/';

const styles = theme => ({
    aside: {
        ...theme.mixins.gutters(),
        background: theme.palette.background.lightgrey,
        paddingBottom: theme.spacing.unit * 11,
        paddingTop: theme.spacing.unit * 3,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            height: 'inherit',
            paddingLeft: theme.spacing.unit * 5,
            paddingTop: theme.spacing.unit * 47.5,
        },
    },
});

class Aside extends React.Component {
    render() {
        const {classes, children} = this.props;
        return (
            <Grid item xs={12} md={3} component="aside" className={classes.aside}>
                {children}
            </Grid>
        )
    }
};

Aside.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.object,
    small: PropTypes.bool,
};

export default withStyles(styles)(Aside);

