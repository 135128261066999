import React, {Component} from 'react';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormationModalHeader from './FormationModalHeader';
import AddMemberPanel from '../layouts/AddMemberPanel';
import AddMemberToSelectedPanel from '../layouts/AddMemberToSelectedPanel';
import SelectedMembersPanel from '../layouts/SelectedMembersPanel';
import {withStyles, Card, Grid, Modal} from '@material-ui/core';
import {fetch} from '../../utils/dataAccess';
import {list} from '../../actions/training/list';

const styles = theme => ({
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    card: {
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            overflow: 'scroll',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 840
        }
    },
    leftPanel: {
        paddingBottom: theme.spacing.unit * 4,
        paddingLeft: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 4,
    },
});

class FormationModal extends Component {

    state = {
        leftPanelReverse: false,
        firstName: '',
        lastName: '',
        email: '',
        submissionError: null,
        formationMembers: [],
        formationWaitingMembers: [],
        formationMembersIdsToAdd: [],
        formationWaitingMembersIdsToAdd: [],
        formationMembersIdsToRemove: [],
        formationWaitingMembersIdsToRemove: [],
        associationMembers: [],
    };

    componentWillReceiveProps(nextProps) {
        if (false === nextProps.isModalOpen) {
            this.setState(state => ({
                formationMembers: [],
                formationWaitingMembers: [],
                formationMembersIdsToAdd: [],
                formationWaitingMembersIdsToAdd: [],
                formationMembersIdsToRemove: [],
                formationWaitingMembersIdsToRemove: [],
                associationMembers: [],
            }))
        } else {
            fetch('/members?pagination=false&enabled=1').then(response => {
                response
                    .json().then(data => {
                    this.setState(state => {
                        state.associationMembers = data['hydra:member'];
                        state.numberMaxOfParticipants = this.props.item.numberMaxOfParticipants;
                        state.numberOfParticipants = this.props.item.registeredMembers.length;
                        state.formationMembers = [...this.props.item.registeredMembers.filter(registeredMember => data['hydra:member'].some(associationMember => associationMember.uuid === registeredMember.id))];
                        state.formationWaitingMembers = [...this.props.item.waitingMembers.filter(waitingMember => data['hydra:member'].some(associationMember => associationMember.uuid === waitingMember.id))];
                        return state;
                    })
                })
            });
        }
    };

    addMemberToFormation = (memberId) => {
        let associationMember = this.state.associationMembers.find(associationMember => (memberId === associationMember.uuid));

        this.setState(state => {
            let formationMembers = state.formationMembers;
            let formationWaitingMembers = state.formationWaitingMembers;
            let formationMembersIdsToAdd = state.formationMembersIdsToAdd;
            let formationWaitingMembersIdsToAdd = state.formationWaitingMembersIdsToAdd;
            let formationMembersIdsToRemove = state.formationMembersIdsToRemove;
            let formationWaitingMembersIdsToRemove = state.formationWaitingMembersIdsToRemove;
            let numberOfParticipants = state.numberOfParticipants;

            let formationMember = {
                id: associationMember.uuid,
                firstName: associationMember.firstName,
                lastName: associationMember.lastName
            };

            let wasRemovedFromRegistered = false;
            let wasRemovedFromWaiting = false;


            if (numberOfParticipants < state.numberMaxOfParticipants) {
                const formationMembersIndexToRemove = formationMembersIdsToRemove.indexOf(memberId);
                if (-1 !== formationMembersIndexToRemove) {
                    formationMembersIdsToRemove.splice(formationMembersIndexToRemove, 1);

                    wasRemovedFromRegistered = true;
                }

            } else {
                if (numberOfParticipants === state.numberMaxOfParticipants) {
                    const formationWaitingMembersIndexToRemove = formationWaitingMembersIdsToRemove.indexOf(memberId);
                    if (-1 !== formationWaitingMembersIndexToRemove) {
                        formationWaitingMembersIdsToRemove.splice(formationWaitingMembersIndexToRemove, 1);

                        wasRemovedFromWaiting = true;
                    }
                }
            }

            if (numberOfParticipants < state.numberMaxOfParticipants && false === wasRemovedFromRegistered) {
                formationMembersIdsToAdd.push(memberId);
            }

            if (numberOfParticipants === state.numberMaxOfParticipants && false === wasRemovedFromWaiting) {
                formationWaitingMembersIdsToAdd.push(memberId);
            }


            if (numberOfParticipants < state.numberMaxOfParticipants) {
                numberOfParticipants++;
                formationMembers.push(formationMember);
            } else {
                formationWaitingMembers.push(formationMember);
            }

            return {
                numberOfParticipants,
                formationMembers,
                formationWaitingMembers,
                formationMembersIdsToAdd,
                formationWaitingMembersIdsToAdd,
                formationMembersIdsToRemove,
                formationWaitingMembersIdsToRemove
            }
        });
    };


    removeMemberToFormation = (memberId) => {
        if (this.state.formationMembers.some(member => memberId === member.id)) {
            this.removeRegisteredMemberToFormation(memberId);
        } else {
            this.removeWaitingMemberToFormation(memberId);
        }
    };

    removeRegisteredMemberToFormation = (memberId) => {
        this.setState(state => {
            let formationMembers = state.formationMembers;
            let formationMembersIdsToAdd = state.formationMembersIdsToAdd;
            let formationMembersIdsToRemove = state.formationMembersIdsToRemove;
            let numberOfParticipants = state.numberOfParticipants;

            const formationMembersIndexToRemove = formationMembers.findIndex(member => memberId === member.id);
            if (-1 !== formationMembersIndexToRemove) {
                formationMembers.splice(formationMembersIndexToRemove, 1);
            }

            const formationMembersIndexToAdd = formationMembersIdsToAdd.indexOf(memberId);
            if (-1 !== formationMembersIndexToAdd) {
                formationMembersIdsToAdd.splice(formationMembersIndexToAdd, 1);
            } else {
                formationMembersIdsToRemove.push(memberId);
            }

            numberOfParticipants--;

            return {
                numberOfParticipants,
                formationMembers,
                formationMembersIdsToAdd,
                formationMembersIdsToRemove
            }
        });
    };

    removeWaitingMemberToFormation = (memberId) => {
        this.setState(state => {
            let formationWaitingMembers = state.formationWaitingMembers;
            let formationWaitingMembersIdsToAdd = state.formationWaitingMembersIdsToAdd;
            let formationWaitingMembersIdsToRemove = state.formationWaitingMembersIdsToRemove;

            const formationWaitingMembersIndexToRemove = formationWaitingMembers.findIndex(member => memberId === member.id);
            if (-1 !== formationWaitingMembersIndexToRemove) {
                formationWaitingMembers.splice(formationWaitingMembersIndexToRemove, 1);
            }

            const formationWaitingMembersIndexToAdd = formationWaitingMembersIdsToAdd.indexOf(memberId);
            if (-1 !== formationWaitingMembersIndexToAdd) {
                formationWaitingMembersIdsToAdd.splice(formationWaitingMembersIndexToAdd, 1);
            } else {
                formationWaitingMembersIdsToRemove.push(memberId);
            }

            return {
                formationWaitingMembers,
                formationWaitingMembersIdsToAdd,
                formationWaitingMembersIdsToRemove
            }
        });
    };

    submitFormationMember = () => {

        if (0 < this.state.formationMembersIdsToAdd.length || 0 < this.state.formationWaitingMembersIdsToAdd.length || 0 < this.state.formationMembersIdsToRemove.length || 0 < this.state.formationWaitingMembersIdsToRemove.length) {
            const registerValues = {
                id: this.props.item.id,
                memberIdsAdded: this.state.formationMembersIdsToAdd,
                waitingMemberIdsAdded: this.state.formationWaitingMembersIdsToAdd,
                memberIdsRemoved: this.state.formationMembersIdsToRemove.concat(this.state.formationWaitingMembersIdsToRemove)
            };

            fetch('/cqrs/commands/app_ad13_application_training_modify_registered_members', {
                method: 'POST',
                body: JSON.stringify(registerValues)
            }).then((response) => {
                this.props.retrieveTrainings();
                this.props.handleModal();
            }).catch(error => {
                this.setState({
                    submissionError: error.message
                });
            });
        }
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    toggleLeftPanelSide = () => {
        this.setState({leftPanelReverse: !this.state.leftPanelReverse});
    };

    render() {
        const {leftPanelReverse, associationMembers, formationMembers, formationWaitingMembers} = this.state;
        const {classes, item, isModalOpen, handleModal} = this.props;
        return (
            <Modal
                open={isModalOpen}
                onClose={handleModal}
                className={classes.modal}
            >
                <Card className={classes.card}>
                    <FormationModalHeader item={item}/>
                    <Grid container>
                        <Grid item xs={12} md={6} className={classes.leftPanel}>
                            {leftPanelReverse ?
                                <AddMemberPanel
                                    state={this.state}
                                    handleChange={this.handleChange}
                                    toggleLeftPanelSide={this.toggleLeftPanelSide}
                                />
                                :
                                <AddMemberToSelectedPanel
                                    state={this.state}
                                    formationMembers={formationMembers}
                                    formationWaitingMembers={formationWaitingMembers}
                                    associationMembers={associationMembers}
                                    formationUuid={item.id}
                                    addMemberToFormation={this.addMemberToFormation}
                                    removeMemberToFormation={this.removeMemberToFormation}
                                    handleChange={this.handleChange}
                                    toggleLeftPanelSide={this.toggleLeftPanelSide}
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectedMembersPanel
                                item={item}
                                formationMembers={formationMembers}
                                formationWaitingMembers={formationWaitingMembers}
                                removeMemberToFormation={this.removeMemberToFormation}
                                handleModal={handleModal}
                                storeSelectedMembers={this.submitFormationMember}
                                numberOfParticipants={this.state.numberOfParticipants}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {null !== this.state.submissionError &&
                            <FormHelperText error={true}>{this.state.submissionError}</FormHelperText>}
                        </Grid>
                    </Grid>
                </Card>
            </Modal>
        );
    }
}

FormationModal.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    handleModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    retrieveTrainings: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    retrieveTrainings: () => dispatch(list())
});

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withStyles(styles)
)(FormationModal);
