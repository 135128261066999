import React from 'react'
import {withStyles} from '@material-ui/core/';

const styles = theme => ({
    ctaCheck: {
        fill: theme.palette.secondary.main,
        position: 'absolute',
        right: 0,
        top: -64,
        zIndex: 600,
        [theme.breakpoints.up('md')]: {
            left: - 80,
            top: -100,
        },
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.secondary.light,
        }
    },
    square: {
        fill: 'inherit'
    },
    icon: {
        fill: theme.palette.secondary.contrastText,
    }
});

const AD13CtaCheck = props => (
    <svg width={80} height={80} className={props.classes.ctaCheck} onClick={props.validateForm}>
        <path className={props.classes.square} d="M-.022 40L39.999-.022 80.022 40 40 80.022z" />
        <path className={props.classes.icon} d="M36.4 44.3l11.8-11.7 1.6 1.5-13.4 13.3-6.2-6.2 1.6-1.5z"/>
    </svg>
);

export default withStyles(styles)(AD13CtaCheck);
