import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Search from '@material-ui/icons/Search';
import AD13Input from '../partials/AD13Input';
import MembersList from '../partials/MembersList';
import {withStyles, Grid, InputAdornment, Typography} from '@material-ui/core';

const styles = theme => ({
    leftPanelAction: {
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    icon: {
        color: theme.palette.textSecondary,
    },
    leftPanelList: {
        flexGrow: 1,
        width: '100%',
    },
});

class AddMemberToSelectedPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            associationMembers: props.associationMembers,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(state => {
            return {
                associationMembers: nextProps.associationMembers,
            }
        })
    }

    handleSearch = (e) => {

        const search = e.target.value;
        this.setState(() => {
            let associationMembers = this.props.associationMembers;
            return {
                associationMembers: associationMembers.filter(member => {
                    let searchIn = member.firstName + ' ' + member.lastName + ' ' + member.email;
                    return -1 !== searchIn.search(search);
                })
            }
        })
    };

    render() {
        const {classes, formationMembers, formationWaitingMembers, formationUuid, addMemberToFormation, removeMemberToFormation, toggleLeftPanelSide} = this.props;
        return (
            <Grid container>
                <Grid container justify={'space-between'}>
                    <Typography variant={'h4'} color={'primary'}>
                        {'Membres (' + formationMembers.length + ')'}
                    </Typography>
                    <Typography onClick={toggleLeftPanelSide} className={classes.leftPanelAction}>
                        + Ajouter un membre
                    </Typography>
                </Grid>
                <AD13Input
                    placeholder={'Recherche'}
                    id="outlined-search"
                    onChange={this.handleSearch}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Search className={classes.icon}/>
                            </InputAdornment>
                    }}
                />
                <MembersList associationMembers={this.state.associationMembers} formationMembers={formationMembers} formationWaitingMembers={formationWaitingMembers} formationUuid={formationUuid}
                             addMemberToFormation={addMemberToFormation}
                             removeMemberToFormation={removeMemberToFormation}/>
            </Grid>
        );
    }
}

AddMemberToSelectedPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    formationMembers: PropTypes.array.isRequired,
    associationMembers: PropTypes.array.isRequired,
    addMemberToFormation: PropTypes.func.isRequired,
    removeMemberToFormation: PropTypes.func.isRequired,
    formationUuid: PropTypes.string.isRequired,
    toggleLeftPanelSide: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddMemberToSelectedPanel);
