import React, {Component} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import {withStyles, Card, CardHeader, CardMedia, Grid, Typography} from '@material-ui/core';
import AD13_click from '../../assets/AD13_click.svg';

const styles = (theme) => ({
    card: {
        background: 'transparent',
        borderRadius: 0,
        transition: 'all .5s',
        width: '100%',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    mediaContainer: {
        height: theme.spacing.unit * 27.5,
        overflow: 'hidden',
        position: 'relative',
        transition: theme.transition.smooth,
        [theme.breakpoints.up('md')]: {
            '&::before': {
                content: 'url(' + AD13_click + ')',
                display: 'block',
                height: theme.spacing.unit * 10,
                position: 'absolute',
                top: 'calc(50% - 40px)',
                left: 70,
                pointerEvents: 'none',
                transition: theme.transition.smooth,
                width: theme.spacing.unit * 10,
                opacity: 0,
                zIndex: 50
            },
            '&:hover::before': {
                opacity: 1
            },
            '&:hover > span': {
                transform: 'scale(1.1)'
            }
        }
    },
    media: {
        backgroundPosition: '0% 50%',
        backgroundSize: 'contain',
        height: theme.spacing.unit * 27.5,
        transition: theme.transition.smooth,
        width: '100%',
        zIndex: 10
    },
    header: {
        paddingLeft: 0
    },
    category: {
        fontWeight: 800,
        textTransform: 'uppercase'
    }
});

class ListItem extends Component {
    render() {
        const {classes, item, history} = this.props,
            currentYear = new Date().getFullYear(),
            options = new Date(item.events[0].dateStart).getFullYear() !== currentYear ? {
                day: 'numeric',
                month: "long",
                year: 'numeric'
            } : {day: 'numeric', month: "long"},
            startDate = new Date(item.events[0].dateStart).toLocaleString('fr-FR', options);

        const itemImage = null !== item['image'] ? item['image']['url'] : darkRabbit;
        return (
            <Card elevation={0} className={classes.card} onClick={() => history.push(encodeURIComponent(item['id']))}>
                <Grid container className={classes.mediaContainer}>
                    <CardMedia component={'span'} className={classes.media} image={itemImage} title={item['title']}/>
                </Grid>
                <CardHeader disableTypography className={classes.header}
                            title={
                                <Grid container>
                                    <Typography color={"secondary"} variant={'body2'} className={classes.category}>
                                        {startDate}
                                    </Typography>
                                </Grid>
                            }
                            subheader={
                                <Typography variant={'h6'}>
                                    {item['name']}
                                </Typography>
                            }
                />
            </Card>
        );
    }
};

ListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
};

export default compose(
    withRouter,
    withStyles(styles)
)(ListItem);
