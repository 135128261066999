import React, { Component } from 'react';
import {Grid, Typography, withStyles} from '@material-ui/core';
import AD13Loader from "./AD13Loader";
import AD13Button from './AD13Button';

const styles = theme =>  ({
    messages: {
        paddingTop: theme.spacing.unit * 6,
        minHeight: '100vh',
    },
});

class ShowNotReady extends Component {
    render() {
        const {classes, loading, error, deleteError, deletedItem} = this.props;
        let errorMessage;

        if ('Not Found' === error) {
            errorMessage = 'Désolé, cet article n\'existe plus.'
        } else {
            errorMessage = error
        }

        return (
            <Grid container justify="center" alignItems={'center'} className={classes.messages}>
                {loading && <AD13Loader />}
                {error &&
                    <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                        <Typography gutterBottom>
                            {error && errorMessage}
                            {deleteError && deleteError}
                            {deletedItem && deletedItem}
                        </Typography>
                        <AD13Button href='/' text={'Retourner à l\'accueil'}/>
                    </Grid>

                }
            </Grid>
        );
    }
};

export default withStyles(styles)(ShowNotReady);
