import React, {Component} from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import AD13Input from '../partials/AD13Input';
import {withStyles, Button, Grid, Typography} from '@material-ui/core';
import {fetch} from "../../utils/dataAccess";
import {loadState} from "../../store/localstorage";
import FormHelperText from '@material-ui/core/FormHelperText';
import {list} from '../../actions/training/list';
import {Link} from 'react-router-dom';

const styles = theme => ({
    leftPanelTitle: {
        paddingBottom: theme.spacing.unit * 3,
    },
    leftPaneActions: {
        paddingTop: theme.spacing.unit * 4,
    },
});

class AddMemberPanel extends Component {

    constructor(props) {
        const localState = loadState();

        super(props);
        this.state = {
            form: {
                email: '',
                firstName: '',
                lastName: '',
                associationId: localState.storeAuth.user.associations[0].uuid,
            },
            modifyError: {},
            formValidated: false
        };
    }

    handleChange = name => event => {
        let form = this.state.form;
        form[name] = event.target.value;
        this.setState({
            form: form,
        });
    };

    validateForm = () => {

        fetch('/members/volunteer_registration', {
            method: 'POST',
            body: JSON.stringify(this.state.form)
        }).then(response => {
            this.setState(state => ({
                modifyError: {},
                formValidated: true
            }));

            this.props.retrieveTrainings();
            this.props.toggleLeftPanelSide();

        }).catch(error => {
            if (error.hasOwnProperty('errors')) {
                this.setState(state => {
                    state.modifyError = error.errors;
                    return state;
                })
            } else {
                this.setState(state => {
                    state.modifyError = {error: error.message};
                    return state;
                })
            }
        });
    };

    render() {
        const {classes, toggleLeftPanelSide} = this.props;
        return (
            <Grid container>
                <Grid container justify={'space-between'} className={classes.leftPanelTitle}>
                    <Typography variant={'h4'} color={'textPrimary'}>
                        Ajouter un membre
                    </Typography>
                </Grid>
                <form>
                    <AD13Input
                        id="panel-outlined-firstName"
                        label={'Prénom'}
                        type={'text'}
                        value={this.state.form.firstName}
                        onChange={this.handleChange('firstName')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        error={this.state.modifyError.hasOwnProperty('firstName')}
                    />
                    {this.state.modifyError.hasOwnProperty('firstName') &&
                    <FormHelperText error={true}>{this.state.modifyError.firstName}</FormHelperText>}
                    <AD13Input
                        id="panel-outlined-lastName"
                        label={'Nom'}
                        type={'text'}
                        value={this.state.form.lastName}
                        onChange={this.handleChange('lastName')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        error={this.state.modifyError.hasOwnProperty('lastName')}
                    />
                    {this.state.modifyError.hasOwnProperty('lastName') &&
                    <FormHelperText error={true}>{this.state.modifyError.lastName}</FormHelperText>}
                    <AD13Input
                        id="panel-outlined-email"
                        label={'Email'}
                        type={'email'}
                        value={this.state.form.email}
                        onChange={this.handleChange('email')}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        error={this.state.modifyError.hasOwnProperty('email')}
                    />
                    {this.state.modifyError.hasOwnProperty('email') &&
                    <FormHelperText error={true}>{this.state.modifyError.email}</FormHelperText>}

                    {this.state.modifyError.hasOwnProperty('error') &&
                    <FormHelperText error={true}>{this.state.modifyError.error}</FormHelperText>}
                </form>
                <FormHelperText>
                    Je déclare être parfaitement informé par PURJUS COMMUNICATION que le renseignement des champs de ce formulaire constitue un traitement de données au sens du <a
                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees" target="_blank" rel="noopener noreferrer">Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016</a> et avoir notamment satisfait préalablement au respect des articles 6 et 15 à 22 du texte.
                    <Link style={{display: 'block'}} to={'/consentement'}>En savoir plus</Link>
                </FormHelperText>
                <Grid container justify={'space-between'} className={classes.leftPaneActions}>
                    <Button variant={'text'} onClick={toggleLeftPanelSide}>Annuler</Button>
                    <Button variant={'contained'} color={'primary'} onClick={this.validateForm}>Ajouter</Button>
                </Grid>
            </Grid>
        );
    }
}

AddMemberPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    toggleLeftPanelSide: PropTypes.func.isRequired,
    addMemberToMembers: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
    retrieveTrainings: () => dispatch(list())
});

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    withStyles(styles)
)(AddMemberPanel);
