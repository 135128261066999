import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import {withStyles, Card, CardContent, CardHeader, CardMedia, Grid, Typography} from '@material-ui/core';
import AD13_click from '../../assets/AD13_click.svg';

const styles = (theme) => ({
    card: {
        background: 'transparent',
        borderRadius: 0,
        transition: 'all .5s',
        minWidth: '100%',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    mediaContainer: {
        height: theme.spacing.unit * 27.5,
        overflow: 'hidden',
        position: 'relative',
        transition: theme.transition.smooth,
        [theme.breakpoints.up('md')]: {
            '&::before': {
                content: 'url(' + AD13_click + ')',
                display: 'block',
                height: theme.spacing.unit * 10,
                position: 'absolute',
                top: 'calc(50% - 40px)',
                left: 70,
                pointerEvents: 'none',
                transition: theme.transition.smooth,
                width: theme.spacing.unit * 10,
                opacity: 0,
                zIndex: 50
            },
            '&:hover::before': {
                opacity: 1
            },
            '&:hover > span': {
                transform: 'scale(1.1)'
            }
        }
    },
    media: {
        backgroundPosition: '0% 50%',
        backgroundSize: 'contain',
        height: theme.spacing.unit * 27.5,
        transition: theme.transition.smooth,
        width: '100%',
        zIndex: 10
    },
    header: {
        paddingBottom: theme.spacing.unit,
        paddingLeft: 0,
        paddingTop: theme.spacing.unit
    },
    content: {
        paddingLeft: 0,
        paddingTop: 0
    },
    title: {
        paddingBottom: theme.spacing.unit
    },
    category: {
        fontWeight: 800,
        textTransform: 'uppercase'
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none'
        }
    }
})

class ListItem extends Component {

    redirect = () => {
        const uri = encodeURIComponent(this.props.item['uuid']);
        const isSinglePage = this.props.history.location.pathname.includes('/show');
        const pathname = isSinglePage ? `${uri}` : `/article/${uri}`;
        this.props.history.push(pathname);
    };

    render() {
        const {classes, item} = this.props;
        const itemImage = null !== item['image'] ? item['image']['url'] : darkRabbit;
        return (
            <Card elevation={0} className={classes.card} onClick={this.redirect}>
                <Grid container className={classes.mediaContainer}>
                    <CardMedia component={'span'} className={classes.media} image={itemImage} title={item['title']}/>
                </Grid>
                <CardHeader disableTypography className={classes.header}
                    title={
                        <Grid container className={classes.title}>
                            <Typography color={"primary"} variant={'button'} className={classes.category}>
                                {item['category'].name}&nbsp;
                            </Typography>
                        </Grid>
                    }
                    subheader={
                        <Typography variant={'h6'}>
                            {item['title']}
                        </Typography>
                    }
                />
                <CardContent className={classes.content} dangerouslySetInnerHTML={{__html: '<div>' + item['description'] + '</div>'}}/>
            </Card>
        );
    }
}

ListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

export default compose(
    withRouter,
    withStyles(styles)
)(ListItem);
