import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, TextField} from '@material-ui/core/';
import {palette} from '../../MuiTheme';

const styles = theme => ({
    input: {
        color: theme.palette.tertiary.light,
        fontFamily: "'OpenSans', sans-serif",
    },
    inputFocused: {
        '& > div' : {
            color: theme.palette.primary.light,
        }
    },
    notchedOutline: {
        borderRadius: 0,
    },
    label: {
        color: theme.palette.tertiary.light,
        fontFamily: "'OpenSans', sans-serif",
        '&::selection': {
            background: palette.primary.main,
            color: palette.primary.contrastText,
        },
    },
    labelFocused: {
        color: theme.palette.primary.main + ' !important',
    },
    icon: {
        color: 'inherit',
    },
    helperLink: {
        color: theme.palette.tertiary.light,
        '&:hover': {
            color: theme.palette.secondary.main,
        }
    },
    helperText: {
        fontFamily: "'OpenSans', sans-serif",
        textAlign: 'right'
    }
});

class AD13Input extends React.Component {
    render() {
        const {classes, required, id, label, type, value, defaultValue, onChange, margin, placeholder, variant, multiline, rows, rowsMax, fullWidth, select, SelectProps, InputProps, helperText, children, error, disabled} = this.props;

        return (
            <TextField
                required={required}
                id={id}
                label={label}
                type={type ? type : null}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                margin={margin ? margin : 'normal'}
                placeholder={placeholder}
                variant={variant ? variant : 'outlined'}
                multiline={multiline}
                rows={rows}
                rowsMax={rowsMax}
                fullWidth={fullWidth}
                select={select}
                SelectProps={SelectProps}
                InputLabelProps={{
                    classes: {
                        root: classes.label,
                        focused: classes.labelFocused,
                    },
                }}
                InputProps={{
                    ...InputProps,
                    classes: {
                        root: classes.input,
                        focused: classes.inputFocused,
                        notchedOutline: classes.notchedOutline,
                    },
                }}
                helperText={helperText}
                FormHelperTextProps={{
                    classes: {
                        contained: classes.helperText,
                    },
                }}
                error={error}
                disabled={disabled}
            >
                {children}
            </TextField>
        )
    }
}

AD13Input.propTypes = {
    classes: PropTypes.object.isRequired,
    required: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    margin: PropTypes.string,
    placeholder: PropTypes.string,
    variant: PropTypes.string,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    fullWidth: PropTypes.bool,
    select: PropTypes.bool,
    SelectProps: PropTypes.object,
    InputProps: PropTypes.object,
    helperText: PropTypes.object,
    children: PropTypes.object,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default withStyles(styles)(AD13Input)
