import thunk from 'redux-thunk';
import storeDrawerState from './../store/Reducers/drawerStateReducer';
import storeAuth from './../store/Reducers/authReducer';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {routerReducer as routing} from 'react-router-redux';
import {reducer as form} from 'redux-form';
import article from './../reducers/article/';
import training from './../reducers/training/';
import member from './../reducers/member/';
import document from './../reducers/document/';
import {loadState, saveState}  from './localstorage';

const persistedState = loadState();
const store = createStore(
    combineReducers({routing, form, article, training, member, document, storeDrawerState, storeAuth}),
    persistedState,
    applyMiddleware(thunk)
);

store.subscribe(() => {
    saveState({
        storeAuth: store.getState().storeAuth
    });
});

export default store;
