import React from 'react';
import {DropzoneArea} from 'material-ui-dropzone';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import AD13Input from '../partials/AD13Input';
import Search from '@material-ui/icons/Search';
import AD13Wysiwyg from '../partials/AD13Wysiwyg'
import Config from "../../Config";
import AD13MembersAddModal from '../partials/AD13MembersAddModal';
import AD13MembersModificationModal from '../partials/AD13MembersModificationModal';
import AD13MembersRemoveModal from '../partials/AD13MembersRemoveModal';
import {fetch} from "../../utils/dataAccess";
import darkRabbit from '../../assets/AD13_DarkRabbit.png';
import {
    withStyles,
    Button,
    CardMedia,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Tooltip,
    Typography
} from '@material-ui/core';

const styles = theme => ({
    dropzone: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    dropzoneText: {
        fontSize: '1rem',
        width: '100%'
    },
    intertitle: {
        marginBottom: theme.spacing.unit * 2
    },
    listContainer: {
        height: '100%'
    },
    membersList: {
        flexGrow: 1,
        marginBottom: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 3,
        maxHeight: theme.spacing.unit * 76,
        overflowY: 'auto'
    },
    actions: {
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing.unit * 4
        }
    },
    cta: {
        marginLeft: theme.spacing.unit * 2
    },
    media: {
        height: 'auto',
        margin: 'auto',
        maxHeight: 300,
        maxWidth: '100%',
        width: 'auto'
    }
});

class AccountMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            members: [],
            isMemberAddModalOpen: false,
            isMemberModificationModalOpen: false,
            isMemberRemoveModalOpen: false,
            memberUuid : null
        };
    }

    populateMembers = () => {
        fetch('/members?pagination=false&enabled=1').then(response => {
            response.json()
                .then(data => {
                    this.setState(state => {
                        return {
                            members: data['hydra:member']
                        }
                    })
            })
        });
    }

    componentDidMount() {
        this.populateMembers();
    }

    handleSearch = (e) => {
        const search = e.target.value;
        this.setState(() => {
            let members = this.props.association.members;
            return {
                members: members.filter(member => {
                    let searchIn = member.firstName + ' ' + member.lastName + ' ' + member.email;
                    return -1 !== searchIn.search(search);
                })
            }
        })
    };

    handleSave = files => {
        const file = files[0];
        const body = new FormData();
        body.append('file', file);

        return fetch(`${Config.api.entryPoint}/media_objects`, {body, method: 'POST'})
            .then(response => {
                response.json().then(data => {
                    this.props.handleChangeValue('image', data);
                });
            });
    };

    handleMemberAddModal = () => {
        this.setState({isMemberAddModalOpen: !this.state.isMemberAddModalOpen});
    };

    handleMemberModificationModal = (memberUuid) => {
        let memberToModify = null === this.state.memberUuid ? memberUuid : null;
        this.setState({
            isMemberModificationModalOpen: !this.state.isMemberModificationModalOpen,
            memberUuid: memberToModify
        });
    };

    handleMemberRemoveModal = (memberUuid) => {
        let memberToModify = null === this.state.memberUuid ? memberUuid : null;
        this.setState({
            isMemberRemoveModalOpen: !this.state.isMemberRemoveModalOpen,
            memberUuid: memberToModify
        })
    };

    abort = () => {
        this.props.fetchAssociationDatas();
        this.props.handleEdit()
    };

    render() {
        const {classes, isEditable, association, handleChangeValue, validateForm} = this.props;

        return (
            <Grid container spacing={40}>
                <Grid item md={12} lg={6}>
                    <form noValidate autoComplete="off" style={{width: '100%'}}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant={'subtitle1'} className={classes.intertitle}>
                                    Photo
                                </Typography>
                            </Grid>
                            {isEditable ?
                                <DropzoneArea
                                    onChange={this.handleSave}
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    filesLimit={1}
                                    maxFileSize={1572864}
                                    dropzoneText={'Ajouter une photo, déposez le fichier ou cliquez ici'}
                                    dropZoneClass={classes.dropzone}
                                    dropzoneParagraphClass={classes.dropzoneText}
                                />
                                :
                                <CardMedia
                                    component={'img'}
                                    src={!association.image ? darkRabbit : association.image.url}
                                    className={classes.media}
                                />
                            }
                        </Grid>
                        <Grid container style={{marginTop: 32}}>
                            <Grid item xs={12}>
                                <Typography variant={'subtitle1'} className={classes.intertitle}>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {isEditable ?
                                    <AD13Wysiwyg
                                        defaultContent={association.description}
                                        onChange={value => handleChangeValue('description', value)}
                                    />
                                    :
                                    <Typography dangerouslySetInnerHTML={{__html: '<div>' + association.description + '</div>'}}/>
                                }
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container direction={'column'} className={classes.listContainer}>
                        <Typography variant={'subtitle1'} className={classes.intertitle}>
                            Membres ({this.state.members.length})
                        </Typography>
                        {!isEditable ?
                            <AD13Input
                                id="outlined-search"
                                onChange={this.handleSearch}
                                placeholder={'Rechercher un membre'}
                                fullWidth
                                margin={'none'}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Search/></InputAdornment>
                                }}
                            />
                            :
                            <Button variant={'contained'} color={'default'} size={'large'} fullWidth onClick={this.handleMemberAddModal}>
                                Ajouter un membre
                            </Button>
                        }
                        <List className={classes.membersList}>
                            {this.state.members.map((member, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon style={{color: '#F1F1F1'}}>
                                        <AccountCircle color={'inherit'} style={{height: 42, width: 42}}/>
                                    </ListItemIcon>
                                    <ListItemText primary={member.firstName + ' ' + member.lastName}
                                                  secondary={member.email}/>
                                    {isEditable &&
                                    <ListItemSecondaryAction>
                                        <Tooltip title={"Modifier les informations du membre"}>
                                            <IconButton aria-label="Edit" onClick={() => this.handleMemberModificationModal(member.uuid)}>
                                                <Edit/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Désinscrire définitivement le membre"}>
                                            <IconButton aria-label="Delete"
                                                //onClick={() => this.removeMember(member.uuid)}
                                                onClick={() => this.handleMemberRemoveModal(member.uuid)}
                                            >
                                                <Close/>
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
                {isEditable &&
                <Grid item xs={12}>
                    <Grid container justify={'flex-end'} className={classes.actions}>
                        <Button
                            variant={'contained'}
                            color={'default'}
                            className={classes.cta}
                            onClick={this.abort}
                        >
                            Annuler
                        </Button>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            className={classes.cta}
                            onClick={validateForm}
                        >
                            Valider
                        </Button>
                    </Grid>
                </Grid>
                }
                <AD13MembersAddModal
                    isModalOpen={this.state.isMemberAddModalOpen}
                    handleModal={this.handleMemberAddModal}
                    fetchAssociationDatas={this.populateMembers}
                    members={this.props.association.members}
                />
                <AD13MembersModificationModal
                    isModalOpen={this.state.isMemberModificationModalOpen}
                    handleModal={this.handleMemberModificationModal}
                    fetchAssociationDatas={this.populateMembers}
                    members={this.state.members}
                    memberUuid={this.state.memberUuid}
                />
                <AD13MembersRemoveModal
                    isModalOpen={this.state.isMemberRemoveModalOpen}
                    handleModal={this.handleMemberRemoveModal}
                    fetchAssociationDatas={this.populateMembers}
                    memberUuid={this.state.memberUuid}
                    association={association}
                />
            </Grid>
        )
    }
}

export default withStyles(styles)(AccountMain)
