import React from 'react';
import {withRouter} from 'react-router-dom';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import List from '../article/List';
import AD13PageAside from '../partials/AD13PageAside';
import {Redirect} from 'react-router-dom'

class Page extends React.Component {
    render() {
        const {match} = this.props,
            {page} = match.params,
            title = 'reglementation' === page ? "Réglementation" : 'actualite' === page ? 'Actualité' : 'L\'association';

        if ('reglementation' !== page && 'actualite' !== page && 'association' !== page) {
            return (
                <Redirect to={'/'}/>
            )

        }
        return (
            <React.Fragment>
                <PageLayout title={title}>
                    <Container>
                        <React.Fragment>
                            <Main title={title}>
                                <List page={page}/>
                            </Main>
                            <Aside>
                                <AD13PageAside/>
                            </Aside>
                        </React.Fragment>
                    </Container>
                </PageLayout>
            </React.Fragment>
        )
    }
}

export default withRouter(Page)

