import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withStyles, Grid, Typography} from '@material-ui/core';

const styles = theme => ({
    header: {
        ...theme.mixins.gutters(),
        backgroundColor: theme.palette.primary.main,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: theme.palette.primary.contrastText,
        minHeight: theme.spacing.unit * 7.5,
        paddingBottom: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
    },
    headerDate: {
        fontWeight: 700,
    },
});

class FormationModalHeader extends Component {
    render() {
        const {classes, item} = this.props,
            formationDate = new Date(item.events[0].dateStart).toLocaleString('fr-FR', {day: "numeric", month: "long"})
        return (
            <Grid container className={classes.header} style={{backgroundImage: null !== item['image'] && 'url(' + item['image']['url']  + ')'}} direction={'column'}>
                <Typography variant={'caption'} color={'inherit'} className={classes.headerDate} >
                    {formationDate}
                </Typography>
                <Typography variant={'h6'} color={'inherit'}>
                    {item.name}
                </Typography>
            </Grid>
        );
    }
};

FormationModalHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormationModalHeader);
