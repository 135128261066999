import React from 'react';
import Autocomplete from 'react-autocomplete';
import {TextField, Typography} from '@material-ui/core/';

export default class AD13Autocomplete extends React.Component {

    state = {
        items: [],
        value: '',
    };

    render() {
        return (
            <Autocomplete
                onChange={e => {
                    this.setState({value: e.target.value});
                    this.props.callbackSelectedItem(null);
                    this.props.callbackDataRetriever(e.target.value).then(items => {
                        this.setState({items: items});
                    });
                }}
                items={this.state.items}
                value={this.state.value}
                onSelect={(val, item) => {
                    this.setState({value: val});
                    this.props.callbackSelectedItem(item);
                }}
                getItemValue={(item) => item.label}
                renderMenu={(items, value) => {
                    return <div children={items}/>
                }}
                renderItem={(item, highlighted) => {
                    return (
                        <Typography
                            key={item.id}
                            style={{backgroundColor: highlighted ? '#eee' : 'transparent'}}
                        >
                            {item.label}
                        </Typography>);
                }}
                renderInput={props => {
                    const {ref, ...rest} = props;
                    return (
                        <TextField {...rest} inputRef={ref}
                            required={this.props.required}
                            id={this.props.id}
                            label={this.props.label}
                            value={this.state.value}
                            margin={'normal'}
                            variant={'outlined'}
                            fullWidth
                            error={this.props.error}
                        />
                    )
                }}
                wrapperStyle={{width:  '100%'}}
            />
        );
    };
}
