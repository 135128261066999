import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, Slide} from '@material-ui/core/';

class DrawerButton extends React.Component {
    handleDrawer = () => {
        const action = {type: 'TOGGLE_DRAWER', value: !this.props.storeDrawerState.isDrawerOpen}
        this.props.dispatch(action)
    };

    render() {
        const {text, slide} = this.props;
        if (slide) {
            return (
                <Slide direction="left" in={true} appear timeout={1000}>
                    <Button variant="text" onClick={this.handleDrawer} color="inherit">{text}</Button>
                </Slide>
            )
        }

        return (
            <Button variant="text" onClick={this.handleDrawer} color="inherit">{text}</Button>
        )

    }
}

DrawerButton.propTypes = {
    text: PropTypes.string.isRequired,
    slide: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return state
};

export default connect(mapStateToProps)(DrawerButton);

