import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {withStyles, Button, CardActions, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import {Group } from '@material-ui/icons'

const styles = theme => ({
    contentContainer: {
        ...theme.mixins.gutters(),
        height: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: theme.spacing.unit * 2,
        [theme.breakpoints.up('md')]: {
            paddingTop: 0,
        }
    },
    contentWrapper: {
        height: 'inherit',
    },
    header: {
        maxWidth: '100%',
        padding: 0,
    },
    title: {
        maxWidth: '100%',
        '&:first-letter': {
            textTransform: 'uppercase',
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-evenly',
        padding: 0,
        '&:last-child': {
            paddingBottom: 0
        }
    },
    groupIcon: {
        fontSize: '1rem',
    },
    capacity: {
        borderColor: theme.palette.textSecondary,
        borderLeft: '1px solid',
        borderRight: '1px solid',
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    actions: {
        padding: 0,
        paddingTop: theme.spacing.unit,
    },
    actionSeeMore: {
        background: theme.palette.tertiary.light,
        color: theme.palette.tertiary.contrastText,
        marginBottom: theme.spacing.unit,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            marginRight: theme.spacing.unit,
        }
    },
    actionpast: {
        background: theme.palette.tertiary.main,
        color: theme.palette.tertiary.contrastText,
    },
    type: {
        fontWeight: 800,
        textTransform: 'uppercase',
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        }
    }
})

class FormationListItemContent extends Component {
    redirect = (id) => {
        const location = {pathname: '/formation/' + id}
        this.props.history.push(location)
    }

    getStatus = (status, isAuthenticated) => {
        switch (status) {
            case 'opened_for_registration' :
                return isAuthenticated ? 'Inscrire un équipier' : 'Ouvert aux inscriptions';
            case 'closed_for_registration' :
                return 'Inscriptions clôturées';
            case 'canceled' :
                return 'Annulée';
            case 'created':
            default:
                return 'Bientôt ouverte';
        }
    };

    render() {
        const {classes, width, item, handleModal, isAuthenticated} = this.props,
            isMobile = !isWidthUp('md', width),
            isOutdated = new Date() > new Date(item.events[0].dateStart),
            membres = item.numberOfParticipants,
            capacityMin = item.numberMinOfParticipants,
            capacityMax = item.numberMaxOfParticipants,
            formationStatus = membres < capacityMin ? 'Insuffisant' : membres >= capacityMax ? 'Complet' : capacityMax - membres + ' places disponibles',
            formationColor = formationStatus === 'Insuffisant' ? 'primary' : formationStatus === 'Complet' ? 'textPrimary' : 'secondary',
            dateOptions = {day: 'numeric', month: 'long', year: 'numeric'}

        return (
            <Grid container direction={'column'} wrap={'nowrap'} className={classes.contentContainer}>
                <CardHeader disableTypography className={classes.header}
                    title={
                        <Typography variant={'h6'} className={classes.title}>
                            {item.name}
                        </Typography>
                    }
                />
                <CardContent className={classes.content}>
                    <Grid container direction={'column'} wrap={'nowrap'}>
                        <Typography>
                            Stage sur {item.events.length} journée{item.events.length > 1 ? 's' : ''}
                        </Typography>
                        {item.events.map((event, index) => (
                            <Typography key={index}>
                                Jour {index + 1} : {new Date(event.dateStart).toLocaleString('fr-Fr', dateOptions)}
                            </Typography>
                        ))}
                    </Grid>
                    <Grid container alignItems={'center'} className={classes.infos}>
                        <Typography variant={'subtitle1'} color={formationColor}>
                            <Group className={classes.groupIcon}/>&nbsp;{membres}
                        </Typography>
                        <Typography variant={'caption'}  className={classes.capacity}>
                            {capacityMin} à {capacityMax} pers.
                        </Typography>
                        <Typography variant={'subtitle1'} color={formationColor}>
                            {formationStatus}
                        </Typography>
                    </Grid>
                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>
                    <Grid container justify={'flex-start'}>
                        <Button variant={"contained"} size={'small'} className={classes.actionSeeMore}
                                fullWidth={isMobile} onClick={() => this.redirect(item['id'])}>
                            En savoir plus
                        </Button>
                        {
                            (isOutdated ?
                                <Button
                                    variant={"contained"}
                                    size={'small'}
                                    disabled
                                    className={classes.actionpast}
                                    fullWidth={isMobile}
                                >
                                    Passé
                                </Button>
                                :
                                <Button variant={"contained"} color={'secondary'}
                                        disabled={'opened_for_registration' !== item.status || false === isAuthenticated}
                                        size={'small'}
                                        onClick={handleModal}
                                        fullWidth={isMobile}
                                >
                                    {this.getStatus(item.status, isAuthenticated)}
                                </Button>)
                        }
                    </Grid>
                </CardActions>
            </Grid>
        );
    }
}

FormationListItemContent.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    handleModal: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    isAuthenticated: state.storeAuth.isAuthenticated
});

export default compose(
    connect(mapStateToProps),
    withRouter,
    withWidth(),
    withStyles(styles)
)(FormationListItemContent);
