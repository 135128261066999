import React from 'react';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import CarteDrawerLeft from '../adherents/CarteDrawerLeft';
import CarteSvg from '../adherents/CarteSvg';
import CarteDrawerRight from '../adherents/CarteDrawerRight';
import {Grid} from '@material-ui/core'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import {fetch, normalize} from "../../utils/dataAccess";
import CarteCommunesList from '../adherents/CarteCommunesList';

class Carte extends React.Component {

    state = {
        isDrawerOpen: false,
        currentSvgX: 0,
        currentSvgY: 0,
        scale: 1,
        currentTarget: null,
        idealX: 0,
        idealY: 0,
        x: 0,
        y: 0,
        commune: {},
        carteZones : []
    };

    getZones = () => {
        fetch('/associations/map?pagination=false').then(response => {
            response.json().then(data=>{
                let carteZones = normalize(data);
                this.setState({carteZones: carteZones['hydra:member']});
            })
        })
    }

    getIdealPos = () => {
        const idealPosX = (window.innerWidth - 400) / 2 - 100;
        const idealPosY = (window.innerHeight / 2);
        this.setState({idealX: idealPosX, idealY: idealPosY});
    };

    resetMapPosition = () => {
        this.setState({x: 0, y: 0, scale: 1})
    };

    toggleDrawer = (bool, commune) => {
        this.setState({isDrawerOpen: bool});
        if (!bool) {
            this.resetMapPosition()
        }
    };

    toggleMobileDrawer = (commune) => {
        this.findCommuneById(commune['@id'])
        this.setState({isDrawerOpen: !this.state.isDrawerOpen});
    };

    findCommuneById = (associationId) => {
        fetch(associationId).then(response => {
          response.json().then(data=>{
            let commune = normalize(data);
            this.setState({commune: commune})
          })
        })
    };

    getIdOnClick = (e) => {
        let zone = this.state.carteZones.find(zone => {
            return zone['@id'] === e.target.id
        });

        if (undefined !== zone) {
            this.findCommuneById(zone['@id']);

            if (zone.partOfAD13) {
                this.toggleDrawer('map' !== e.target.id);

                let x, y, target, targetPos;
                target = e.target;
                targetPos = target.getBoundingClientRect();
                if ('map' !== e.target.id) {
                    x = (this.state.idealX - targetPos.x) / this.state.scale;
                    y = (this.state.idealY - targetPos.y)  / this.state.scale;
                } else {
                    x = 0;
                    y = 0;
                }
                this.setState(
                    {
                        x: x,
                        y: y,
                        scale: 'map' !== e.target.id ? 1.5 : 1,
                        currentTarget: e.target.id,
                    },
                )

            }
        }
    };

    componentDidMount() {
        this.getIdealPos();
        this.getZones();
    }

    render() {
        const {isDrawerOpen, commune, currentTarget, carteZones} = this.state,
            isMobile = !isWidthUp('md', this.props.width);
        return (
            <PageLayout noBackground noLogo={!isMobile} title={"Adhérents AD 13"}>
                <Container>
                    <Grid container style={{minHeight: !isMobile && 1000}}>
                        {isMobile ?
                            <CarteCommunesList toggleDrawer={this.toggleMobileDrawer}/>
                            :
                            <React.Fragment>
                                <CarteDrawerLeft isDrawerOpen={isDrawerOpen}/>
                                <CarteSvg
                                    getIdOnClick={this.getIdOnClick}
                                    state={this.state}
                                    currentTarget={currentTarget}
                                    zones={carteZones}
                                />
                            </React.Fragment>
                        }
                        <CarteDrawerRight
                            isDrawerOpen={isDrawerOpen}
                            toggleDrawer={this.toggleDrawer}
                            commune={commune}
                        />
                    </Grid>
                </Container>
            </PageLayout>
        )
    }
}


export default withWidth()(Carte)
