import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';
import PageLayout from '../partials/PageLayout';
import HomeIntro from '../accueil/HomeIntro';
import HomeAbout from '../accueil/HomeAbout';
import HomeSwiper from '../accueil/HomeSwiper';
import HomeAside from '../accueil/HomeAside';
import {withStyles, Grid} from '@material-ui/core';
import {fetch} from '../../utils/dataAccess';

const styles = theme => ({
    homeIntroContainer: {
        height: '90vh',
        [theme.breakpoints.up('md')]: {
            height: 'auto',
            zIndex: 500,
        },
    },
    homeContentContainer: {
        margin: 'auto',
        maxWidth: theme.layout.width,
        position: 'relative',
    },
    main: {
        [theme.breakpoints.down('sm')]: {
            ...theme.mixins.gutters(),
            background: 'linear-gradient(to bottom, #FFF calc(100% - 40px),' + theme.palette.background.lightgrey +' calc(100% - 40px))',
            zIndex: 200,
        },
    },
    aside: {
        background: theme.palette.background.lightgrey,
        zIndex: 200,
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters(),
        },
        [theme.breakpoints.up('md')]: {
            height: 'inherit',
            paddingLeft: theme.spacing.unit * 5,
            paddingTop: 900,
        },
    },
});

class Home extends React.Component {

    state = {
        items: []
    };

    componentDidMount() {
        fetch('articles').then(response => {
            response.json().then(data=>{
                this.setState({items: [...data['hydra:member']]})
            })
        })
    };

    render() {
        const {classes} = this.props,
            {items} = this.state;
        return (
            <PageLayout title={"Accueil"}>
                <React.Fragment>
                    <Grid container className={classes.homeIntroContainer}>
                        <HomeIntro/>
                    </Grid>
                    <Grid container className={classes.homeContentContainer}>
                        <Grid item xs={12} md={7} component="main" className={classes.main}>
                            <HomeAbout/>
                        </Grid>
                        <Grid item xs={12} md={5} component="aside" className={classes.aside}>
                            <HomeAside/>
                        </Grid>
                        <Grid container id="slider">
                            {0 < items.length && <HomeSwiper items={items}/>}
                        </Grid>
                    </Grid>
                </React.Fragment>
            </PageLayout>
        )
    }
};

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default compose(
    withRouter,
    withStyles(styles)
)(Home);
