import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { list, reset } from '../../actions/article/list';
import ListItem from '../layouts/ListItem';
import {withStyles, Grid} from '@material-ui/core';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import SliderNotReady from '../layouts/SliderNotReady';
import Swiper from 'react-id-swiper';

const styles = theme => ({
    mobileSliderWrapper: {
        paddingBottom: theme.spacing.unit * 5,
    },
    sliderWrapper: {
        flexGrow: 1,
    },
    sliderContainer: {
        zIndex: 100,
    },
    container: {
        margin: 0,
        overflow: 'hidden',
    }
});

let sliderWidth;

class Slider extends Component {

    getSliderWidth = () => {
        if (1280 < window.innerWidth) {
            sliderWidth = window.innerWidth - ((window.innerWidth - 1280) / 2)
        } else {
            sliderWidth = '100%'
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.getSliderWidth);
        this.getSliderWidth();
    };

    componentDidUpdate() {
        this.getSliderWidth();
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.getSliderWidth)
    };

    render() {
        const {classes, width, loading, deletedItem, error, items} = this.props,
            isMobile = !isWidthUp('md', width),
            params = {
                slidesPerView: 'auto',
                spaceBetween: 32,
                containerClass: classes.container
            };
        return (
            items ?
                isMobile ?
                    <Grid container spacing={16} className={classes.mobileSliderWrapper}>
                        {items.map(item => (
                            <Grid item xs={6} md={6} lg={6} key={item['@id']}>
                                <ListItem item={item} />
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <Grid container className={classes.sliderWrapper}>
                        <Grid container className={classes.sliderContainer} style={{width: sliderWidth}}>
                            <Swiper {...params}>
                                {items.map(item => (
                                    <div key={item['@id']} style={{marginRight: 32, width: 400}}>
                                        <ListItem item={item}/>
                                    </div>
                                ))}
                            </Swiper>
                        </Grid>
                    </Grid>
                :
                <SliderNotReady loading={loading} error={error} deletedItem={deletedItem}/>
        );
    }
}

Slider.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.array,
    width: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem
  } = state.training.list;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter,
    withWidth(),
    withStyles(styles)
)(Slider);
