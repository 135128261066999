import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withStyles, Grid} from '@material-ui/core';

const size = 20,
    coteCta = Math.sqrt(Math.pow(size, 2) / 2),
    pointe = (size - coteCta) / 2

const styles = theme => ({
    stepContainer: {
        ...theme.mixins.gutters(),
        alignItems: 'center',
        display: 'flex',
        minHeight: 44,
    },
    diamond: {
        alignItems: "center",
        display: "flex",
        height: size,
        justifyContent: "center",
        margin: 'auto',
        position: 'relative',
        textDecoration: 'none',
        width: size,
        zIndex: 10,
        '&::before': {
            border: '3px solid ' + theme.palette.tertiary.main,
            content: "''",
            display: 'block',
            height: coteCta,
            left: pointe,
            position: 'absolute',
            top: pointe,
            transform: 'rotateZ(45deg)',
            transition: theme.transition.smooth,
            width: coteCta,
            zIndex: 0,
        },
        '&:hover::before': {
            border: '3px solid ' + theme.palette.tertiary.light,
        },
        '&:after': {
            content: '""',
            display: 'none',
            height: theme.spacing.unit * 48,
            position: 'absolute',
            bottom:  'calc(100% + ' + theme.spacing.unit + 'px)',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                height: theme.spacing.unit * 18,
            }
        }
    },
    diamondNext: {
        '&::before': {
            backgroundColor: theme.palette.primary.main,
            border: '3px solid ' + theme.palette.primary.main,
        },
        '&:hover::before': {
            background: theme.palette.primary.light,
            border: '3px solid ' + theme.palette.primary.light,
        },
        '&:after': {
            border: '1px solid ' + theme.palette.primary.main,
        }
    },
    diamondOutdated: {
        '&::before': {
            backgroundColor: theme.palette.tertiary.main,
        },
        '&:hover::before': {
            background: theme.palette.tertiary.light,
        },
        '&:after': {
            border: '1px solid ' + theme.palette.tertiary.main,
        }
    }
})

class FormationListItemDiamond extends Component {

    render() {
        const {classes, item, nextFormation} = this.props,
            isOutdated = new Date() > new Date(item.events[0].dateStart),
            isNext = item['@id'] === nextFormation
        return (
            <Grid item className={classes.stepContainer}>
                <span className={`${classes.diamond} ${isNext && classes.diamondNext} ${isOutdated && classes.diamondOutdated}`}/>
            </Grid>
        );
    }
}

FormationListItemDiamond.propTypes = {
    classes: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    nextFormation: PropTypes.string.isRequired,
}

export default withStyles(styles)(FormationListItemDiamond);
