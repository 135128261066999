import React from 'react'
import PropTypes from 'prop-types'
import {withStyles, Grid, Tooltip, Typography} from '@material-ui/core'

const styles = theme => ({
    mapContainer: {
        left: 100,
        top: 191,
        position: 'absolute',
        width: 'calc(100% - 150px)',
        maxWidth: 960,
        zIndex: 100,
    },
    map: {
        transition: theme.transition.smooth,
	},
    mapTooltip: {
        backgroundColor: 'transparent',
        color: theme.palette.textPrimary,
        position: 'absolute',
        top: -100,
        left: -50,
        width: 300,
    },
    path: {
        fill: theme.palette.background.lightgrey,
        transition: theme.transition.smooth,
        strokeWidth: 1,
        stroke: theme.palette.primary.contrastText,
        '&:hover': {
            cursor: 'pointer',
            fill: theme.palette.primary.main,
            opacity: 1,
        },
    },
    adherent: {
        fill: theme.palette.primary.light,
        opacity: .5,
    },
    selected: {
        fill: theme.palette.primary.main,
        opacity: 1,
    },
});

const City = (props) => {
    return (
        <Tooltip
            classes={{ tooltip: props.classes.mapTooltip }}
            title={
                <Typography variant={'h4'} style={{textTransform: 'capitalize'}}>
                    {props.zone.mapZoneLabel}
                </Typography>
            }
        >
            <path
                id={props.zone['@id']}
                d={props.zone.mapZonePath}
                className={`${props.classes.path} ${true === props.zone.partOfAD13 && props.classes.adherent} ${props.zone['@id'] === props.currentTarget && props.classes.selected}`}
            />
        </Tooltip>
    )
};

class CarteSvg extends React.Component {
	render() {
        const {classes, getIdOnClick, currentTarget, zones} = this.props
        const {x, y, scale} = this.props.state
		return (
            <Grid item xs={12}
                  component="figure"
                  className={classes.mapContainer}
            >
                <svg id="map" viewBox="0 0 960 637.6" preserveAspectRatio="xMinYMin meet"
                     onClick={e => getIdOnClick(e)}
                     className={classes.map}
                     style={{width: '100%', height: 'auto', transform: 'scale(' + scale + ') translate(' + x + 'px,' + y + 'px)'}}
                >
                    {zones.map((zone, i) => (
                        <City key={i} classes={classes} currentTarget={currentTarget} zone={zone}/>
                    ))}
                </svg>
            </Grid>
		)
	}
};

CarteSvg.propTypes = {
    getIdOnClick: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
    currentTarget: PropTypes.string,
    zones: PropTypes.array.isRequired,
};

export default withStyles(styles)(CarteSvg);
