import React from 'react'
import PropTypes from 'prop-types'
import Container from '../partials/Container'
import AD13ReturnCTA from './AD13ReturnCTA'
import {withStyles, Grid, Hidden} from '@material-ui/core'
import AD13SocialLinks from '../partials/AD13SocialLinks'

const styles = theme => ({
    contentContainer: {
        margin: 'auto'
    },
    content: {
        [theme.breakpoints.down('md')]: {
            ...theme.mixins.gutters(),
        },
        marginBottom: theme.spacing.unit * 7,
    },
});

class AD13SingleContent extends React.Component {
    render() {
        const {classes, children, socialData} = this.props;

        return (
            <Container marginTop={56}>
                <React.Fragment>
                    <Hidden mdDown>
                        <Grid item md={2}>
                            <AD13ReturnCTA/>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} md={8} className={classes.contentContainer}>
                        <Grid container className={classes.content}>
                            {children}
                        </Grid>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item md={2}>
                            <AD13SocialLinks socialData={socialData}/>
                        </Grid>
                    </Hidden>
                </React.Fragment>
            </Container>
        )
    }
};

AD13SingleContent.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.object,
    item: PropTypes.object
};

export default withStyles(styles)(AD13SingleContent);

