import React from 'react';
import ReactDom from 'react-dom';
import {Route, Switch} from 'react-router-dom';
import Home from './Components/layouts/Home';
import Page from './Components/layouts/Page';
import Legals from './Components/layouts/Legals';
import Consent from './Components/layouts/Consent';
import Carte from './Components/layouts/Carte';
import Formation from './Components/layouts/Formation';
import Documents from './Components/layouts/Documents';
import Contact from './Components/layouts/Contact';
import SignUp from './Components/layouts/SignUp';
import PasswordRecovery from './Components/layouts/PasswordRecovery';
import PasswordChange from './Components/layouts/PasswordChange';
import Preferences from './Components/layouts/Preferences';
import ArticleSubmit from './Components/layouts/ArticleSubmit';
import Account from './Components/layouts/Account';
import NotFound from './Components/layouts/NotFound';
import ArticleSingle from './Components/layouts/ArticleSingle';
import FormationSingle from './Components/layouts/FormationSingle';
import FireGuide from './Components/layouts/FireGuide';
import Partners from './Components/layouts/Partners'

class Routes extends React.Component {

    componentDidUpdate() {
        if(ReactDom.findDOMNode(this)) {
            ReactDom.findDOMNode(this).scrollIntoView({behavior: 'smooth'});
        }
    };

    render() {
        return (
            <Switch>
                <Route path="/adherents_ad13/" component={Carte} strict exact/>
                <Route path="/legals/" component={Legals}/>
                <Route path="/consentement/" component={Consent}/>
                <Route path="/contact/" component={Contact}/>
                <Route path="/inscription/" component={SignUp} />
                <Route path="/recuperation_mot_de_passe/" component={PasswordRecovery} />
                <Route path="/nouveau_mot_de_passe/:token" component={PasswordChange} />
                <Route path="/preferences/" component={Preferences} strict exact/>
                <Route path="/soumission/" component={ArticleSubmit} strict exact/>
                <Route path="/compte/:id" component={Account} strict exact/>
                <Route path="/formation/:id" component={FormationSingle}/>
                <Route path="/formations/" component={Formation} strict exact/>
                <Route path="/documents/" component={Documents} strict exact/>
                <Route path="/article/:id" component={ArticleSingle}/>
                <Route path="/categorie/:page" component={Page} strict/>
                <Route path="/guides" component={FireGuide} />
                <Route path="/partenaires" component={Partners} />
                <Route path="/" component={Home} exact />
                <Route render={() => <NotFound/>}/>
            </Switch>
        )
    }
}

export default Routes;
