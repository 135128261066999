import React from 'react';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import AD13Button from '../partials/AD13Button';
import {DropzoneArea} from 'material-ui-dropzone';
import AD13Wysiwyg from '../partials/AD13Wysiwyg'
import AD13Input from '../partials/AD13Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
    withStyles,
    Checkbox,
    FormControlLabel,
    Grid,
    Hidden,
    Typography
} from '@material-ui/core';
import {fetch} from '../../utils/dataAccess';
import Config from "../../Config";
import Ad13FileList from '../partials/Ad13FileList';

const styles = theme => ({
    dropzone: {
        ...theme.mixins.gutters(),
        alignItems: 'center',
        background: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: theme.spacing.unit * 27.5
    },
    dropzoneText: {
        fontSize: '1rem'
    },
    url: {
        color: theme.palette.secondary.main,
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.main,
        }
    }
});

class ArticleSubmit extends React.Component {

    state = {
        articleCategories: [],
        form: {
            title: '',
            articleCategoryId: '',
            description: '',
            content: '',
            imageId: null,
            filesId: []
        },
        articleSubmitted: false,
        checked: false,
        modifyError: {},
        uploadedFiles: [],
    };

    componentDidMount() {
        fetch('/article_categories').then(response => {
            response.json().then(articleCategories => {
                this.setState(state => {

                    state['articleCategories'] = articleCategories['hydra:member'].map(articleCategory => ({
                        id: articleCategory.uuid,
                        name: articleCategory.name
                    }));

                    state.form['articleCategoryId'] = state['articleCategories'][0]['id'];

                    return state;
                })
            })
        });
    }

    handleChange = name => event => {
        let form = this.state.form;
        form[name] = event.target.value;
        this.setState({
            form: form,
        });
    };

    handleChangeValue = (name, value) => {
        let form = this.state.form;
        form[name] = value;
        this.setState({
            form: form,
        });
    };

    handleSave = file => {

        const body = new FormData();
        body.append('file', file);

        return fetch(`${Config.api.entryPoint}/media_objects`, {body, method: 'POST'})
            .then(response => {
                response.json().then(data => {
                    this.handleChangeValue('imageId', data.uuid);
                });
            });
    };

    onDropAttachedFiles = file => {

        if(this.state.uploadedFiles.find(uploadedFile => file.name === uploadedFile.name)){
            return false;
        }

        const body = new FormData();
        body.append('file', file);

        this.setState({
            loading: true
        });

        return fetch(`${Config.api.entryPoint}/media_objects`, {body, method: 'POST'})
            .then(response => {
                response.json().then(data => {
                    this.setState(state => {
                        let uploadedFiles = state.uploadedFiles;

                        uploadedFiles.push({
                            uuid: data.uuid,
                            url: data.url,
                            name: file.name
                        });

                        return {uploadedFiles};
                    })
                });
            }).catch(() => {
                this.setState({
                    loading: false
                });
            });
    };

    onDeleteAttachedFile = fileId => {
        this.setState(state => {
            let uploadedFiles = state.uploadedFiles;
            const indexToDelete = uploadedFiles.findIndex(file => file.uuid === fileId);
            uploadedFiles.splice(indexToDelete, 1);

            return {uploadedFiles};
        })
    };

    handleCheckbox = () => {
        this.setState({checked: !this.state.checked});
    };

    validateForm = () => {
        if (false === this.state.checked) {
            this.setState({modifyError: {checked: 'Vous devez accepter les conditions pour soumettre l\'article'}});
            return;
        }


        let form = this.state.form;
        form.filesId = this.state.uploadedFiles.reduce((accumulateur, valeurCourante) => accumulateur.concat([valeurCourante['uuid']]), [])

        fetch('/article_submission', {
            method: 'POST',
            body: JSON.stringify(this.state.form)
        }).then(reponse => {
            this.setState(state => ({
                modifyError: {},
                articleSubmitted: true
            }))

        }).catch(error => {
            this.setState(state => {
                state.modifyError = error.errors;
                return state;
            })
        });

    };

    render() {
        const {classes} = this.props;
        const {checked, modifyError, articleSubmitted, uploadedFiles} = this.state;
        return (
            <PageLayout title={"Soumettre un article"}>
                <Container>
                    <React.Fragment>
                        {false === articleSubmitted ?
                            <Main title={"Soumettre un article"}>
                                <Grid container style={{maxWidth: 700}}>
                                    <Typography>
                                        Vous souhaitez soumettre du contenu à l&#39;AD13 ? Remplissez le formulaire
                                        suivant
                                        avec l'ensemble des informations. Attention, celui-ci sera soumis à validation
                                        par
                                        un administratur avant publication. Si il ne répond pas aux critères de
                                        l&#39;AD13,
                                        il ne sera pas publié.
                                    </Typography>
                                    <form noValidate autoComplete="off" style={{width: '100%'}}>
                                        <AD13Input
                                            required
                                            id="outlined-title"
                                            label="Titre"
                                            type="text"
                                            value={this.state.form.title}
                                            onChange={this.handleChange('title')}
                                            fullWidth
                                            error={modifyError.hasOwnProperty('title')}
                                        />
                                        {modifyError.hasOwnProperty('title') &&
                                        <FormHelperText error={true}>{modifyError.title}</FormHelperText>}
                                        <AD13Input
                                            required
                                            id="outlined-category"
                                            label="Catégorie"
                                            value={this.state.form.articleCategoryId}
                                            onChange={this.handleChange('articleCategoryId')}
                                            fullWidth
                                            select
                                            SelectProps={{
                                                native: true,
                                            }}
                                            error={modifyError.hasOwnProperty('category')}
                                        >
                                            <React.Fragment>
                                                {
                                                    this.state.articleCategories.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>))
                                                }
                                            </React.Fragment>
                                        </AD13Input>
                                        {modifyError.hasOwnProperty('category') &&
                                        <FormHelperText error={true}>{modifyError.category}</FormHelperText>}
                                        <Grid container style={{marginTop: 16}}>
                                            <DropzoneArea
                                                onDrop={this.handleSave}
                                                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                                filesLimit={1}
                                                maxFileSize={1572864}
                                                dropzoneText={'Ajouter une photo, déposez le fichier ou cliquez ici'}
                                                dropZoneClass={classes.dropzone}
                                                dropzoneParagraphClass={classes.dropzoneText}
                                            />
                                            <Grid item xs={12} style={{marginTop: 8}}>
                                                <Typography variant={'caption'}>
                                                    Fichier JPG, 1200*800px, 1.5 Mo max
                                                </Typography>
                                                <Typography variant={'caption'}>
                                                    Privilégiez des photos d'illustration de bonne qualité sans texte
                                                    par
                                                    dessus
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <FormHelperText>Description</FormHelperText>
                                        <AD13Wysiwyg defaultContent={''} onChange={value => {
                                            this.handleChangeValue('description', value)
                                        }}/>
                                        {modifyError.hasOwnProperty('description') &&
                                        <FormHelperText error={true}>{modifyError.description}</FormHelperText>}

                                        <FormHelperText>Contenu</FormHelperText>
                                        <AD13Wysiwyg defaultContent={''} onChange={value => {
                                            this.handleChangeValue('content', value)
                                        }}/>
                                        {modifyError.hasOwnProperty('content') &&
                                        <FormHelperText error={true}>{modifyError.content}</FormHelperText>}

                                        <FormHelperText>Pièces jointes</FormHelperText>
                                        <Grid container style={{marginTop: 16}}>
                                            <DropzoneArea
                                                onDrop={this.onDropAttachedFiles}
                                                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
                                                filesLimit={100}
                                                maxFileSize={1572864}
                                                dropzoneText={'Déposez le fichier à uploader, ou cliquez ici pour le sélectionner.'}
                                                dropZoneClass={classes.dropzone}
                                                dropzoneParagraphClass={classes.dropzoneText}
                                                showPreviews={false}
                                                showPreviewsInDropzone={false}
                                            />
                                            <Grid item xs={12} style={{marginTop: 8}}>
                                                <Typography variant={'caption'}>
                                                    Fichier PDF, JPG, 1.5 Mo max
                                                </Typography>
                                            </Grid>
                                            <Ad13FileList previewFiles={uploadedFiles} onDelete={this.onDeleteAttachedFile}/>
                                        </Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={this.handleCheckbox}
                                                    value="checked"
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <Typography variant={'body2'} color={'textPrimary'}
                                                            style={{paddingTop: 12}}>
                                                    J'atteste que je suis l'auteur et le propriétaire des textes et
                                                    photographies et autorise l'ADCCFF13 à en faire usage sur son site
                                                    internet.
                                                </Typography>
                                            }
                                        />
                                        {modifyError.hasOwnProperty('checked') &&
                                        <FormHelperText error={true}>{modifyError.checked}</FormHelperText>}
                                    </form>
                                    <Grid container justify={'flex-end'} style={{marginRight: 20, marginTop: 16}}>
                                        <AD13Button text="envoyer" onClick={this.validateForm}/>
                                    </Grid>
                                </Grid>
                            </Main>
                            :
                            <Main title={"Article soumis"}>
                                <Grid container style={{maxWidth: 600}}>
                                    <Typography>
                                        Votre article a été soumis. Il sera publié après validation d'un responsable de
                                        l'association.
                                    </Typography>
                                </Grid>
                            </Main>
                        }
                        <Hidden smDown>
                            <Aside/>
                        </Hidden>
                    </React.Fragment>
                </Container>
            </PageLayout>
        )
    }
}

export default compose(
    withRouter,
    withStyles(styles)
)(ArticleSubmit)

