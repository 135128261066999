import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import AD13_chevron from '../../assets/AD13_chevron.svg';
import {withStyles, Button} from '@material-ui/core/';

const styles = theme => ({
    link: {
        position: 'relative',
        '&:hover': {
            textDecoration: "none",
        }
    },
    button: {
        borderRadius: 0,
        height: theme.spacing.unit * 5,
        paddingRight: 40,
        position: 'relative',
        '&::before': {
            borderLeft: '20px solid ' + theme.palette.secondary.main,
            borderBottom: '20px solid transparent',
            borderTop: '20px solid transparent',
            content: "''",
            position: 'absolute',
            top: 0,
            right: -20,
            width: 0,
            height: 0,
            transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&::after': {
            background: 'url(' +  AD13_chevron + ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            content: "''",
            height: 14,
            position: 'absolute',
            right: 20,
            top: 13,
            width: 8,
        },
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            '&::before': {
                borderLeft: '20px solid ' + theme.palette.secondary.dark,
            }
        }
    },
    buttonColored: {
        '&::before': {
            borderLeft: '20px solid ' + theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            '&::before': {
                borderLeft: '20px solid ' + theme.palette.primary.dark,
            }
        }
    },
    arrow: {
        borderLeft: '20px solid',
        borderBottom: '20px solid transparent',
        borderTop: '20px solid transparent',
        content: "''",
        position: 'absolute',
        top: 0,
        right: -20,
        width: 0,
        height: 0,
        transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    buttonDisabled: {
        '&::before': {
            borderLeft: '20px solid rgba(0, 0, 0, 0.12)',
        },
    }
});

class AD13Button extends React.Component {
    render() {
        const {classes, text, color, href, onClick, blank, disabled} = this.props;
        return (
            href ?
            <Link to={href} className={classes.link} target={blank ? '_blank' : ''}>
                <Button
                    variant="contained"
                    color={color ? color : "secondary"}
                    className={`${classes.button} ${color && classes.buttonColored} ${disabled && classes.buttonDisabled}`}
                    disabled={disabled}
                >
                    {text}
                </Button>
            </Link>
            :
            <Button
                onClick={onClick}
                variant="contained"
                color={color ? color : "secondary"}
                className={`${classes.button} ${color && classes.buttonColored} ${disabled && classes.buttonDisabled}`}
                disabled={disabled}
            >
                {text}
            </Button>
        )
    }
}

AD13Button.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    blank: PropTypes.bool,
    disabled: PropTypes.bool

}

export default withStyles(styles)(AD13Button)
