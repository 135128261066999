import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import PageLayout from '../partials/PageLayout';
import Container from '../partials/Container';
import Main from '../partials/Main';
import Aside from '../partials/Aside';
import FormationList from '../formation/FormationList';
import FormationAside from '../formation/FormationAside';
import {list, reset} from '../../actions/training/list';
import AD13Loader from "../partials/AD13Loader";
import {Grid, Hidden, Typography} from '@material-ui/core';
import {getNextFormations, sortItemsByDate} from '../../utils/dateUtils';
import AD13Button from '../partials/AD13Button';

let trainings, nextFormation;

class Formation extends React.Component {
    static propTypes = {
        retrieved: PropTypes.object,
        eventSource: PropTypes.instanceOf(EventSource),
        list: PropTypes.func.isRequigred,
        reset: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        error: PropTypes.string,
        deletedItem: PropTypes.object
    };

    fetchTrainings = () => {
        this.props.list(
            this.props.match.params.page &&
            decodeURIComponent(this.props.match.params.page)
        );
    }

    componentDidMount() {
        this.fetchTrainings()
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.page !== nextProps.match.params.page)
            nextProps.list(
                nextProps.match.params.page &&
                decodeURIComponent(nextProps.match.params.page)
            );
    };

    componentWillUnmount() {
        this.props.reset(this.props.eventSource);
    };

    render() {
        const {loading, deletedItem, error, retrieved} = this.props;
        let items = undefined !== trainings ? trainings : retrieved;
        if(null !== items) {
            sortItemsByDate(items);
            nextFormation = 0 < getNextFormations(items).length && getNextFormations(items)[0]['@id'];
        }
        return (
            <PageLayout  title={"Nos formations"}>
                <Container>
                    <React.Fragment>
                        <Main>
                            {null !== items ?
                                0 < items['hydra:member'].length ?
                                    <div style={{paddingTop: 16}}>
                                        <FormationList items={items} nextFormation={nextFormation} fetchTrainings={this.fetchTrainings}/>
                                    </div>
                                    :
                                    <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                                        <Typography gutterBottom>
                                            Aucune formation pour le moment
                                        </Typography>
                                        <AD13Button href='/' text={'Retourner à l\'accueil'}/>
                                    </Grid>
                                :
                                <Grid container justify="center">
                                    {loading && <AD13Loader />}
                                    {deletedItem && <Typography>{deletedItem['@id']} deleted.</Typography>}
                                    {error && <Typography>{error}</Typography>}
                                </Grid>
                            }
                        </Main>
                        <Hidden smDown>
                            {null !== items &&
                                <Aside>
                                    <FormationAside items={items}/>
                                </Aside>
                            }
                        </Hidden>
                    </React.Fragment>
                </Container>
            </PageLayout>

        )
    }
};

Formation.propTypes = {
    match: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    const {
        retrieved,
        loading,
        error,
        eventSource,
        deletedItem
    } = state.training.list;
    return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = dispatch => ({
    list: page => dispatch(list(page)),
    reset: eventSource => dispatch(reset(eventSource))
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter
)(Formation);
