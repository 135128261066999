const initialState = { isAuthenticated: false, isLoginOpen: false }

function storeAuth(state = initialState, action) {
    let nextState
    switch (action.type) {
        case 'AUTHENTICATE_USER':
            nextState = {
                ...state,
                isAuthenticated: action.isAuthenticated,
                token: action.token,
                user: action.user
            };
            return nextState || state;
        case 'TOGGLE_LOGIN_MODAL':
            nextState = {
                ...state,
                isLoginOpen: action.value
            };
            return nextState || state;
        default:
            return state
    }
}

export default storeAuth
